/**
 * Variables communes
 */
export const APPENV = {
    SUPPORT_MAIL: "support-rb@rb.com",
    APP_NAME: process.env.REACT_APP_DESCRIPTION,
    APP_DESCRIPTION: "DivinEnergy",
    APP_CODE: "DivinEnergy",
    ENV: process.env.REACT_APP_ENV ?? "",
    HOST_RB_API: process.env.REACT_APP_RB_HOST_API ?? null,
    HOST_RB_FRONT: process.env.REACT_APP_RB_HOST_FRONT ?? null,
    NAME: process.env.REACT_APP_NAME,
    API_KEY: process.env.REACT_APP_API_KEY ?? null
};
export const MEDIA = {
    LOGO: "",
};
