import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useApp } from "context/App/useApp";
import { PageRegister } from "App/register/PageRegister";
import { PublicOnlyRouteGuard } from "PublicOnlyRouteGuard";
import { ErrorPage } from "components/ErrorPage";
import { PageProfil } from "App/utilisateur/PageProfil";
import { AuthenticatedRouteGuard } from "AuthenticatedRouteGuard";
import { PageDons } from "App/dons/PageDons";

export function AccountRoutes() {
  const { setPageColor, setBackgroundColorNotif, setColorNotif, setColorNotifHover } = useApp();

  useEffect(() => {
    setPageColor("#EA5B0C");
    setBackgroundColorNotif("#EA5B0C");
    setColorNotif("#FFFFFF");
    setColorNotifHover("#000000");
  }, [setPageColor, setBackgroundColorNotif, setColorNotifHover, setColorNotif]);

  return (
    <Routes>
      <Route
        path="profil"
        element={
          <AuthenticatedRouteGuard>
            <PageProfil />
          </AuthenticatedRouteGuard>
        }
      />
      <Route
        path="proposerDon"
        element={
          <AuthenticatedRouteGuard>
            <PageDons />
          </AuthenticatedRouteGuard>
        }
      />
      <Route
        path="register"
        element={
          <PublicOnlyRouteGuard>
            <PageRegister />
          </PublicOnlyRouteGuard>
        }
      />
      <Route path="*" element={<ErrorPage errorCode={404} />} />
    </Routes>
  );
}
