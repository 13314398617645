/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

const IconOptions = React.forwardRef(({ color = "#FFFFFF", ...rest }, ref) => (
  <chakra.svg
    width="42"
    height="31"
    viewBox="0 0 42 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="0.5" y="0.5" width="41" height="30" rx="15" stroke={color} />
    <circle cx="13" cy="16" r="2.5" stroke={color} />
    <circle cx="21" cy="16" r="2.5" stroke={color} />
    <circle cx="29" cy="16" r="2.5" stroke={color} />
  </chakra.svg>
));
IconOptions.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconOptions;
