/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconPause({ color = "#E71D73", stroke = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="59"
      height="46"
      viewBox="0 0 59 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="58" height="45" rx="22.5" stroke={color} />
      <path
        d="M32.2493 33.3147C32.2493 33.9703 32.8082 34.5 33.5 34.5C34.1918 34.5 34.7507 33.9703 34.7507 33.3147V12.6853C34.7507 12.0297 34.1918 11.5 33.5 11.5C32.8082 11.5 32.2493 12.0297 32.2493 12.6853V33.3147Z"
        fill={color}
      />
      <path
        d="M24.5 33.3147C24.5 33.9703 25.0605 34.5 25.7507 34.5C26.441 34.5 27.0015 33.9703 27.0015 33.3147V12.6853C27.0015 12.0297 26.441 11.5 25.7507 11.5C25.0605 11.5 24.5 12.0297 24.5 12.6853V33.3147Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconPause.propTypes = {
  color: PropTypes.string,
  stroke: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconPause;
