import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import IconEnergie from "components/Icons/icons-generated/IconEnergie";
import IconNotif from "components/Icons/icons-generated/IconNotif";
import IconNotifActif from "components/Icons/icons-generated/IconNotifActif";
import IconCopier from "components/Icons/icons-generated/IconCopier";
import IconFavoris from "components/Icons/icons-generated/IconFavoris";
import { IconValider } from "components/Icons";
import IconDownload from "components/Icons/icons-generated/IconDowload";
import { ItemAide } from "./ItemAide";

export function TableAide() {
  const { t } = useTranslation();
  const pageColor = "#36A9E1";
  return (
    <Stack mb={8}>
      <Stack alignItems="center" mb={8}>
        <Text textTransform="uppercase" fontSize="24px" color="energieCyan">
          {t("aide:title")}
        </Text>
      </Stack>
      <ItemAide question={t("aide:question.laSource")} reponse={t("aide:reponse.laSource")} />
      <ItemAide question={t("aide:question.souverain")} reponse={t("aide:reponse.souverain")} />
      <ItemAide question={t("aide:question.energie")} reponse={t("aide:reponse.energie")} />
      <ItemAide question={t("aide:question.don")} reponse={t("aide:reponse.don")} />
      <ItemAide question={t("aide:question.donPonctuel")} reponse={t("aide:reponse.donPonctuel")} />
      <ItemAide
        question={t("aide:question.donPermanent")}
        reponse={t("aide:reponse.donPermanent")}
      />
      <ItemAide question={t("aide:question.donMensuel")} reponse={t("aide:reponse.donMensuel")} />
      <ItemAide question={t("aide:question.donFavori")} reponse={t("aide:reponse.donFavori")} />
      <ItemAide question={t("aide:question.donateur")} reponse={t("aide:reponse.donateur")} />
      <ItemAide
        question={t("aide:question.beneficiaire")}
        reponse={t("aide:reponse.beneficiaire")}
      />
      <ItemAide question={t("aide:question.projetDeVie")} reponse={t("aide:reponse.projetDeVie")} />

      <ItemAide question={t("aide:question.collectif")} reponse={t("aide:reponse.collectif")} />
      <ItemAide question={t("aide:question.reference")} reponse={t("aide:reponse.reference")} />
      <ItemAide question={t("aide:question.clePublique")} reponse={t("aide:reponse.clePublique")} />
      <ItemAide
        question={t("aide:question.statutDonNotifie")}
        reponse={t("aide:reponse.statutDonNotifie")}
      />
      <ItemAide
        question={t("aide:question.statutDonValide")}
        reponse={t("aide:reponse.statutDonValide")}
      />
      <ItemAide question={t("aide:question.historique")} reponse={t("aide:reponse.historique")} />
      <ItemAide
        question={t("aide:question.divinEnergy")}
        icon={<IconEnergie w="30px" color={pageColor} fill={pageColor} />}
        reponse={t("aide:reponse.divinEnergy")}
      />
      <ItemAide
        question={t("aide:question.notifications")}
        icon={
          <Stack h="60px">
            <IconNotif w="30px" color={pageColor} />
            <IconNotifActif w="30px" color={pageColor} />
          </Stack>
        }
        reponse={t("aide:reponse.notifications")}
      />
      <ItemAide
        question={t("aide:question.copierColler")}
        icon={<IconCopier w="30px" stroke={pageColor} fill={pageColor} />}
        reponse={t("aide:reponse.copierColler")}
      />
      <ItemAide
        question={t("aide:question.favoris")}
        icon={<IconFavoris w="30px" stroke={pageColor} fill={pageColor} />}
        reponse={t("aide:reponse.favoris")}
      />
      <ItemAide
        question={t("aide:question.validation")}
        icon={<IconValider w="30px" color="energieCyan" fill={pageColor} />}
        reponse={t("aide:reponse.validation")}
      />
      <ItemAide
        question={t("aide:question.reception")}
        icon={<IconDownload h="30px" w="30px" />}
        reponse={t("aide:reponse.reception")}
      />
    </Stack>
  );
}
