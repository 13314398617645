import { Box, HStack, Icon, Text, useRadio } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
import IconChecked from "components/Icons/icons-generated/IconChecked";

const propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  children: {},
  borderColor: "black",
  color: "white",
  isDisabled: false,
};

export function RadioCard({ children, isDisabled, borderColor, color, ...rest }) {
  const { getInputProps, getRadioProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        color="white"
        _checked={{
          color,
          borderColor: color,
        }}
        px={4}
        py={2}
      >
        <HStack justifyContent="space-between" h="100%">
          {isDisabled && input?.checked ? (
            <>
              <Text>{children}</Text>
              <IconChecked minHeight="25px" minWidth="25px" color={color} />
            </>
          ) : null}
          {/* {isDisabled && !input?.checked ? (
            <Text color="rgba(176, 176, 176, 0.5)">{children}</Text>
          ) : null} */}
          {isDisabled && !input?.checked ? (
            <Text color="rgba(176, 176, 176, 0.5)">{children}</Text>
          ) : null}
          {!isDisabled && !input?.checked ? (
            <>
              <Text>{children}</Text>
              <Icon minHeight="25px" minWidth="25px" as={BiRadioCircle} color={color} />
            </>
          ) : null}
          {!isDisabled && input?.checked ? (
            <>
              <Text>{children}</Text>
              <Icon minHeight="25px" minWidth="25px" as={BiRadioCircleMarked} color={color} />
            </>
          ) : null}
        </HStack>
      </Box>
    </Box>
  );
}
RadioCard.propTypes = propTypes;
RadioCard.defaultProps = defaultProps;
