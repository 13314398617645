import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

export function MyToast({
  status = "info",
  backgroundColor = "",
  color = "white",
  text = "Info",
  title = "Info",
}) {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return isVisible ? (
    <Alert status={status} backgroundColor={backgroundColor} color={color}>
      <AlertIcon color={color} />
      <Box>
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{text}</AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  ) : null;
}
MyToast.propTypes = {
  status: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};
