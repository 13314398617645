import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "components/Layout";
import { PageLogin } from "App/login/PageLogin";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorBoundary } from "components/ErrorBoundary";
import { ErrorPage } from "components/ErrorPage";
import { Loader } from "components/Loader";
import { PageCollectifs } from "App/collectifs/PageCollectifs";
import { PageEnvoyerDon } from "App/dons/PageEnvoyerDon";
import { PageEnvoyerDonEdit } from "App/dons/PageEnvoyerDonEdit";
import { PageAdmin } from "App/admin/PageAdmin";
import { PageLogout } from "App/logout/PageLogout";
import { PublicOnlyRouteGuard } from "PublicOnlyRouteGuard";
import { AuthenticatedRouteGuard } from "AuthenticatedRouteGuard";
import { PageDecouvrir } from "App/decouvrir/PageDecouvrir";
import { DashboardRoutes } from "DashboardRoutes";
import { PagePartenaires } from "App/partenaires/PagePartenaires";
import { AccountRoutes } from "./AccountRoutes";

export function App() {
  const { t } = useTranslation();
  useEffect(() => {
    document.getElementsByTagName("META")[2].content = t("app:description");
  });

  return (
    <ErrorBoundary>
      <BrowserRouter basename="/">
        <Layout>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="divinenergie" element={<PageDecouvrir />} />
              <Route
                path="donsEnergie"
                element={
                  <AuthenticatedRouteGuard>
                    <PageEnvoyerDon />
                  </AuthenticatedRouteGuard>
                }
              />
              <Route
                path="admin"
                element={
                  <AuthenticatedRouteGuard>
                    <PageAdmin />
                  </AuthenticatedRouteGuard>
                }
              />
              <Route
                path="donsEnergie/:id"
                element={
                  <AuthenticatedRouteGuard>
                    <PageEnvoyerDonEdit />
                  </AuthenticatedRouteGuard>
                }
              />
              <Route path="partenaires" element={<PagePartenaires />} />
              <Route
                path="collectifs"
                element={
                  <AuthenticatedRouteGuard>
                    <PageCollectifs />
                  </AuthenticatedRouteGuard>
                }
              />

              <Route
                path="login"
                element={
                  <PublicOnlyRouteGuard>
                    <PageLogin />
                  </PublicOnlyRouteGuard>
                }
              />
              <Route
                path="logout"
                element={
                  <ErrorBoundary>
                    <PageLogout />
                  </ErrorBoundary>
                }
              />

              <Route
                path="dashboard/*"
                element={
                  <AuthenticatedRouteGuard>
                    <DashboardRoutes />
                  </AuthenticatedRouteGuard>
                }
              />

              <Route
                path="account/*"
                element={
                  <ErrorBoundary>
                    <AccountRoutes />
                  </ErrorBoundary>
                }
              />

              <Route path="*" element={<ErrorPage errorCode={404} />} />
            </Routes>
          </Suspense>
        </Layout>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </ErrorBoundary>
  );
}
