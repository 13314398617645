import React, {useEffect, useState} from "react";
import {Grid, GridItem, HStack, Spinner, Stack, Text, useBreakpointValue, useDisclosure,} from "@chakra-ui/react";
import {useGetUserById} from "App/utilisateur/services/useGetUserById";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import {Formiz, useForm} from "@formiz/core";
import {useTranslation} from "react-i18next";
import {FieldFactory} from "components/fields/FieldFactory";
import {useGlobalEvents} from "hooks/useGlobalEvents";
import {useApp} from "context/App/useApp";
import {FormatDate} from "functions/common-scope/utils";
import {useGetPaysCurrentUser} from "App/register/services/useGetPaysCurrentUser";
import {useLanguageContext} from "context/lang-context";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import IconEnergieProposerDon from "components/Icons/icons-generated/IconEnergieProposerDon";
import {ModalCustom} from "components/Modal/Modal";
import {ButtonsValider} from "components/Buttons/ButtonsValider";
import {useAuthentificationContext} from "context/auth-context";
import {isEmail, isMinLength, isNumber} from "@formiz/validations";
import {useGetDonsById} from "../services/useGetDonsById";
import {useEnvoyerDon} from "../services/useEnvoyerDon";
import {handleResearchUser, handleResearchUserByMobile} from "../../utilisateur/services/useSearchUser";

export function FormEnvoyerDons() {
    const {t} = useTranslation();
    const {isOpen: isOpenValider, onClose: onCloseValider, onOpen: onOpenValider} = useDisclosure();
    const loginForm = useForm({
        subscribe: "form",
    });
    const {lang} = useLanguageContext();
    const isMobile = useBreakpointValue({base: true, md: false});

    const {pageColor} = useApp();
    const [hoverColor, setHoverColor] = useState({
        isHover: false,
    });
    const [searchParams] = useSearchParams();
    const [choices, setChoices] = useState([]);

    const {dons, isLoading: isLoadingDons} = useGetDonsById({donId: searchParams.get("donId")});

    const {user: userBenef, isLoading: isLoadingBenef} = useGetUserById({id: dons?.usager});
    const [refBenef, setRefBenef] = useState(userBenef?.clePublique);

    const {user, isLoading} = useAuthentificationContext();
    useEffect(() => {
        if (userBenef?.clePublique) {
            setRefBenef(userBenef.clePublique);
            setChoices([
                {
                    label: `${userBenef.identite} - ${userBenef.clePublique}`,
                    value: userBenef.clePublique,
                },
            ]);
        }
    }, [userBenef]);

    const navigate = useNavigate();
    useGlobalEvents({
        form: loginForm,
        type: "keypress",
        key: "Enter",
        action: "submitForm",
    });

    const onSuccess = () => {
        navigate("/dashboard/historique");
    };

    const {mutate: envoyerDon, isLoading: isLoadingCreate} = useEnvoyerDon({onSuccess});

    const handleEnvoyerDon = (values) => {
        const datas = {...values};
        if (searchParams.get("donId")) {
            datas.refDon = searchParams.get("donId");
        }
        envoyerDon(datas);
    };

    // const handleColler = () => {
    //   try {
    //     return navigator.clipboard.readText().then((clipText) => console.log(clipText));
    //     // console.log(text);
    //     // return setRefBenef(text);
    //   } catch (error) {
    //     console.log(error);
    //     return null;
    //   }
    // };
    const {pays, isLoading: isLoadingPays} = useGetPaysCurrentUser();

    if (isLoadingDons || isLoading || isLoadingBenef || isLoadingPays) {
        return null;
    }

    const date = new Date();
    return (
        <Formiz id="dons-form" autoForm onValidSubmit={handleEnvoyerDon} connect={loginForm}>
            <Stack mb={8}>
                <Stack alignItems="center" mb={8}>
                    <Text textTransform="uppercase" fontSize="24px" color="energieRose">
                        {t("dons:envoyer")}
                    </Text>
                </Stack>
                <Stack alignItems="center" borderTopRadius="10px" backgroundColor={pageColor}>
                    <Text fontSize="16px" color="energieBlack.500" fontWeight="bold">
                        {`${t("dons:reference")}: ${pays.codeIso}${user.identite}_${FormatDate(
                            date,
                            "YYYY-MM-DD H:m:s",
                            lang
                        )}`}
                    </Text>
                </Stack>
                <Stack background="#E71D7366" position="relative">
                    <HStack p={1} alignSelf="center" alignItems="center">
                        <Text color="white">{`${t("account:energieDisponible")}`} </Text>
                        <IconEnergieSansPlus color="white"/>
                        <Text color="white">{user?.energiesDisponible ?? 0} </Text>
                    </HStack>
                </Stack>
            </Stack>
            <Grid
                columnGap={4}
                padding={isMobile ? 2 : 0}
                templateAreas={[
                    `"reference"
              "description"
              "contact"
              "quantite"`,
                    `"reference quantite"
            "contact ."
            "description description"
            `,
                ]}
            >
                <GridItem h="100%" area="reference">
                    <Text textTransform="uppercase" fontSize="18px" color="energieRose">
                        {t("dons:referenceBenef")}
                    </Text>
                    {userBenef !== null && refBenef === undefined ? (
                        <Spinner/>
                    ) : (
                        <HStack minH="120px" borderLeft="solid #E71D73 4px" p={4} spacing={4}>
                            {!dons?.id ? <Stack>
                                <FieldFactory
                                    country={pays?.codeIso ?? "FR"}
                                    label={`${t("app:core.form.label.mobile")}`}
                                    type="phone"
                                    name="mobile"
                                    placeholder={`${t("app:core.form.placeholder.mobile")}`}
                                    onKeyUp={(e) => handleResearchUserByMobile({e, setChoices, setRefBenef})}
                                    required={`${t("dons:required.referenceBenef")}`}
                                    validations={[
                                        {
                                            rule: isNumber(),
                                            message: t("app:core.form.label.number"),
                                        },
                                    ]}
                                />
                                {choices && choices.length > 0 && (
                                    <FieldFactory
                                        noOptionsMessage={`${t("app:core.form.placeholder.selectUser")}`}
                                        type="select"
                                        name="refBenef"
                                        placeholder={`${t("app:core.form.placeholder.selectUser")}`}
                                        options={choices}
                                        onKeyUp={(e) => handleResearchUser({e, setChoices})}
                                        required={`${t("app:core.form.required.envoyeDon")}`}
                                        setParentValue={setRefBenef}
                                        defaultValue={`${refBenef ?? choices[0].value}`}
                                        isReadOnly={!!dons?.id}
                                    />
                                )}
                            </Stack> : <FieldFactory
                                noOptionsMessage={`${t("app:core.form.placeholder.envoyeDon")}`}
                                type="select"
                                name="refBenef"
                                placeholder={`${t("app:core.form.placeholder.envoyeDon")}`}
                                options={choices}
                                onKeyUp={(e) => handleResearchUser({e, setChoices})}
                                required={`${t("app:core.form.required.envoyeDon")}`}
                                setParentValue={setRefBenef}
                                defaultValue={`${refBenef}`}
                                isReadOnly={!!dons?.id}
                            />}


                            {/* <IconCle cursor="pointer" onClick={handleColler} /> */}
                        </HStack>
                    )}
                </GridItem>
                <GridItem h="100%" area="quantite">
                    <Stack>
                        <Text textTransform="uppercase" fontSize="18px" color="energieRose">
                            {t("dons:quantiteEnvoye")}
                        </Text>
                        <HStack
                            minH="120px"
                            p={4}
                            marginTop="0px!important;"
                            borderLeft="solid #E71D73 4px"
                            spacing={4}
                        >
                            <IconEnergieProposerDon color={pageColor}/>
                            <FieldFactory
                                type="number"
                                name="quantite"
                                textAlign="center"
                                validations={[
                                    {
                                        rule: isNumber(),
                                        message: t("app:core.form.label.formatEmail"),
                                    },
                                    {
                                        rule: (val) => val >= 1,
                                        message: t("app:core.form.label.number"),
                                    },
                                ]}
                                defaultValue={dons?.quantite ?? 1}
                            />
                        </HStack>
                    </Stack>
                </GridItem>

                <GridItem area="contact">
                    {userBenef ? (
                        <>
                            <Text textTransform="uppercase" fontSize="18px" color="energieRose">
                                {t("dons:contact")}
                            </Text>
                            <Stack p={4} spacing={4} borderLeft="solid #E71D73 4px">
                                <FieldFactory
                                    type="text"
                                    name="mail"
                                    label={`${t("app:core.form.label.mail")}`}
                                    validations={[
                                        {
                                            rule: isEmail(),
                                            message: "Le format n'est pas valide.",
                                        },
                                    ]}
                                    required={`${t("app:core.form.required.mail")}`}
                                    placeholder={`${t("app:core.form.placeholder.mail")}`}
                                    defaultValue={user?.mail}
                                />
                                <FieldFactory
                                    country={pays?.codeIso ?? "FR"}
                                    label={`${t("app:core.form.label.fixe")}`}
                                    type="phone"
                                    name="fixe"
                                    placeholder={`${t("app:core.form.placeholder.fixe")}`}
                                    defaultValue={user?.fixe}
                                />
                                <FieldFactory
                                    country={pays?.codeIso ?? "FR"}
                                    label={`${t("app:core.form.label.mobile")}`}
                                    type="phone"
                                    name="mobile"
                                    placeholder={`${t("app:core.form.placeholder.mobile")}`}
                                    defaultValue={user?.mobile}
                                />
                            </Stack>
                        </>
                    ) : null}
                </GridItem>

                <GridItem h="100%" area="description">
                    <Text textTransform="uppercase" fontSize="18px" color="energieRose">
                        {t("dons:description.title")}
                    </Text>
                    <Stack p={4} mb={4} borderLeft="solid #E71D73 4px" spacing={4}>
                        <FieldFactory
                            defaultValue={searchParams.get("nom") ?? dons?.nom}
                            label={`${t("dons:titre")}`}
                            type="text"
                            name="nom"
                            placeholder={`${t("dons:placeholder.donDirect")}`}
                            toUpperCase
                            required={`${t("dons:required.titre")}`}
                            isDisabled={!!dons?.id}
                        />
                        <FieldFactory
                            height="150px"
                            name="descriptif"
                            label={`${t("dons:descriptif.autre")}`}
                            type="textarea"
                            validations={[
                                {
                                    rule: isMinLength(100),
                                    message: t("app:core.form.label.length"),
                                },
                            ]}
                            placeholder={
                                searchParams.get("description") ??
                                dons?.description ??
                                `${t("dons:descriptif.donDirect")}`
                            }
                            required={`${t("dons:required.descriptif")}`}
                        />
                    </Stack>
                </GridItem>
            </Grid>
            <HStack
                borderTop="solid #E71D73 4px"
                alignItems="center"
                px={1}
                spacing={4}
                py={4}
                my={8}
                h="100%"
                justifyContent="end"
            >
                <ButtonsValider
                    minW="80px"
                    className={hoverColor.isHover ? "logoHover" : ""}
                    onMouseEnter={() => setHoverColor({isHover: true})}
                    onMouseLeave={() => setHoverColor({isHover: false})}
                    onClick={onOpenValider}
                    isDisabled={isLoadingCreate || !loginForm.isValid || !refBenef}
                    isLoading={isLoadingCreate}
                    color={pageColor}
                />
            </HStack>
            <ModalCustom
                title={t("dons:valider.modalTitle")}
                textDescription={t("dons:valider.modalDescription")}
                bgColor="energieRose"
                color="white"
                isOpen={isOpenValider}
                onClose={onCloseValider}
                handleValider={loginForm.submit}
                className={hoverColor.isHover ? "logoHover" : ""}
                onMouseEnter={() => setHoverColor({isHover: true})}
                onMouseLeave={() => setHoverColor({isHover: false})}
                isLoading={isLoadingCreate}
            >
                <Stack justifyContent="center" spacing={4} p={4}>
                    <FieldFactory
                        type="datetime"
                        name="heurenaissanceConfirm"
                        placeholder={`${t("app:core.form.placeholder.heure")}`}
                        required={`${t("app:core.form.required.heure")}`}
                    />
                </Stack>
            </ModalCustom>
        </Formiz>
    );
}
