import { Stack, Icon, Text, Box } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export function LabelValeur(props) {
  // Hooks
  const { t } = useTranslation();

  // Variables
  const { label, children, icon, horizontal, ...rest } = props;
  const colorLabel = "gray.500";
  const colorValue = "gray.600";

  return (
    <Stack
      fontSize="sm"
      spacing="5px"
      alignItems={horizontal ? "center" : "flex-start"}
      direction={horizontal ? "row" : "column"}
      {...rest}
    >
      {/* Icon */}
      {icon && <Icon color={colorLabel} as={icon} />}
      {/* Label */}
      {label && <Text color={colorLabel}>{`${label} `}</Text>}

      {/* Valeur */}
      <Box textAlign="left" fontWeight="bold" color={colorValue}>
        {children ?? <Text fontStyle="italic">{t("app:global.non_renseigne")}</Text>}
      </Box>
    </Stack>
  );
}

LabelValeur.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  icon: PropTypes.func,
  horizontal: PropTypes.bool,
};

LabelValeur.defaultProps = {
  label: null,
  children: null,
  icon: null,
  horizontal: true,
};
