import React from "react";
import { HStack, Heading, Link, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function TextMentions() {
  const { t } = useTranslation();
  return (
    <Stack bg="energieCyan" textTransform="uppercase">
      <Heading color="white">{t("mentions:editeur")}</Heading>
      <Text color="white">{t("mentions:editeurText")}</Text>
      <Text
        cursor="pointer"
        textDecoration="underline"
        color="white"
        onClick={(e) => {
          window.location.href = "mailto:pierre@divinenergy.info";
          e.preventDefault();
        }}
      >
        {t("mentions:editeurEmail")}
      </Text>
      <Heading color="white">{t("mentions:hebergeur")}</Heading>
      <Text color="white">{t("mentions:hebergeurText")}</Text>
      <Text color="white">{t("mentions:hebergeurDenomination")}</Text>
      <Text color="white">{t("mentions:hebergeurDenominationSite")}</Text>
      <Text color="white">{t("mentions:hebergeurDenominationSouverain")}</Text>
      <Text color="white">{t("mentions:hebergeurCnc")}</Text>
      <Text color="white">{t("mentions:hebergeurCncText")}</Text>
      <Text
        cursor="pointer"
        onClick={(e) => {
          window.location.href = "mailto:pierre@divinenergy.info";
          e.preventDefault();
        }}
        textDecoration="underline"
        color="white"
      >
        {t("mentions:emailContact")}
      </Text>
      <Heading color="white">{t("mentions:cg")}</Heading>
      <Text color="white">{t("mentions:cgText1")}</Text>
      <Text color="white">{t("mentions:cgText2")}</Text>
      <Text color="white">{t("mentions:cgText3")}</Text>
      <Text color="white">{t("mentions:cgText4")}</Text>
      <Text color="white">{t("mentions:cgText5")}</Text>
      <Text color="white">{t("mentions:cgText6")}</Text>
      <Text color="white">{t("mentions:cgText7")}</Text>
      <Text color="white">{t("mentions:cgText8")}</Text>
      <Text color="white">{t("mentions:cgText9")}</Text>
      <Text color="white">{t("mentions:cgText10")}</Text>
      <Heading color="white">{t("mentions:politique")}</Heading>
      <Text color="white">{t("mentions:perso")}</Text>
      <Heading color="white">{t("mentions:finalite")}</Heading>
      <Text color="white">{t("mentions:finaliteText1")}</Text>
      <Text color="white">{t("mentions:finaliteText2")}</Text>
      <Text color="white">{t("mentions:finaliteText3")}</Text>
      <Text color="white">{t("mentions:finaliteText4")}</Text>
      <Text color="white">{t("mentions:finaliteText5")}</Text>
      <Heading color="white">{t("mentions:propriete")}</Heading>
      <Text color="white">{t("mentions:proprieteText1")}</Text>
      <Text
        cursor="pointer"
        color="white"
        onClick={(e) => {
          window.location.href = "mailto:pierre@divinenergy.info";
          e.preventDefault();
        }}
      >
        {t("mentions:proprieteText2")}
      </Text>
      <Heading color="white">{t("mentions:source")}</Heading>
      <HStack>
        <Link
          cursor="pointer"
          textDecoration="underline"
          isExternal
          href="https://placedeshumains.com/"
        >
          {t("mentions:footer1")}
        </Link>
      </HStack>
      <Link
        cursor="pointer"
        textDecoration="underline"
        isExternal
        href=" https://divinenergy.info/"
      >
        {t("mentions:footer2")}
      </Link>
      <Link cursor="pointer" textDecoration="underline" isExternal href="https://esseniens.org/">
        {t("mentions:footer3")}
      </Link>
    </Stack>
  );
}
