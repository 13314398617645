/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconModifier({ color = "#E71D73", stroke = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="59"
      height="46"
      viewBox="0 0 59 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="58" height="45" rx="22.5" stroke={color} />
      <path
        d="M38.0715 22C37.6374 22 37.2856 22.3517 37.2856 22.7858V30.6432C37.2856 31.0774 36.9339 31.4291 36.4998 31.4291H22.3572C21.923 31.4291 21.5713 31.0774 21.5713 30.6432V14.9284C21.5713 14.4943 21.923 14.1426 22.3572 14.1426H31.7859C32.22 14.1426 32.5718 13.7908 32.5718 13.3567C32.5718 12.9225 32.22 12.5708 31.7859 12.5708H22.3572C21.0552 12.5713 20 13.6269 20 14.9284V30.6428C20 31.9448 21.0552 33 22.3572 33H36.5002C37.8022 33 38.8574 31.9448 38.8574 30.6428V22.7854C38.8574 22.3517 38.5057 21.9995 38.0715 21.9995V22Z"
        fill={color}
      />
      <path
        d="M41.1198 11.8812C40.5558 11.3168 39.7906 11 38.9928 11C38.1945 10.9977 37.4284 11.3154 36.8658 11.8817L26.5163 22.2302C26.4302 22.3168 26.3658 22.4217 26.3271 22.5373L24.7558 27.2516C24.6186 27.6632 24.8414 28.1084 25.253 28.2451C25.3331 28.2718 25.4165 28.2852 25.5007 28.2857C25.585 28.2857 25.6688 28.2718 25.7489 28.2456L30.4632 26.6743C30.5788 26.6356 30.6842 26.5707 30.7703 26.4842L41.1198 16.1347C42.2947 14.9603 42.2947 13.0556 41.1198 11.8812ZM40.0084 15.0247L29.7938 25.2393L26.7419 26.2586L27.758 23.2108L37.9767 12.9958C38.5384 12.435 39.4486 12.436 40.0089 12.9976C40.2768 13.266 40.4278 13.6293 40.4287 14.0082C40.4297 14.3894 40.2782 14.7549 40.0084 15.0242V15.0247Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconModifier.propTypes = {
  color: PropTypes.string,
  stroke: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconModifier;
