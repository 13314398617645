import Axios from "axios";
import { APPENV } from "config/config";
import { useQuery } from "react-query";

export const useGetDonsById = ({donId}) => {
  const {data, isLoading, isFetching } = useQuery(['don',donId],
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/dons/${donId}`
      ),
  {enabled: !!donId});

  const dons = data?.data ?? null;
  return {dons, isLoading, isFetching};
}

