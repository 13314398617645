import Axios from "axios";
import { APPENV } from "config/config";
import { useQuery } from "react-query";
export const useGetPaysAdmin = () => {
  const {data, isLoading, isFetching } = useQuery('pays_admin',
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/pays/admin`
      ),
  );

  const pays = data?.data ?? null;
  return {pays, isLoading, isFetching};
}

