/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconHistoTriangleBas({ color = "#8CA0A0", ...rest }) {
  return (
    <chakra.svg
      width="77"
      height="32"
      viewBox="0 0 77 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M40.7205 30.5181C39.3761 31.4153 37.6239 31.4153 36.2795 30.5181L2.28023 7.82708C-1.01075 5.6307 0.544123 0.500002 4.50071 0.500002L72.4993 0.499996C76.4559 0.499996 78.0107 5.63069 74.7198 7.82708L40.7205 30.5181Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconHistoTriangleBas.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconHistoTriangleBas;
