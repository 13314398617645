import Axios from "axios";
import { APPENV } from "config/config";
import {  useQuery } from "react-query";

export const useGetCollectifsByPaysId = (paysId) => {
  const {data, isLoading, isFetching } = useQuery(['collectifs_pays',paysId], 
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/collectifs?paysId=${paysId}`
      ),
      {
        enabled: !!paysId
      }
  );

  const collectifs = data?.data ?? null;
  return {collectifs, isLoading, isFetching};
}
