import React from "react";
import { createRoot } from "react-dom/client";
import "config/dayjs";
import "config/axios";
import "config/i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider } from "@chakra-ui/react";
import { customTheme } from "theme/customTheme";
import { NavbarProvider } from "context/navbar-context";
import { CookiesProvider } from "react-cookie";
import { AppProvider } from "context/App/AppProvider";
import { AuthentificationProvider } from "context/auth-context";
import { LanguageProvider } from "context/lang-context";
import { App } from "./App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

/**
 * Merge des thèmes MUI et Chakra
 */
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <CookiesProvider>
          <NavbarProvider>
            <AuthentificationProvider>
              <LanguageProvider>
                <ChakraProvider resetCSS theme={customTheme}>
                  <App />
                </ChakraProvider>
              </LanguageProvider>
            </AuthentificationProvider>
          </NavbarProvider>
        </CookiesProvider>
      </AppProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
