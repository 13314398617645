import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import IconNotif from "components/Icons/icons-generated/IconNotif";
import IconGoBack from "components/Icons/icons-generated/IconGoBack";
import IconOptions from "components/Icons/icons-generated/IconOptions";
import { useReadAllNotifications } from "App/dons/services/useReadAllNotifications";
import IconNotifActif from "components/Icons/icons-generated/IconNotifActif";
import { useApp } from "context/App/useApp";
import { useTranslation } from "react-i18next";
import { Formiz, useForm } from "@formiz/core";
import { FieldFactory } from "components/fields/FieldFactory";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { CardNotif } from "./CardNotif";
import { useGetNotifications } from "./services/useGetNotifications";

export function NotificationsDrawer() {
  const [hoverColor, setHoverColor] = useState({
    color: "#E71D73",
    isHover: false,
  });
  const form = useForm({ subscribe: "form" });
  const queryClient = useQueryClient();
  const { notifications, isLoading } = useGetNotifications();
  const [data, setData] = useState(notifications);
  const { pageColor } = useApp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const onSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: "notifications" });
  };
  const { mutate: readAll } = useReadAllNotifications({ onSuccess });

  useEffect(() => {
    if (!isLoading) {
      setData(notifications);
    }
  }, [isLoading, notifications]);

  const haveNotif =
    (notifications?.filter((n) => n?.notifications?.lu === false) ?? [])?.length > 0;
  const handleAllRead = () => {
    readAll();
  };

  const handleSearchDon = (values) => {
    if (values?.search) {
      const searchNumber = !isNaN(values.search);
      if (searchNumber) {
        setData((old) => old.filter((n) => Number(n.dons.quantite) === Number(values.search)));
      } else {
        setData((old) =>
          old.filter(
            (n) =>
              n.dons.reference.toLowerCase().indexOf(values.search.toLowerCase()) > -1 ||
              n.usager.clePublique.toLowerCase().indexOf(values.search.toLowerCase()) > -1 ||
              n.destinataire.clePublique.toLowerCase().indexOf(values.search.toLowerCase()) > -1
          )
        );
      }
    } else {
      setData(notifications);
    }
  };

  return (
    <>
      {haveNotif ? (
        <IconNotifActif
          color={pageColor}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          _hover={{ cursor: "pointer" }}
          onClick={onOpen}
          h="30px"
        />
      ) : (
        <IconNotif
          color={pageColor}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          _hover={{ cursor: "pointer" }}
          onClick={onOpen}
          h="30px"
        />
      )}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent bgColor="energieBlack.500">
          <DrawerHeader
            w="100%"
            alignSelf="center"
            textAlign="center"
            color="#B0B0B0"
            borderBottom="1px solid rgba(255, 255, 255, 0.5);"
          >
            <Stack>
              <HStack justifyContent="space-between">
                <IconGoBack onClick={onClose} />
                {haveNotif ? (
                  <IconNotifActif mx="auto" h="30px" color="#FFFFFF" />
                ) : (
                  <IconNotif mx="auto" h="30px" color="#FFFFFF" />
                )}
                <Popover placement="bottom-end">
                  <PopoverTrigger>
                    <Stack>
                      <IconOptions mx="auto" h="30px" color="#FFFFFF" />
                    </Stack>
                  </PopoverTrigger>
                  <PopoverContent backgroundColor="energieBlack.500" textAlign="left">
                    <PopoverBody>
                      <Stack p={2}>
                        <Text
                          color="#B0B0B0"
                          fontSize="12px"
                          cursor="pointer"
                          onClick={() => handleAllRead()}
                        >
                          {t("notifications:marquerAll")}
                        </Text>
                        {/* <Text color="#B0B0B0" fontSize="12px">
                          {t("notifications:gerer")}
                        </Text> */}
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </HStack>
              <Text textTransform="uppercase">{t("notifications:title")}</Text>
              <Formiz id="dons-form" autoForm onValidSubmit={handleSearchDon} connect={form}>
                <HStack>
                  <FieldFactory
                    type="text"
                    textAlign="center"
                    name="search"
                    placeholder={`${t("dons:search")}`}
                  />
                  <ButtonsValider color={pageColor} onClick={form.submit} isDisabled={isLoading} />
                </HStack>
              </Formiz>
            </Stack>
          </DrawerHeader>
          <DrawerBody>
            {!isLoading ? (
              <Stack divider={<StackDivider border="1px solid rgba(255, 255, 255, 0.5);" />}>
                {data?.map((notif) => (
                  <CardNotif key={notif.notifications.id} notif={notif} onClose={onClose} />
                ))}
              </Stack>
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
