import { IconButton } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import IconSupprimer from "components/Icons/icons-generated/IconSupprimer";

const propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
  isDisabled: false,
  color: "white",
  className: "",
};

export function ButtonsSupprimer(props) {
  const {
    color,
    isLoading = false,
    isDisabled = false,
    onMouseEnter,
    onMouseLeave,
    onClick,
    className,
    ...rest
  } = props;

  return (
    <IconButton
      _hover={{
        backgroundColor: "transparent",
      }}
      color={color}
      isDisabled={isDisabled}
      isLoading={isLoading}
      background="transparent"
      border="none"
      w="50px"
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      minH="50px"
      icon={<IconSupprimer color={color} {...rest} />}
    />
  );
}
ButtonsSupprimer.propTypes = propTypes;
ButtonsSupprimer.defaultProps = defaultProps;
