import Axios from "axios";
import { APPENV } from "config/config";
import { useQuery } from "react-query";

export const useGetCommentairesDon = ({id, params = new URLSearchParams(), enabled = false}) => {
  const {data, isLoading, isFetching } = useQuery(['messagerie',id, params.toString()],
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/dons/${id}/messages`, {
          params
        },
      ),
      {
        enabled
    }
  );

  const messages = data?.data ?? null;
  return {messages, isLoading, isFetching};
}

