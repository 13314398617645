import React, {useRef, useState} from "react";
import {Center, Stack, useBreakpointValue, useToast} from "@chakra-ui/react";
import {Formiz, useForm} from "@formiz/core";
import {useTranslation} from "react-i18next";
import {FieldFactory} from "components/fields/FieldFactory";
import {useGlobalEvents} from "hooks/useGlobalEvents";
import {useLocation} from "react-router";
import {useLogin} from "App/register/services/useLogin";
import PropTypes from "prop-types";
import {ButtonsValider} from "components/Buttons/ButtonsValider";
import dayjs from "dayjs";
import {handleResearch} from "App/dons/services/useSearchLieu";
import {MyToast} from "components/Toast/MyToast";

export function FormLogin({onSuccess = null}) {
    const [selectedChoice, setSelectedChoices] = useState();
    const [choices, setChoices] = useState([]);
    // const [mdp, setMdp] = useState(null);
    const mdp = useRef(null);
    const toast = useToast();

    const {t} = useTranslation();
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const loginForm = useForm({
        subscribe: "form",
    });
    const isMobile = useBreakpointValue({base: true, md: false});
    const [hoverColor, setHoverColor] = useState("#E71D73");
    useGlobalEvents({
        form: loginForm,
        type: "keypress",
        key: "Enter",
        action: "submitForm",
    });

    const {mutate, isLoading} = useLogin({onSuccess});

    const beforeSubmit = () => {
        if (mdp?.current?.length === 12 && !Number.isNaN(parseInt(mdp?.current, 10))) {
            loginForm.submit();
        } else {
            toast({
                duration: 9000,
                position: "top",
                render: () => (
                    <MyToast
                        status="error"
                        backgroundColor="#EA5B0C"
                        color="white"
                        title={t("app:core.form.label.formatHeure")}
                        text={t("app:core.form.label.formatHeure")}
                    />
                ),
            });
        }
    };

    const handleChangeMdp = (e) => {
        mdp.current = e;
    };

    const handleConnexion = (values) => {
        mutate({...values, ...selectedChoice});
    };

    const handleChange = (val) => {
        setSelectedChoices(val);
        setTimeout(() => {
            beforeSubmit();
        }, 500);
    };

    return (
        <Formiz id="login-form" autoForm onValidSubmit={handleConnexion} connect={loginForm}>
            <Stack spacing={4} m={isMobile ? 12 : 4} maxW={isMobile ? "80%" : "350px"}>
                <Stack spacing={8}>
                    <FieldFactory
                        type="text"
                        capitalize
                        textAlign="center"
                        name="identite"
                        required={`${t("app:core.form.required.prenom")}`}
                        label={`${t("app:core.form.label.prenom")}`}
                        placeholder={`${t("app:core.form.placeholder.prenom")}`}
                        defaultValue={params.get("identite") ?? ""}
                    />
                    <FieldFactory
                        type="datetime"
                        name="password"
                        label={`${t("app:core.form.label.heureParticulier")}`}
                        textAlign="center"
                        placeholder={`${t("app:core.form.placeholder.heure")}`}
                        required={`${t("app:core.form.required.heure")}`}
                        validations={[
                            {
                                rule: (val) => dayjs(val).isValid(),
                                message: t("app:core.form.label.formatHeure"),
                            },
                        ]}
                        setState={handleChangeMdp}
                    />
                    <FieldFactory
                        type="select"
                        noOptionsMessage={`${t("app:core.form.placeholder.saisissezAdresse")}`}
                        name="lieu"
                        label={`${t("app:core.form.label.lieu")}`}
                        placeholder={`${t("app:core.form.placeholder.rechercher")}`}
                        options={choices}
                        onKeyUp={(e) => handleResearch({e, setChoices})}
                        required={`${t("app:core.form.required.lieu")}`}
                        setParentValue={handleChange}
                        withObject
                    />
                </Stack>

                <Stack>
                    <Center>
                        <ButtonsValider
                            onMouseEnter={() => setHoverColor("#FFFFFF")}
                            onMouseLeave={() => setHoverColor("#E71D73")}
                            onClick={beforeSubmit}
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            color={hoverColor}
                        />
                    </Center>
                </Stack>
            </Stack>
        </Formiz>
    );
}

FormLogin.propTypes = {
    onSuccess: PropTypes.func,
};
