import React, { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { ViewportHeight } from "App/layout/ViewportHeight";
import PropTypes from "prop-types";
import { LoginModalInterceptor } from "./LoginModalInterceptor";

export function Layout({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ViewportHeight w="100vw" h="100vh" minH="100%" overflow="auto">
      <Flex flex="1" direction="column">
        {children}
      </Flex>
      <LoginModalInterceptor />
    </ViewportHeight>
  );
}
Layout.propTypes = {
  children: PropTypes.node,
};
