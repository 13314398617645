/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconTelegram({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.14 0L0 8.55V9.79L6.05 11.66L7.96 17.8H9.49L11.95 15.28L17.15 19.1L18.16 18.71L22.01 0.73L21.14 0ZM17.12 17.47L10.27 12.44L9.51 13.48L10.91 14.51L8.92 16.51L7.36 11.49L16.42 6.08L15.76 4.97L6.56 10.46L2.11 9.09L20.5 1.65L17.13 17.47H17.12Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconTelegram.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconTelegram;
