import Axios from "axios";
import { APPENV } from "config/config";
import {  useQuery } from "react-query";

export const useGetUserById = ({id}) => {
  const {data, isLoading, isFetching } = useQuery([`user`, id],
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/user/${id}`
      ),
      {enabled: !!id}
  );

  const user = data?.data ?? null;
  return {user, isLoading, isFetching};
}
