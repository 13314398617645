import React from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { Header } from "components/Layout/Header";
import { Footer } from "components/Layout/Footer";
import { TableAide } from "./_partials/TableAide";

export function PageAide() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <SlideIn>
      <Header />
      <Box p={4} w={isMobile ? "100%" : "70%"} mx="auto">
        <TableAide />
      </Box>
      <Footer />
    </SlideIn>
  );
}
