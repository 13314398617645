import React, { useEffect, useState } from "react";
import {
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import { Formiz, useForm } from "@formiz/core";
import { useTranslation } from "react-i18next";
import { FieldFactory } from "components/fields/FieldFactory";
import { useGlobalEvents } from "hooks/useGlobalEvents";
import { useApp } from "context/App/useApp";
import { useNavigate } from "react-router";
import { useQueryClient } from "react-query";
import { isEmail, isMinLength, isNumber } from "@formiz/validations";
import { useSearchParams } from "react-router-dom";
import IconEnergieProposerDon from "components/Icons/icons-generated/IconEnergieProposerDon";
import { ModalCustom } from "components/Modal/Modal";
import { useLanguageContext } from "context/lang-context";
import { useGetPaysCurrentUser } from "App/register/services/useGetPaysCurrentUser";
import { FormatDate } from "functions/common-scope/utils";
import { ButtonsSupprimer } from "components/Buttons/ButtonsSupprimer";
import { ReactRadioInput } from "components/fields/ReactRadioInput";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { useAuthentificationContext } from "context/auth-context";
import { useCreateDons } from "../services/useCreateDons";
import { useGetDonsById } from "../services/useGetDonsById";
import { useUpdateDons } from "../services/useUpdateDons";
import { useDeleteDons } from "../services/useDeleteDons";
import { handleResearch } from "../services/useSearchLieu";

export function FormDons() {
  const loginForm = useForm({
    subscribe: "form",
  });
  const deleteForm = useForm();
  const { pageColor } = useApp();
  const { t } = useTranslation();
  const { lang } = useLanguageContext();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const [hoverColor, setHoverColor] = useState({
    isHover: false,
  });
  const [searchParams] = useSearchParams();
  const isUpdating = !!searchParams.get("donId");
  const { dons, isLoading: isLoadingDons } = useGetDonsById({ donId: searchParams.get("donId") });
  const { user, isLoading } = useAuthentificationContext();
  const [selectedChoice, setSectedChoice] = useState();
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    if (!isLoading && !isLoadingDons) {
      setSectedChoice({
        label: dons?.lieuEchange ?? user?.lieuEchange,
        value: dons?.lieuEchange ?? user?.lieuEchange,
        city: dons?.ville ?? user?.ville,
      });
      setChoices([
        {
          label: dons?.lieuEchange ?? user?.lieuEchange,
          value: dons?.lieuEchange ?? user?.lieuEchange,
          city: dons?.ville ?? user?.ville,
        },
      ]);
    }
  }, [isLoading, dons, user, isLoadingDons]);

  const navigate = useNavigate();
  useGlobalEvents({
    form: loginForm,
    type: "keypress",
    key: "Enter",
    action: "submitForm",
  });

  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: "user" });
    navigate("/dashboard/dons");
  };

  const onSuccessUpdate = () => {
    queryClient.invalidateQueries({ queryKey: "user" });
    navigate("/dashboard/dons");
  };

  const { mutate: proposerDon, isLoading: isLoadingCreate } = useCreateDons({ onSuccess });
  const { mutate: updateDon, isLoading: isLoadingUpdate } = useUpdateDons({
    onSuccess: onSuccessUpdate,
  });
  const handleSuccessDelete = async () => {
    onCloseDelete();
    navigate("/dashboard/dons");
  };
  const { mutate: deleteDon, isLoading: iseLoadingDelete } = useDeleteDons({
    onSuccess: handleSuccessDelete,
  });
  const handleSupprimer = (values) => {
    deleteDon({ ...values, dons: { ...dons } });
  };

  const handleProposerDon = (values) => {
    const datas = { ...values, lieu: selectedChoice };
    if (isUpdating) {
      const data = { ...datas, id: searchParams.get("donId") };
      updateDon(data);
    } else {
      proposerDon(datas);
    }
  };

  const { pays, isLoading: isLoadingPays } = useGetPaysCurrentUser();

  if (isLoadingDons || isLoading || isLoadingPays) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const date = new Date();

  return (
    <>
      <Formiz id="dons-form" autoForm onValidSubmit={handleProposerDon} connect={loginForm}>
        <Stack mb={8}>
          <Stack alignItems="center" mb={8}>
            <Text textTransform="uppercase" fontSize="24px" color="energieOrange.500">
              {t("dons:proposer.title")}
            </Text>
          </Stack>
          <Stack alignItems="center" borderTopRadius="10px" backgroundColor={pageColor}>
            <Text fontSize="16px" color="energieBlack.500" fontWeight="bold">
              {`${t("dons:reference")}: ${pays.codeIso}${user.identite}_${FormatDate(
                date,
                "YYYY-MM-DD H:m:s",
                lang
              )}`}
            </Text>
          </Stack>
          <Stack background="rgba(234, 91, 12, 0.4)" position="relative">
            <HStack p={1} alignSelf="center" alignItems="center">
              <Text color="white">{`${t("account:energieDisponible")}`} </Text>
              <IconEnergieSansPlus color="white" />
              <Text color="white">{user?.energiesDisponible ?? 0} </Text>
            </HStack>
          </Stack>
        </Stack>
        <Grid
          rowGap={isMobile ? 8 : 0}
          templateAreas={[
            `"type" 
        "localisation" 
        "temporalite"
        "description" 
        "lieu"
        "contact" 
        "quantite"`,
            `
        "type localisation temporalite"
        "description lieu contact"
        "description quantite contact"`,
          ]}
        >
          <GridItem area="type">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("dons:type.title")}
              </Text>
              <Stack p={4} borderLeft="solid #EA5B0C 4px" spacing={4}>
                <ReactRadioInput
                  defaultValue={dons?.typeDon}
                  color={pageColor}
                  direction="column"
                  name="type_don"
                  required={`${t("dons:type.required")}`}
                  options={[
                    { label: t("dons:type.materiel.label"), value: t("dons:type.materiel.value") },
                    { label: t("dons:type.service.label"), value: t("dons:type.service.value") },
                    {
                      label: t("dons:type.consommation.label"),
                      value: t("dons:type.consommation.value"),
                    },
                  ]}
                />
              </Stack>
              <Divider h="20px" orientation="horizontal" />
            </Stack>
          </GridItem>

          <GridItem area="localisation">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("dons:localisation.title")}
              </Text>
              <Stack p={4} borderLeft="solid #EA5B0C 4px">
                <ReactRadioInput
                  defaultValue={dons?.natDon}
                  color={pageColor}
                  direction="column"
                  name="nat_don"
                  required={`${t("dons:localisation.required")}`}
                  options={[
                    {
                      label: t("dons:localisation.local.label"),
                      value: t("dons:localisation.local.value"),
                    },
                    {
                      label: t("dons:localisation.national.label"),
                      value: t("dons:localisation.national.value"),
                    },
                    {
                      label: t("dons:localisation.international.label"),
                      value: t("dons:localisation.international.value"),
                    },
                  ]}
                />
              </Stack>
              <Divider h="20px" orientation="horizontal" />
            </Stack>
          </GridItem>
          <GridItem area="temporalite">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("dons:temporalite.title")}
              </Text>
              <Stack p={4} borderLeft="solid #EA5B0C 4px">
                <ReactRadioInput
                  defaultValue={dons?.frequenceDon}
                  color={pageColor}
                  direction="column"
                  name="frequence_don"
                  required={`${t("dons:frequence.required")}`}
                  options={[
                    {
                      label: t("dons:frequence.ponctuel.label"),
                      value: t("dons:frequence.ponctuel.value"),
                    },
                    {
                      label: t("dons:frequence.permanent.label"),
                      value: t("dons:frequence.permanent.value"),
                    },
                    {
                      label: t("dons:frequence.mensuel.label"),
                      value: t("dons:frequence.mensuel.value"),
                    },
                  ]}
                />
              </Stack>
              <Divider h="20px" orientation="horizontal" />
            </Stack>
          </GridItem>
          <GridItem py={2} h="100%" area="description">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("dons:description.title")}
              </Text>
              <Stack p={4} h="100%" borderLeft="solid #EA5B0C 4px" spacing={4}>
                <FieldFactory
                  defaultValue={dons?.nom}
                  label={`${t("dons:titre")}`}
                  type="text"
                  name="nom"
                  placeholder={`${t(`dons:searchTitle`)}`}
                  toUpperCase
                  required={`${t("dons:required.nom")}`}
                />
                <FieldFactory
                  maxHeight="200px"
                  name="descriptif"
                  validations={[
                    {
                      rule: isMinLength(100),
                      message: t("app:core.form.label.length"),
                    },
                  ]}
                  label={t(`dons:descriptifTitre.autre`)}
                  type="textarea"
                  placeholder={t(`dons:descriptifPlaceholder`)}
                  required={`${t("dons:required.descriptif")}`}
                  defaultValue={dons?.description}
                />
              </Stack>
              {isMobile ? <Divider h="20px" orientation="horizontal" /> : null}
            </Stack>
          </GridItem>
          <GridItem py={2} area="lieu">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("dons:lieuechange")}
              </Text>
              <Stack p={4} borderLeft="solid #EA5B0C 4px">
                <FieldFactory
                  key={choices?.length}
                  noOptionsMessage="Saisissez une adresse..."
                  type="select"
                  name="lieuEchange"
                  placeholder={`${t("app:core.form.placeholder.lieuEchange")}`}
                  options={choices}
                  onKeyUp={(e) => handleResearch({ e, setChoices })}
                  required={`${t("app:core.form.required.lieuEchange")}`}
                  setParentValue={setSectedChoice}
                  defaultValue={`${dons?.lieuEchange ?? user?.lieuEchange}`}
                  withObject
                />
              </Stack>
              {isMobile ? <Divider h="20px" orientation="horizontal" /> : null}
            </Stack>
          </GridItem>
          <GridItem area="quantite">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("dons:quantite")}
              </Text>
              <HStack h="120px" p={4} borderLeft="solid #EA5B0C 4px">
                <IconEnergieProposerDon />
                <FieldFactory
                  type="number"
                  name="quantite"
                  validations={[
                    {
                      rule: isNumber(),
                      message: t("app:core.form.label.formatEmail"),
                    },
                    {
                      rule: (val) => val >= 1,
                      message: t("app:core.form.label.number"),
                    },
                  ]}
                  textAlign="center"
                  defaultValue={dons?.quantite ?? 1}
                />
              </HStack>
            </Stack>
          </GridItem>
          <GridItem py={2} area="contact">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("dons:contact")}
              </Text>
              <Stack p={4} spacing={2} borderLeft="solid #EA5B0C 4px">
                <FieldFactory
                  type="text"
                  name="mail"
                  label={`${t("app:core.form.label.mail")}`}
                  required={`${t("app:core.form.required.mail")}`}
                  validations={[
                    {
                      rule: isEmail(),
                      message: "Le format n'est pas valide.",
                    },
                  ]}
                  placeholder={`${t("app:core.form.placeholder.mail")}`}
                  defaultValue={user?.mail}
                />
                <FieldFactory
                  country={pays?.codeIso ?? "FR"}
                  label={`${t("app:core.form.label.fixe")}`}
                  type="phone"
                  name="fixe"
                  placeholder={`${t("app:core.form.placeholder.fixe")}`}
                  defaultValue={user?.fixe}
                />
                <FieldFactory
                  country={pays?.codeIso ?? "FR"}
                  label={`${t("app:core.form.label.mobile")}`}
                  type="phone"
                  required={`${t("app:core.form.required.mobile")}`}
                  name="mobile"
                  placeholder={`${t("app:core.form.placeholder.mobile")}`}
                  defaultValue={user?.mobile}
                />
              </Stack>
              {isMobile ? <Divider h="20px" orientation="horizontal" /> : null}
            </Stack>
          </GridItem>
        </Grid>
        <HStack
          borderTop="solid #EA5B0C 4px"
          alignItems="center"
          px={1}
          spacing={4}
          py={4}
          my={8}
          h="100%"
          justifyContent="end"
        >
          <ButtonsSupprimer
            minW="80px"
            onClick={onOpenDelete}
            isDisabled={iseLoadingDelete || !isUpdating}
            isLoading={iseLoadingDelete}
            color={hoverColor.isHoverSupprimer ? "white" : pageColor}
            stroke={hoverColor.isHoverSupprimer ? "white" : pageColor}
            onMouseEnter={() => setHoverColor({ isHoverSupprimer: true })}
            onMouseLeave={() => setHoverColor({ isHoverSupprimer: false })}
          />
          <ButtonsValider
            minW="80px"
            className={hoverColor.isHover ? "logoHover" : ""}
            onMouseEnter={() => setHoverColor({ isHover: true })}
            onMouseLeave={() => setHoverColor({ isHover: false })}
            onClick={loginForm.submit}
            isDisabled={isLoadingUpdate || isLoadingCreate}
            isLoading={isLoadingUpdate || isLoadingCreate}
            color={pageColor}
          />
        </HStack>
      </Formiz>
      <Formiz id="delete-form" autoForm onValidSubmit={handleSupprimer} connect={deleteForm}>
        <ModalCustom
          title={t("dons:delete.modalTitle")}
          textDescription={t("dons:delete.modalDescription")}
          bgColor="energieOrange.500"
          color="white"
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          handleValider={deleteForm.submit}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          isLoading={iseLoadingDelete}
        >
          <Stack justifyContent="center" spacing={4} p={4}>
            <FieldFactory
              type="datetime"
              name="heurenaissanceConfirmDelete"
              placeholder={`${t("app:core.form.placeholder.heure")}`}
              required={`${t("app:core.form.required.heure")}`}
            />
          </Stack>
        </ModalCustom>
      </Formiz>
    </>
  );
}
