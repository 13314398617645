/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconValider({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="51"
      height="46"
      viewBox="0 0 51 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.3762 46C10.4873 46 0 35.6813 0 23.0005C0 10.3197 10.4873 0 23.3762 0C27.8578 0 32.2189 1.25651 35.9863 3.63625C36.4794 3.94793 36.6238 4.59383 36.307 5.08095C35.9883 5.5661 35.3318 5.70822 34.8387 5.39654C31.413 3.23244 27.4494 2.0906 23.3762 2.0906C11.6577 2.0906 2.12475 11.4704 2.12475 23.0005C2.12475 34.5306 11.6577 43.9094 23.3752 43.9094C35.0928 43.9094 44.6257 34.5296 44.6257 23.0005C44.6257 22.3105 44.5919 21.6313 44.5261 20.9599C44.4684 20.3845 44.8957 19.8729 45.4804 19.818C46.0751 19.77 46.5851 20.1817 46.6409 20.757C46.7136 21.495 46.7515 22.2438 46.7515 23.0005C46.7515 35.6823 36.2642 46.001 23.3752 46.001L23.3762 46Z"
        fill={color}
      />
      <path
        d="M26.5637 27.7051C26.2918 27.7051 26.0198 27.6022 25.8117 27.4003L16.2488 17.9911C15.8344 17.5834 15.8344 16.9208 16.2488 16.5131C16.6632 16.1054 17.3366 16.1054 17.7509 16.5131L26.5617 25.1823L49.1869 2.91883C49.6013 2.5111 50.2747 2.5111 50.6891 2.91883C51.1035 3.32656 51.1035 3.98913 50.6891 4.39686L27.3128 27.3973C27.1066 27.6022 26.8347 27.7051 26.5627 27.7051H26.5637Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconValider.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconValider;
