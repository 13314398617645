export default {
  baseStyle:{
    color: "red",
    textTransform: 'uppercase',
    size: "xl",
    _disabled:{
      opacity:1,
      color: "white"
    }
  },
  defaultProps:{
    variant:"rounded",
    fontSize: '16px',
    size: "xl",

  }
}
