import { IconButton } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import IconPause from "components/Icons/icons-generated/IconPause";
import { BsFillPlayFill } from "react-icons/bs";

const propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPause: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
  isDisabled: false,
  isPause: false,
  color: "white",
  className: "",
};

export function ButtonsPause(props) {
  const {
    color,
    isLoading = false,
    isDisabled = false,
    isPause = false,
    onMouseEnter,
    onMouseLeave,
    onClick,
    className,
    ...rest
  } = props;

  return (
    <IconButton
      _hover={{
        backgroundColor: "transparent",
      }}
      color={color}
      isDisabled={isDisabled}
      isLoading={isLoading}
      background="transparent"
      border="none"
      minW="80px"
      minH="46px"
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      fontSize="46px"
      icon={
        isPause ? (
          <BsFillPlayFill width="100%" h="100%" color={color} {...rest} />
        ) : (
          <IconPause color={color} {...rest} />
        )
      }
    />
  );
}
ButtonsPause.propTypes = propTypes;
ButtonsPause.defaultProps = defaultProps;
