import React from "react";
import { SlideFade } from "@chakra-ui/react";
import PropTypes from "prop-types";

export function SlideIn({ children, ...rest }) {
  return (
    <SlideFade
      in
      offsetY={-20}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
      {...rest}
    >
      {children}
    </SlideFade>
  );
}
SlideIn.propTypes = {
  children: PropTypes.node,
};
