import React, { useEffect, useState } from "react";
import { Center, Spinner, Stack, StackDivider, Text, useBreakpointValue } from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { Header } from "components/Layout/Header";
import { useTranslation } from "react-i18next";
import { Footer } from "components/Layout/Footer";
import { useApp } from "context/App/useApp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthentificationContext } from "context/auth-context";
import { ButtonFilters } from "components/Buttons/ButtonFilters";
import { ButtonWithIco } from "components/Buttons/ButtonWithIco";
import IconDoubleMains from "components/Icons/icons-generated/IconDoubleMains";
import { CardDon } from "./_partials/CardDon";
import { GetDonsFilters } from "./funtions/GetDonsFilters";
import { useSearchDons } from "./services/useSearchDons";
import { SearchCardMobile } from "./_partials/SearchCardMobile";
import { SearchCardDesktop } from "./_partials/SearchCardDesktop";

import { CardDonMobile } from "./_partials/CardDonMobile";

export function PageRechercheDons() {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, isLoading } = useAuthentificationContext();
  const navigate = useNavigate();

  // remplacer par params pour filter coter backend
  const {
    dons,
    error,
    isLoading: isLoadingDons,
    isFetching,
  } = useSearchDons({
    params: searchParams,
    isFavorite: false,
  });

  const { t } = useTranslation();
  const { pageColor, setPageColor, setBackgroundColorNotif, setColorNotif, setColorNotifHover } =
    useApp();

  useEffect(() => {
    setPageColor("#36A9E1");
    setBackgroundColorNotif("#36A9E1");
    setColorNotif("#FFFFFF");
    setColorNotifHover("#000000");
  }, [setPageColor, setBackgroundColorNotif, setColorNotifHover, setColorNotif]);
  const { type, localisation, frequence } = GetDonsFilters();
  const [selectedValue, setSelectedValue] = useState({
    type: user?.typeDon?.toUpperCase() ?? null,
    frequence: user?.frequenceDon?.toUpperCase() ?? null,
    localisation: user?.natDon?.toUpperCase() ?? null,
  });

  useEffect(() => {
    setSelectedValue({
      type: user?.typeDon?.toUpperCase() ?? null,
      frequence: user?.frequenceDon?.toUpperCase() ?? null,
      localisation: user?.natDon?.toUpperCase() ?? null,
    });
  }, [user]);

  const handleSearchDon = (values) => {
    setSelectedValue((old) => ({ ...old, search: values.search }));
  };

  const handleClickFilters = (typeFiltre, filtre) => {
    if (typeFiltre === "type") {
      setSelectedValue((old) => ({
        ...old,
        type: old.type === filtre.value ? "" : filtre.value,
      }));
    } else if (typeFiltre === "localisation") {
      setSelectedValue((old) => ({
        ...old,
        localisation: old.localisation === filtre.value ? "" : filtre.value,
      }));
    } else if (typeFiltre === "frequence") {
      setSelectedValue((old) => ({
        ...old,
        frequence: old.frequence === filtre.value ? "" : filtre.value,
      }));
    }
  };

  useEffect(() => {
    if (selectedValue && !searchParams.get("donsId")) {
      if (selectedValue.type) {
        searchParams.set("type", selectedValue.type);
      } else {
        searchParams.delete("type");
      }
      if (selectedValue.localisation) {
        searchParams.set("localisation", selectedValue.localisation);
      } else {
        searchParams.delete("localisation");
      }
      if (selectedValue.frequence) {
        searchParams.set("frequence", selectedValue.frequence);
      } else {
        searchParams.delete("frequence");
      }
      if (selectedValue.search) {
        searchParams.set("search", selectedValue.search);
      }

      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const isErr = (dons?.length === 0 && (error || !error)) || (dons?.length > 0 && error);
  return (
    <SlideIn>
      <Header />
      <Stack mx="auto" minW={isMobile ? "100%" : "500px"}>
        <Stack
          mb={8}
          spacing={2}
          alignItems="center"
          p={4}
          divider={isMobile ? <StackDivider h="1px" borderColor={pageColor} /> : null}
        >
          <Stack alignItems="center">
            <Text textTransform="uppercase" fontSize="24px" color={pageColor}>
              {t("dons:title")}
            </Text>
          </Stack>

          {isMobile ? (
            <SearchCardMobile
              handleClickFilters={handleClickFilters}
              dons={dons}
              handleSearchDon={handleSearchDon}
              isLoadingDons={isLoadingDons}
              isFetching={isFetching}
              selectedValue={selectedValue}
              pageColor={pageColor}
              type={type}
              frequence={frequence}
              localisation={localisation}
            />
          ) : (
            <SearchCardDesktop
              handleClickFilters={handleClickFilters}
              dons={dons}
              handleSearchDon={handleSearchDon}
              isLoadingDons={isLoadingDons}
              isFetching={isFetching}
              selectedValue={selectedValue}
              pageColor={pageColor}
              type={type}
              frequence={frequence}
              localisation={localisation}
            />
          )}

          {searchParams.get("collectif") ? (
            <ButtonFilters
              _hover={{
                borderColor: "white",
                bgColor: "energieCyan",
                color: "black",
              }}
              maxH="40px"
              onClick={() => setSearchParams((old) => old.delete("collectif"))}
              bgColor="energieCyan"
              color="black"
              text="Dons du collectif"
            />
          ) : null}
          {searchParams.get("donRef") ? (
            <ButtonFilters
              _hover={{
                borderColor: "white",
                bgColor: "energieCyan",
                color: "black",
              }}
              maxH="40px"
              onClick={() => setSearchParams((old) => old.delete("donRef"))}
              bgColor="energieCyan"
              color="black"
              text={searchParams.get("donRef")}
            />
          ) : null}

          {isLoadingDons || isLoading ? (
            <Center>
              <Spinner w="30px" h="30px" />
            </Center>
          ) : (
            <Stack pt={12} spacing={8} w="100%">
              {isErr ? (
                <Stack
                  justifyContent="center"
                  width="100%"
                  border={`${pageColor} inset 1px`}
                  minHeight="180px"
                  textAlign="center"
                  alignItems="center"
                  borderRadius="10px"
                  alignContent="center"
                >
                  {error ? (
                    <Text color="#FFF" fontSize="16px">
                      {t(error)}
                    </Text>
                  ) : (
                    <Text color="#FFF" fontSize="16px">
                      {t("dons:notfound")}
                    </Text>
                  )}

                  <ButtonWithIco
                    onClick={() => {
                      navigate(`/account/proposerDon`, {
                        replace: true,
                      });
                    }}
                    textTransform="uppercase"
                    color={pageColor}
                    text={t("app:menu.proposerUnDon")}
                    icon={<IconDoubleMains color={pageColor} />}
                  />
                </Stack>
              ) : null}
              {dons?.map((don) =>
                isMobile ? (
                  <CardDonMobile user={user} key={don?.dons?.id} don={don} />
                ) : (
                  <CardDon user={user} key={don?.dons?.id} don={don} />
                )
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Footer />
    </SlideIn>
  );
}
