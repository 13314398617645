import React from "react";
import { SlideIn } from "components/SlideIn";
import { Header } from "components/Layout/Header";
import { Footer } from "components/Layout/Footer";
import { useAuthentificationContext } from "context/auth-context";
import { FormUsers } from "./_partials/FormUsers";

export function PageAdmin() {
  const { user } = useAuthentificationContext();

  if (user?.statut !== 3) {
    return null;
  }

  return (
    <SlideIn>
      <Header />
      <FormUsers />
      <Footer />
    </SlideIn>
  );
}
