export const Textarea = {
  variants: {
    outline: {
      bg: "gray.50",
    },
    default: {
      color: "#8A8A8A",
      border: "1px solid",
      backgroundColor: "#191919",
      borderColor: "hsl(0, 0%, 80%)",
      borderRadius: "30px",
      padding: "2px 8px",
      fontSize: "16px",
      textAlign: 'left',
      _active:{
        backgroundColor: "#191919",
      },
      _hover: {
        borderColor: "#191919",
      },
      _placeholder: { fontSize: "16px", color: "hsl(0, 0%, 50%)" },
    },
  },

  defaultProps: {
    variant: "default",
  },
};
