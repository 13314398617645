/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconHistoTriangle({ color = "#8CA0A0", ...rest }) {
  return (
    <chakra.svg
      width="24"
      height="55"
      viewBox="0 0 24 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M22.5166 25.2786C23.4146 26.6234 23.4146 28.3766 22.5166 29.7214L7.32649 52.4686C5.12954 55.7586 8.33436e-07 54.2033 1.00636e-06 50.2473L2.99499e-06 4.75275C3.16791e-06 0.796704 5.12955 -0.758549 7.3265 2.53139L22.5166 25.2786Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconHistoTriangle.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconHistoTriangle;
