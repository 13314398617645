export const Input = {
  variants: {
    inputHeightAuto: {
      field: {
        padding: "2px 0px",
        color: "hsl(0, 0%, 20%)",
        border: "1px solid",
        backgroundColor: "hsl(0, 0%, 100%)",
        borderColor: "hsl(0, 0%, 80%)",
        borderRadius: "0.5rem",
        fontSize: "0.8rem",
        height: "auto",
        _hover: {
          borderColor: "defaultTheme.500",
        },
        _placeholder: { fontSize: "16px", color: "hsl(0, 0%, 50%)" },
      },
    },
    implant: {
      field: {
        border: "none",
        pointerEvents: "none",
        height: "100%",
        fontSize: "16px",
        color: "hsl(0, 0%, 20%)",
        borderRight: "1px solid ",
        borderColor: "hsl(0, 0%, 80%)",
      },
    },
    outline: {
      field: {
        bg: "gray.50",
      },
    },
    noBorder: {
      field: {
        color: "hsl(0, 0%, 20%)",
        border: "1px solid",
        backgroundColor: "hsl(0, 0%, 100%)",
        borderColor: "hsl(0, 0%, 80%)",
        borderRadius: 0,
        minHeight: "2.5rem",
        fontSize: "16px",
        _hover: {
          borderColor: "defaultTheme.500",
        },
        _placeholder: { fontSize: "16px", color: "hsl(0, 0%, 50%)" },
      },
      addon: {
        bg: "gray.100",
        _hover: {
          borderColor: "defaultTheme.500",
        },
      },
    },
    
    default: {
      field: {
        color: "#8A8A8A",
        border: "1px solid",
        backgroundColor: "#191919",
        borderColor: "hsl(0, 0%, 80%)",
        borderRadius: "30px",
        minHeight: "2.5rem",
        padding: "2px 8px",
        fontSize: "16px",
        textAlign: 'left',
        _active:{
          backgroundColor: "#191919",
        },
        _hover: {
          borderColor: "#191919",
        },
        _placeholder: { fontSize: "16px", color: "hsl(0, 0%, 50%)" },
      },
      addon: {
        bg: "gray.100",
        _hover: {
          borderColor: "#191919",
        },
      },
    },
  },

  defaultProps: {
    variant: "default",
  },
};
