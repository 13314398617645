import React, { useEffect, useState } from "react";
import {
  Divider,
  HStack,
  Progress,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { useTranslation } from "react-i18next";
import { FieldFactory } from "components/fields/FieldFactory";
import { useApp } from "context/App/useApp";
import { ModalCustom } from "components/Modal/Modal";
import { ButtonWithIco } from "components/Buttons/ButtonWithIco";
import { FaComment, FaPhone } from "react-icons/fa";
import PropTypes from "prop-types";
import { useCreateCommentaire } from "App/messagerie/services/useCreateCommentaire";
import { useGetCommentairesDon } from "App/messagerie/services/useGetCommentairesDon";
import { FormatDateTime } from "functions/common-scope/utils";
import { nl2br } from "functions/common-scope/nl2br";
import { useAuthentificationContext } from "context/auth-context";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

export function ModalCommentaire({ don = undefined }) {
  const colorDon = "#36A9E1";
  const { user } = useAuthentificationContext();
  const queryClient = useQueryClient();
  const [selectedDestinataire, setSelectedDestinataire] = useState();
  const { pageColor = "#EA5B0C" } = useApp();
  const [searchParams] = useSearchParams();
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen:
      searchParams.get("donsId") && don?.dons?.id === parseInt(searchParams.get("donsId"), 10),
  });

  const { messages, isLoading: isLoadingDons } = useGetCommentairesDon({
    id: don?.dons?.id,
    enabled: isOpen,
  });

  const [filteredMessages, setFilteredMessages] = useState(messages);

  useEffect(() => {
    if (!isLoadingDons) {
      setFilteredMessages(messages);
    }
  }, [isLoadingDons, messages]);

  const { t } = useTranslation();
  const commentaireForm = useForm({
    subscribe: "form",
  });

  const handleValider = () => {
    commentaireForm.submit();
  };

  const handleSuccess = async () => {
    await queryClient.invalidateQueries(
      { queryKey: ["messagerie", don?.dons?.id] },
      { exact: false }
    );
    await queryClient.invalidateQueries({ queryKey: ["searchDons"] }, { exact: false });
    onClose();
  };

  const { mutate: creerCommentaire, isLoading: isLoadingCreate } = useCreateCommentaire({
    id: don?.dons?.id,
    handleSuccess,
  });

  const handleSubmit = (values) => {
    const datas = { ...values, selectedDestinataire };
    creerCommentaire(datas);
  };
  const isMyDon = user?.id === don?.usager?.id;

  const handleChange = (val) => {
    if (val === "") {
      setFilteredMessages(messages);
    } else {
      setFilteredMessages(
        messages?.filter(
          (m) =>
            m?.emetteur?.identite?.indexOf(val) >= 0 ||
            m?.messagerie?.dateCreation?.indexOf(val) >= 0
        )
      );
    }
  };

  return (
    <>
      <ButtonWithIco
        onClick={onOpen}
        fontSize="12px"
        lineHeight="14px"
        minH="16px"
        className="bg"
        _active={{
          bg: colorDon,
        }}
        _hover={{
          bg: colorDon,
        }}
        textTransform="uppercase"
        color={colorDon}
        text={t("messagerie:commentaires")}
        icon={
          <HStack spacing={1}>
            <Text>{don?.nbMessages}</Text>
            <FaComment color={colorDon} />
          </HStack>
        }
      />

      <ModalCustom
        title={t("messagerie:listeDesCommentaires")}
        bgColor={pageColor}
        color="white"
        isOpen={isOpen}
        onClose={onClose}
        handleValider={handleValider}
        isLoading={isLoadingCreate}
        withFooter={!isMyDon || !!(isMyDon && selectedDestinataire)}
      >
        <Formiz>
          <FieldFactory
            type="text"
            handleChange={handleChange}
            px={12}
            py={4}
            textAlign="center"
            name="search"
            placeholder={`${t("dons:search")}`}
          />
        </Formiz>
        {isLoadingDons ? (
          <Progress isIndeterminate />
        ) : (
          <Stack maxH="35vh" overflow="auto" divider={<StackDivider h="1px" borderColor="black" />}>
            {filteredMessages?.length === 0 ? (
              <Text textAlign="center">{t("messagerie:pasDeMessages")}</Text>
            ) : (
              filteredMessages?.map((m) => (
                <Stack
                  cursor={isMyDon && m.emetteur.id !== user.id ? "pointer" : null}
                  opacity={isMyDon && m.emetteur.id !== user.id ? null : "0.8"}
                  _hover={
                    isMyDon && m.emetteur.id !== user.id
                      ? {
                          borderColor: "white",
                          bgColor: "energieCyan",
                          color: "black",
                        }
                      : null
                  }
                  onClick={() =>
                    isMyDon && m.emetteur.id !== user.id ? setSelectedDestinataire(m) : null
                  }
                  key={m?.messagerie?.id}
                >
                  <Text color="black" fontSize="md">
                    {nl2br(m?.messagerie?.commentaire)}
                  </Text>
                  <HStack>
                    <Text fontSize="xs">
                      {`Envoyé le ${FormatDateTime(m?.messagerie?.dateCreation)} par ${
                        m?.emetteur?.identite
                      }`}
                    </Text>
                  </HStack>
                  {m.destinataire.id !== user.id ? (
                    <HStack>
                      <Text fontSize="xs" color="black" fontWeight="bold">
                        {`Destinataire ${m?.destinataire?.identite}`}{" "}
                      </Text>{" "}
                      <FaComment />
                    </HStack>
                  ) : null}
                </Stack>
              ))
            )}
          </Stack>
        )}
        {!isMyDon || (isMyDon && selectedDestinataire) ? (
          <Formiz
            id="commentaire-form"
            autoForm
            onValidSubmit={handleSubmit}
            connect={commentaireForm}
          >
            <Stack justifyContent="center" spacing={4} p={4}>
              {selectedDestinataire ? (
                <HStack
                  fontSize="xs"
                  color="white"
                  cursor={selectedDestinataire?.emetteur?.mobile ? "pointer" : ""}
                  fontWeight="bold"
                  onClick={() =>
                    selectedDestinataire?.emetteur?.mobile
                      ? window.open(`tel:+${selectedDestinataire?.emetteur?.mobile}`)
                      : null
                  }
                >
                  <Text textDecoration="underline">{`${t("messagerie:contacter")} ${
                    selectedDestinataire?.emetteur?.identite
                  }`}</Text>
                  {selectedDestinataire?.emetteur?.mobile ? <FaPhone /> : null}
                </HStack>
              ) : null}
              <FieldFactory
                height="150px"
                name="commentaire"
                label={`${t("messagerie:envoyerUnMessage")}`}
                type="textarea"
                placeholder={`${t("messagerie:descriptif.commentaire")}`}
                required={`${t("messagerie:required.commentaire")}`}
              />
            </Stack>
          </Formiz>
        ) : null}
      </ModalCustom>
    </>
  );
}
ModalCommentaire.propTypes = {
  don: PropTypes.instanceOf(Object).isRequired,
};
