/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconLoupe({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      viewBox="0 0 39 30"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="5" width="27" height="27" fill="black" />
      <rect
        x="0.5"
        y="0.5"
        width="37.4783"
        height="29"
        rx="14.5"
        stroke={color}
      />
      <path d="M19 7.5V22" stroke={color} strokeLinecap="round" />
      <path d="M12 14.75L26.5 14.75" stroke={color} strokeLinecap="round" />
    </chakra.svg>
  );
}
IconLoupe.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconLoupe;
