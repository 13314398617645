import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { ButtonsValider } from "components/Buttons/ButtonsValider";

export function ModalCustom({
  isOpen = false,
  onClose = () => {},
  title = "Modal",
  textDescription = null,
  handleValider = null,
  children,
  color = "black",
  bgColor = "white",
  className = "",
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  isLoading = false,
  withFooter = true,
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={{ base: "xl", md: "xl" }}>
      <ModalOverlay />
      <ModalContent maxH="90vh" overflow="auto" m={8} backgroundColor={bgColor}>
        <Stack>
          <ModalCloseButton
            transform="translate(-50%, 0%)"
            fontSize="30px"
            left="50%"
            color="white"
          />
          <ModalHeader textAlign="center" textTransform="uppercase" color={color} fontSize="24px">
            <Stack alignItems="center" w="100%" spacing={8}>
              <Text pt={8}>{title}</Text>
              {textDescription ? (
                <Text
                  fontSize="14px"
                  textTransform="uppercase"
                  color="#000000"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {textDescription}
                </Text>
              ) : null}
            </Stack>
          </ModalHeader>

          <ModalBody color={color}>{children}</ModalBody>
          {withFooter ? (
            <ModalFooter>
              <Stack alignItems="center" w="100%" spacing={8}>
                <ButtonsValider
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  className={className}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={handleValider}
                  color="black"
                />
              </Stack>
            </ModalFooter>
          ) : null}
        </Stack>
      </ModalContent>
    </Modal>
  );
}
ModalCustom.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  textDescription: PropTypes.string,
  handleValider: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseEnter: PropTypes.func,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  withFooter: PropTypes.bool,
};
