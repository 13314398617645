import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import dayjs from "dayjs";
import * as locales from "../locales";

const AVAILABLE_LANGUAGES = [
  "fr", "en", 'es'
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // initImmediate: true,
    // whitelist: ['fr', 'en'],
    // nonExplicitWhitelist: false,
    // defaultNS: 'translation',
    // fallbackNS: 'translation',
    defaultNS: "common",
    ns: Object.keys(locales.fr),
    resources: locales,
    lng: "fr",
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    // Set current language, for locale translations in dayjs
    dayjs.locale(i18n.language);
  });

  i18n.on('languageChanged', (langKey) => {
    const language = AVAILABLE_LANGUAGES.find(({ key }) => key === langKey);
    dayjs.locale(langKey);
    if (typeof window) {
      document.documentElement.lang = langKey;
      document.documentElement.dir = language?.dir ?? 'ltr';
      document.documentElement.style.fontSize = `${
        (language?.fontScale ?? 1) * 100
      }%`;
    }
  });

export const switchLanguage = () => {
  const newLanguage = i18n.language === "en" ? "fr" : "en";
  i18n.changeLanguage(newLanguage);
  dayjs.locale(newLanguage);
};

export const updateLanguage = (newLanguage) => {
  i18n.changeLanguage(newLanguage);
  dayjs.locale(newLanguage);
};
