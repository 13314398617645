/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconSupprimer({ color = "#E71D73", stroke = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="59"
      height="46"
      viewBox="0 0 59 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="58" height="45" rx="22.5" stroke={color} />
      <g clipPath="url(#clip0_273_2457)">
        <path
          d="M36.0757 36H24.0017C22.6073 36 21.4721 34.8641 21.4721 33.4688V16.7625C21.4721 16.2958 21.85 15.9187 22.3153 15.9187C22.7807 15.9187 23.1585 16.2958 23.1585 16.7625V33.4698C23.1585 33.9354 23.5369 34.3136 24.0017 34.3136H36.0752C36.5406 34.3136 36.9184 33.9354 36.9184 33.4698V16.7625C36.9184 16.2958 37.2952 15.9187 37.7616 15.9187C38.228 15.9187 38.6048 16.2958 38.6048 16.7625V33.4698C38.6048 34.8652 37.4697 36 36.0752 36H36.0757ZM33.7454 32.2907C33.279 32.2907 32.9022 31.9136 32.9022 31.4469V16.7625C32.9022 16.2958 33.279 15.9187 33.7454 15.9187C34.2118 15.9187 34.5886 16.2958 34.5886 16.7625V31.4469C34.5886 31.9136 34.2118 32.2907 33.7454 32.2907ZM30.0385 32.2907C29.5731 32.2907 29.1953 31.9136 29.1953 31.4469V16.7625C29.1953 16.2958 29.5731 15.9187 30.0385 15.9187C30.5038 15.9187 30.8817 16.2958 30.8817 16.7625V31.4469C30.8817 31.9136 30.5038 32.2907 30.0385 32.2907ZM26.331 32.2907C25.8657 32.2907 25.4879 31.9136 25.4879 31.4469V16.7625C25.4879 16.2958 25.8657 15.9187 26.331 15.9187C26.7964 15.9187 27.1742 16.2958 27.1742 16.7625V31.4469C27.1742 31.9136 26.7974 32.2907 26.331 32.2907ZM40.1568 14.2819H19.8432C19.3779 14.2819 19 13.9048 19 13.4381C19 12.9714 19.3779 12.5944 19.8432 12.5944H24.076C24.1941 10.8868 24.895 10.3489 25.7867 9.8448C26.6251 9.3702 28.1191 9 29.1874 9H30.8896C31.9588 9 33.4518 9.3702 34.2892 9.84375C35.182 10.3489 35.8829 10.8868 35.9999 12.5944H40.1558C40.6221 12.5944 40.9989 12.9714 40.9989 13.4381C40.9989 13.9048 40.6221 14.2819 40.1558 14.2819H40.1568ZM25.7645 12.5944H34.3129C34.2444 11.7575 33.9809 11.6082 33.4603 11.3129C32.8711 10.9796 31.6695 10.6875 30.8896 10.6875H29.1874C28.4085 10.6875 27.2069 10.9802 26.6167 11.3135C26.0965 11.6077 25.8341 11.7575 25.7651 12.5944H25.7645Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_273_2457">
          <rect
            width="22"
            height="27"
            fill="white"
            transform="translate(19 9)"
          />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
IconSupprimer.propTypes = {
  color: PropTypes.string,
  stroke: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconSupprimer;
