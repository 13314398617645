import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const GetDonsFilters = () => {
  const { t } = useTranslation();
  const getType = useMemo(() =>{
    return [
      {
        label: t("dons:type.materiel.label"),
        value: t("dons:type.materiel.value"),
      },
      {
        label: t("dons:type.service.label"),
        value: t("dons:type.service.value"),
      },
      {
        label: t("dons:type.consommation.label"),
        value: t("dons:type.consommation.value"),
      },
    ];
  }, []);
  const getLocalisation = useMemo(() =>{
    return [
      {
        label: t("dons:localisation.local.label"),
        value: t("dons:localisation.local.value"),
      },
      {
        label: t("dons:localisation.national.label"),
        value: t("dons:localisation.national.value"),
      },
      {
        label: t("dons:localisation.international.label"),
        value: t("dons:localisation.international.value"),
      },
    ];
  }, []);
  const getFrequence = useMemo(() =>{
    return [
      {
        label: t("dons:frequence.ponctuel.label"),
        value: t("dons:frequence.ponctuel.value"),
      },
      {
        label: t("dons:frequence.mensuel.label"),
        value: t("dons:frequence.mensuel.value"),
      },
      {
        label: t("dons:frequence.permanent.label"),
        value: t("dons:frequence.permanent.value"),
      },
    ];
  }, []);

  return {
    type: [...getType],
    frequence: [...getFrequence],
    localisation: [...getLocalisation],
  }

};
