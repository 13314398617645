import React, { useEffect } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuthentificationContext } from "context/auth-context";
import { useQueryClient } from "react-query";

export function PageLogout() {
  const { updateToken } = useAuthentificationContext();
  const navigate = useNavigate();
  const queryCache = useQueryClient();

  useEffect(() => {
    updateToken(null);
    queryCache.clear();
    navigate("/");
  }, [updateToken, queryCache, navigate]);

  return (
    <Center flex="1">
      <Spinner />
    </Center>
  );
}
