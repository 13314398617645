import React from "react";
import { HStack, Stack, StackDivider, Wrap, WrapItem } from "@chakra-ui/react";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { Formiz, useForm } from "@formiz/core";
import { useTranslation } from "react-i18next";
import { ButtonFilters } from "components/Buttons/ButtonFilters";
import { FieldFactory } from "components/fields/FieldFactory";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

export function SearchCardMobile({
  handleClickFilters,
  handleSearchDon,
  isLoadingDons,
  isFetching,
  selectedValue,
  pageColor,
  type,
  frequence,
  localisation,
}) {
  const { t } = useTranslation();
  const form = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDonsSearch = searchParams.get("donsId");

  return (
    <Stack p={2} divider={<StackDivider h="1px" borderColor={pageColor} />} spacing={4}>
      <Stack justify="center">
        {isDonsSearch ? (
          <Wrap>
            <WrapItem key={isDonsSearch}>
              <ButtonFilters
                _hover={{
                  borderColor: "white",
                  bgColor: "energieCyan",
                  color: "black",
                }}
                maxH="40px"
                onClick={() => {
                  searchParams.delete("donsId");
                  setSearchParams(searchParams);
                }}
                bgColor="energieCyan"
                color="black"
                text={t("messagerie:supprimerFiltre")}
              />
            </WrapItem>
          </Wrap>
        ) : null}
        <Wrap>
          {localisation.map((filtre) => (
            <WrapItem key={filtre.value}>
              <ButtonFilters
                maxH="40px"
                _hover={{
                  borderColor: "white",
                  bgColor:
                    selectedValue.localisation === filtre.value && !isDonsSearch
                      ? "energieCyan"
                      : "black",
                  color:
                    selectedValue.localisation === filtre.value && !isDonsSearch
                      ? "black"
                      : "energieCyan",
                }}
                onClick={() => handleClickFilters("localisation", filtre)}
                bgColor={
                  selectedValue.localisation === filtre.value && !isDonsSearch
                    ? "energieCyan"
                    : "black"
                }
                color={
                  selectedValue.localisation === filtre.value && !isDonsSearch
                    ? "black"
                    : "energieCyan"
                }
                text={filtre.label}
              />
            </WrapItem>
          ))}
        </Wrap>
        <Wrap>
          {type.map((filtre) => (
            <WrapItem key={filtre.value}>
              <ButtonFilters
                _hover={{
                  borderColor: "white",
                  bgColor:
                    selectedValue.type === filtre.value && !isDonsSearch ? "energieCyan" : "black",
                  color:
                    selectedValue.type === filtre.value && !isDonsSearch ? "black" : "energieCyan",
                }}
                maxH="40px"
                onClick={() => handleClickFilters("type", filtre)}
                bgColor={
                  selectedValue.type === filtre.value && !isDonsSearch ? "energieCyan" : "black"
                }
                color={
                  selectedValue.type === filtre.value && !isDonsSearch ? "black" : "energieCyan"
                }
                text={filtre.label}
              />
            </WrapItem>
          ))}
        </Wrap>
        <Wrap>
          {frequence.map((filtre) => (
            <WrapItem key={filtre.value}>
              <ButtonFilters
                maxH="40px"
                _hover={{
                  borderColor: "white",
                  bgColor:
                    selectedValue.frequence === filtre.value && !isDonsSearch
                      ? "energieCyan"
                      : "black",
                  color:
                    selectedValue.frequence === filtre.value && !isDonsSearch
                      ? "black"
                      : "energieCyan",
                }}
                onClick={() => handleClickFilters("frequence", filtre)}
                bgColor={
                  selectedValue.frequence === filtre.value && !isDonsSearch
                    ? "energieCyan"
                    : "black"
                }
                color={
                  selectedValue.frequence === filtre.value && !isDonsSearch
                    ? "black"
                    : "energieCyan"
                }
                text={filtre.label}
              />
            </WrapItem>
          ))}
        </Wrap>
      </Stack>
      <Stack divider={<StackDivider border={`1px solid ${pageColor}`} />} spacing={4}>
        <Formiz id="dons-form" autoForm onValidSubmit={handleSearchDon} connect={form}>
          <HStack>
            <FieldFactory
              type="text"
              textAlign="center"
              name="search"
              placeholder={`${t("dons:search")}`}
            />
            <ButtonsValider color={pageColor} onClick={form.submit} isDisabled={isLoadingDons} />
          </HStack>
        </Formiz>
      </Stack>
    </Stack>
  );
}
SearchCardMobile.propTypes = {
  type: PropTypes.instanceOf(Object).isRequired,
  localisation: PropTypes.instanceOf(Object).isRequired,
  frequence: PropTypes.instanceOf(Object).isRequired,
  handleClickFilters: PropTypes.func,
  handleSearchDon: PropTypes.func,
  isFetching: PropTypes.bool,
  isLoadingDons: PropTypes.bool,
  selectedValue: PropTypes.instanceOf(Object).isRequired,
  pageColor: PropTypes.string,
};
