import React from "react";
import { Tr, Td, Spinner } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Formiz } from "@formiz/core";
import { FieldFactory } from "components/fields/FieldFactory";
import { useUpdateUserStatut } from "App/utilisateur/services/useUpdateUserStatut";
import debounce from "lodash.debounce";

const Row = React.memo(({ user }) => {
  const { t } = useTranslation();
  const listeOptions = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
  ];
  const { mutate: updateUser, isLoading: isLoadingUpdate } = useUpdateUserStatut();
  const handleChange = (newStatut) => {
    const dataToUpdate = {
      ...user,
      newStatut,
    };
    updateUser(dataToUpdate);
  };

  const handleChangeIdentite = debounce((value) => {
    const dataToUpdate = {
      ...user,
      newIdentite: value,
    };
    updateUser(dataToUpdate);
  }, 8000);

  const handleChangeLien = debounce((value) => {
    const dataToUpdate = {
      ...user,
      newLien: value,
    };
    updateUser(dataToUpdate);
  }, 8000);

  const handleChangeMail = debounce((value) => {
    const dataToUpdate = {
      ...user,
      newMail: value,
    };
    updateUser(dataToUpdate);
  }, 8000);

  const tradCollectif = (profil) => {
    switch (profil) {
      case "ENSEIGNE":
        return "COLLECTIF";
      case "SOCIETE":
        return "ENTREPRISE";
      default:
        return profil;
    }
  };

  return (
    <Formiz>
      <Tr bgColor="#36A9E1">
        <Td>
          {!isLoadingUpdate ? (
            <FieldFactory
              type="text"
              name="identite"
              defaultValue={user?.identite}
              required={`${t("app:core.form.required.identite")}`}
              handleKeyUp={handleChangeIdentite}
            />
          ) : (
            <Spinner />
          )}
        </Td>
        <Td>
          <FieldFactory
            type="text"
            name="mail"
            defaultValue={user?.mail}
            handleKeyUp={handleChangeMail}
          />
        </Td>
        <Td>{tradCollectif(user?.profil)}</Td>
        <Td>
          <FieldFactory
            type="text"
            name="lienCollectif"
            defaultValue={user?.lienCollectif}
            handleKeyUp={handleChangeLien}
          />
        </Td>
        <Td>
          {!isLoadingUpdate ? (
            <FieldFactory
              type="select"
              name="localisation"
              options={listeOptions}
              defaultValue={user?.statut}
              required={`${t("app:core.form.required.localisation")}`}
              setParentValue={handleChange}
            />
          ) : (
            <Spinner />
          )}
        </Td>
      </Tr>
    </Formiz>
  );
});
Row.propTypes = {
  user: PropTypes.instanceOf(Object),
};

export { Row };
