/* eslint-disable max-len */
import React from "react";
import { chakra } from "@chakra-ui/react";
import PropTypes from "prop-types";

export function LogoBis({ color = "#FFFFFF", ...rest }) {
  return (
    <chakra.svg width="76" height="52" viewBox="0 0 76 52" {...rest}>
      <path
        d="M5.23668 39.1045C4.4589 38.3164 3.50894 37.9163 2.41648 37.9163H0.911382C0.82826 37.9163 0.748107 37.9344 0.673892 37.9675C0.599676 38.0005 0.537334 38.0427 0.48093 38.0968C0.424526 38.1509 0.379997 38.2171 0.347342 38.2923C0.314687 38.3675 0.296875 38.4487 0.296875 38.533C0.296875 38.7225 0.353279 38.8729 0.466087 38.9812C0.659048 39.1647 0.9381 39.1496 0.944037 39.1496H2.41054C3.16754 39.1496 3.82955 39.4294 4.36984 39.9768C4.9131 40.5273 5.18621 41.1951 5.18621 41.9621C5.18621 42.3562 5.11794 42.7202 4.98435 43.045C4.85076 43.3699 4.64296 43.6767 4.36984 43.9535C3.82955 44.5009 3.17051 44.7777 2.41054 44.7777H0.911382C0.700609 44.7777 0.299844 44.91 0.299844 45.3943C0.299844 45.4785 0.317655 45.5598 0.35031 45.635C0.382965 45.7102 0.427495 45.7763 0.483899 45.8305C0.537334 45.8846 0.602644 45.9267 0.67686 45.9598C0.751076 45.9929 0.831229 46.011 0.914351 46.011H2.41648C3.51191 46.011 4.46187 45.6139 5.23668 44.8258C6.0115 44.0377 6.40929 43.0751 6.40929 41.9621C6.40929 41.3996 6.31133 40.8762 6.1154 40.407C5.92244 39.9377 5.62557 39.5016 5.23371 39.1045H5.23668Z"
        fill={color}
      />
      <path
        d="M8.82872 37.9163C8.66248 37.9163 8.51702 37.9765 8.39827 38.0968C8.27953 38.2171 8.22015 38.3615 8.22015 38.533V45.3943C8.22015 45.5628 8.27953 45.7102 8.39827 45.8305C8.51702 45.9508 8.65951 46.011 8.82872 46.011C8.99794 46.011 9.14043 45.9508 9.25918 45.8305C9.37792 45.7102 9.43729 45.5658 9.43729 45.3943V38.533C9.43729 38.3645 9.37792 38.2171 9.25918 38.0968C9.14043 37.9765 8.99794 37.9163 8.82872 37.9163Z"
        fill={color}
      />
      <path
        d="M17.601 38.0938C17.5446 38.0367 17.4793 37.9945 17.4051 37.9615C17.3309 37.9314 17.2507 37.9163 17.1676 37.9163C16.8915 37.9163 16.6956 38.0457 16.6036 38.2863L14.5315 43.6858L12.4564 38.2803C12.3525 38.0397 12.1566 37.9133 11.8953 37.9133C11.8092 37.9133 11.7291 37.9284 11.6549 37.9615C11.5836 37.9915 11.5183 38.0367 11.4619 38.0938C11.4055 38.151 11.3639 38.2171 11.3313 38.2923C11.3016 38.3675 11.2867 38.4488 11.2867 38.533C11.2867 38.6172 11.3075 38.7225 11.3521 38.8338L13.9734 45.6229C14.0743 45.8786 14.2673 46.011 14.5315 46.011C14.7957 46.011 14.9886 45.8756 15.0896 45.6199L17.7079 38.8338C17.7524 38.7315 17.7732 38.6292 17.7732 38.53C17.7732 38.4458 17.7584 38.3645 17.7287 38.2893C17.699 38.2141 17.6545 38.148 17.5981 38.0908L17.601 38.0938Z"
        fill={color}
      />
      <path
        d="M20.0234 37.9163C19.8572 37.9163 19.7117 37.9765 19.593 38.0968C19.4742 38.2171 19.4149 38.3645 19.4149 38.533V45.3943C19.4149 45.5628 19.4742 45.7102 19.593 45.8305C19.7117 45.9508 19.8542 46.011 20.0234 46.011C20.1926 46.011 20.3351 45.9508 20.4539 45.8305C20.5726 45.7102 20.632 45.5658 20.632 45.3943V38.533C20.632 38.3645 20.5726 38.2171 20.4539 38.0968C20.3351 37.9765 20.1926 37.9163 20.0234 37.9163Z"
        fill={color}
      />
      <path
        d="M28.1812 37.9163C28.015 37.9163 27.8695 37.9765 27.7508 38.0968C27.632 38.2171 27.5727 38.3645 27.5727 38.533V43.6045L23.3839 38.1449V38.1389C23.2474 37.9915 23.09 37.9163 22.9119 37.9163C22.8258 37.9163 22.7457 37.9314 22.6715 37.9644C22.6002 37.9945 22.5349 38.0397 22.4785 38.0968C22.4221 38.154 22.3805 38.2201 22.3479 38.2953C22.3182 38.3705 22.3033 38.4518 22.3033 38.536V45.3973C22.3033 45.5658 22.3627 45.7132 22.4815 45.8335C22.6002 45.9538 22.7427 46.014 22.9119 46.014C23.0811 46.014 23.2236 45.9538 23.3424 45.8335C23.4611 45.7132 23.5205 45.5688 23.5205 45.3973V40.3348L27.7152 45.7944L27.7211 45.8004C27.8488 45.9418 28.0031 46.014 28.1842 46.014C28.2673 46.014 28.3475 45.9989 28.4217 45.9688C28.4959 45.9388 28.5612 45.8936 28.6176 45.8365C28.674 45.7793 28.7156 45.7162 28.7482 45.641C28.7779 45.5658 28.7957 45.4846 28.7957 45.3973V38.536C28.7957 38.3675 28.7364 38.2201 28.6176 38.0998C28.4989 37.9795 28.3534 37.9193 28.1872 37.9193L28.1812 37.9163Z"
        fill={color}
      />
      <path
        d="M33.1359 41.3485C32.9697 41.3485 32.8242 41.4087 32.7055 41.529C32.5867 41.6493 32.5273 41.7937 32.5273 41.9652C32.5273 42.1366 32.5867 42.281 32.7055 42.4013C32.8242 42.5216 32.9667 42.5818 33.1359 42.5818H34.6291C34.7954 42.5818 34.9408 42.5216 35.0596 42.4013C35.1783 42.281 35.2377 42.1366 35.2377 41.9652C35.2377 41.7937 35.1783 41.6493 35.0596 41.529C34.9408 41.4087 34.7954 41.3485 34.6291 41.3485H33.1359Z"
        fill={color}
      />
      <path
        d="M36.1847 38.9691C36.3035 38.8488 36.3628 38.7044 36.3628 38.533C36.3628 38.3615 36.3035 38.2171 36.1847 38.0968C36.066 37.9765 35.9205 37.9163 35.7543 37.9163H31.2419C31.1558 37.9163 31.0757 37.9314 31.0015 37.9644C30.9302 37.9945 30.8649 38.0397 30.8085 38.0968C30.7521 38.154 30.7105 38.2201 30.6779 38.2953C30.6482 38.3705 30.6334 38.4518 30.6334 38.536V45.3973C30.6334 45.4845 30.6482 45.5688 30.6809 45.641C30.7105 45.7132 30.7551 45.7793 30.8115 45.8365C30.8679 45.8936 30.9302 45.9358 31.0044 45.9688C31.0757 45.9989 31.1588 46.017 31.2449 46.017H35.7572C35.9235 46.017 36.0689 45.9568 36.1877 45.8365C36.3064 45.7162 36.3658 45.5718 36.3658 45.4003C36.3658 45.2289 36.3064 45.0845 36.1877 44.9642C36.0689 44.8438 35.9235 44.7837 35.7572 44.7837H31.8505V39.1526H35.7572C35.9235 39.1526 36.0689 39.0925 36.1877 38.9721L36.1847 38.9691Z"
        fill={color}
      />
      <path
        d="M48.837 41.3485C48.6708 41.3485 48.5253 41.4087 48.4066 41.529C48.2878 41.6493 48.2285 41.7937 48.2285 41.9652C48.2285 42.1366 48.2878 42.281 48.4066 42.4013C48.5253 42.5216 48.6678 42.5818 48.837 42.5818H50.3302C50.4965 42.5818 50.642 42.5216 50.7607 42.4013C50.8794 42.281 50.9388 42.1366 50.9388 41.9652C50.9388 41.7937 50.8794 41.6493 50.7607 41.529C50.642 41.4087 50.4995 41.3485 50.3302 41.3485H48.837Z"
        fill={color}
      />
      <path
        d="M51.8858 38.9691C52.0046 38.8488 52.0639 38.7044 52.0639 38.533C52.0639 38.3615 52.0046 38.2171 51.8858 38.0968C51.7671 37.9765 51.6216 37.9163 51.4554 37.9163H46.943C46.8569 37.9163 46.7768 37.9314 46.7026 37.9644C46.6313 37.9945 46.566 38.0397 46.5096 38.0968C46.4532 38.154 46.4117 38.2201 46.379 38.2953C46.3493 38.3705 46.3345 38.4518 46.3345 38.536V45.3973C46.3345 45.4845 46.3493 45.5688 46.382 45.641C46.4117 45.7132 46.4562 45.7793 46.5126 45.8365C46.569 45.8936 46.6313 45.9358 46.7056 45.9688C46.7768 45.9989 46.8599 46.017 46.946 46.017H51.4583C51.6246 46.017 51.77 45.9568 51.8888 45.8365C52.0075 45.7162 52.0669 45.5718 52.0669 45.4003C52.0669 45.2289 52.0075 45.0845 51.8888 44.9642C51.77 44.8438 51.6246 44.7837 51.4583 44.7837H47.5516V39.1526H51.4583C51.6246 39.1526 51.77 39.0925 51.8888 38.9721L51.8858 38.9691Z"
        fill={color}
      />
      <path
        d="M43.9447 37.9163C43.7785 37.9163 43.633 37.9765 43.5143 38.0968C43.3955 38.2171 43.3362 38.3645 43.3362 38.533V43.6045L39.1474 38.1449V38.1389C39.0109 37.9915 38.8535 37.9163 38.6754 37.9163C38.5893 37.9163 38.5092 37.9314 38.4349 37.9644C38.3637 37.9945 38.2984 38.0397 38.242 38.0968C38.1856 38.154 38.144 38.2201 38.1114 38.2953C38.0817 38.3705 38.0668 38.4518 38.0668 38.536V45.3973C38.0668 45.5658 38.1262 45.7132 38.245 45.8335C38.3637 45.9538 38.5062 46.014 38.6754 46.014C38.8446 46.014 38.9871 45.9538 39.1059 45.8335C39.2246 45.7132 39.284 45.5688 39.284 45.3973V40.3348L43.4787 45.7944L43.4846 45.8004C43.6122 45.9418 43.7666 46.014 43.9477 46.014C44.0308 46.014 44.111 45.9989 44.1852 45.9688C44.2594 45.9388 44.3247 45.8936 44.3811 45.8365C44.4375 45.7793 44.4791 45.7162 44.5117 45.641C44.5414 45.5658 44.5592 45.4846 44.5592 45.3973V38.536C44.5592 38.3675 44.4999 38.2201 44.3811 38.0998C44.2624 37.9795 44.1169 37.9193 43.9507 37.9193L43.9447 37.9163Z"
        fill={color}
      />
      <path
        d="M64.0839 39.1496C64.4075 39.1496 64.7162 39.2007 65.0042 39.303C65.2892 39.4053 65.5563 39.5527 65.7909 39.7422C65.9126 39.8565 66.0521 39.9136 66.2065 39.9136C66.2896 39.9136 66.3697 39.8986 66.444 39.8685C66.5182 39.8384 66.5835 39.7933 66.6399 39.7362C66.6963 39.679 66.7379 39.6158 66.7705 39.5406C66.8002 39.4654 66.818 39.3842 66.818 39.297C66.818 39.1165 66.7616 38.9721 66.6488 38.8638C65.8918 38.2352 65.0279 37.9193 64.0898 37.9193C62.9944 37.9193 62.0415 38.3194 61.2637 39.1075C60.4889 39.8926 60.0941 40.8552 60.0941 41.9651C60.0941 43.0751 60.4859 44.0437 61.2637 44.8288C62.0415 45.6139 62.9885 46.014 64.0898 46.014C65.0161 46.014 65.8621 45.7101 66.6013 45.1145L66.6102 45.1055C66.7349 44.9762 66.7972 44.8288 66.7972 44.6663V42.3471C66.7972 42.2659 66.7794 42.1847 66.7468 42.1095C66.7141 42.0343 66.6696 41.9681 66.6161 41.914C66.5627 41.8598 66.4974 41.8147 66.4262 41.7816C66.3519 41.7485 66.2748 41.7305 66.1946 41.7305C65.7107 41.7305 65.5831 42.1366 65.5831 42.3532V44.3535C65.1437 44.6363 64.645 44.7807 64.0928 44.7807C63.3239 44.7807 62.6619 44.5039 62.1216 43.9565C61.5843 43.412 61.3142 42.7442 61.3142 41.9651C61.3142 41.186 61.5873 40.5213 62.1216 39.9798C62.656 39.4384 63.3269 39.1526 64.0928 39.1526L64.0839 39.1496Z"
        fill={color}
      />
      <path
        d="M75.7863 39.3903C75.7061 39.2489 75.5725 39.1466 75.4152 39.1015C75.2579 39.0594 75.0946 39.0804 74.9521 39.1647L72.0221 40.8792L69.092 39.1647C68.9495 39.0835 68.7862 39.0594 68.6289 39.1015C68.4716 39.1436 68.3409 39.2459 68.2578 39.3903C68.1777 39.5316 68.1539 39.7001 68.1955 39.8595C68.237 40.019 68.338 40.1513 68.4805 40.2355L71.4105 41.9501V45.3793C71.4105 45.7192 71.6836 45.9959 72.0191 45.9959C72.1824 45.9959 72.3338 45.9328 72.4495 45.8154C72.5653 45.6981 72.6277 45.5447 72.6277 45.3793V41.9501L75.5577 40.2355C75.6972 40.1543 75.7982 40.019 75.8427 39.8595C75.8842 39.7001 75.8635 39.5347 75.7803 39.3903H75.7863Z"
        fill={color}
      />
      <path
        d="M71.7816 39.3211C71.8617 39.3421 71.9419 39.3542 72.025 39.3542C72.1883 39.3542 72.3486 39.309 72.4881 39.2278C72.7048 39.1015 72.8562 38.8999 72.9216 38.6563C72.9869 38.4126 72.9542 38.16 72.8295 37.9404C72.7048 37.7238 72.5059 37.5674 72.2655 37.5012C72.025 37.435 71.7757 37.4681 71.5589 37.5945C71.3422 37.7208 71.1908 37.9223 71.1255 38.166C71.0602 38.4096 71.0929 38.6623 71.2176 38.8819C71.3422 39.1015 71.5411 39.2549 71.7816 39.3211Z"
        fill={color}
      />
      <path
        d="M58.9571 45.0574L57.449 42.6089C57.6301 42.4976 57.7963 42.3712 57.9359 42.2298C58.0991 42.0704 58.2357 41.8929 58.3455 41.7034C58.4554 41.5139 58.5385 41.3124 58.5919 41.0988C58.6483 40.8853 58.6751 40.6657 58.6751 40.4431C58.6751 40.1002 58.6098 39.7723 58.4791 39.4685C58.3485 39.1647 58.1704 38.8969 57.9478 38.6683C57.7251 38.4397 57.4609 38.2592 57.1611 38.1269C56.8612 37.9945 56.5376 37.9283 56.1963 37.9283H54.3141C54.2281 37.9283 54.1479 37.9434 54.0767 37.9735C54.0054 38.0035 53.9401 38.0487 53.8867 38.1028C53.8303 38.16 53.7887 38.2261 53.759 38.2983C53.7293 38.3735 53.7145 38.4517 53.7145 38.536C53.7145 38.7165 53.7709 38.8638 53.8807 38.9691C54.0796 39.1586 54.3795 39.1466 54.3884 39.1466H56.1992C56.3714 39.1466 56.5377 39.1797 56.692 39.2489C56.8464 39.3181 56.9829 39.4113 57.0987 39.5256C57.2145 39.6429 57.3065 39.7813 57.3718 39.9377C57.4371 40.0941 57.4728 40.2626 57.4728 40.4401C57.4728 40.6175 57.4401 40.78 57.3718 40.9364C57.3036 41.0928 57.2145 41.2312 57.0987 41.3485C56.9859 41.4658 56.8494 41.5621 56.695 41.6282C56.5406 41.6944 56.3744 41.7305 56.1992 41.7305H54.3171C54.231 41.7305 54.1509 41.7456 54.0796 41.7756C54.0084 41.8057 53.9431 41.8508 53.8896 41.905C53.8332 41.9621 53.7917 42.0283 53.762 42.1005C53.7323 42.1757 53.7174 42.2539 53.7174 42.3381C53.7174 42.5186 53.7739 42.666 53.8837 42.7713C54.0826 42.9608 54.3824 42.9488 54.3913 42.9488H56.2438L57.9388 45.6981C58.0695 45.8936 58.2416 45.9929 58.4524 45.9929C58.5355 45.9929 58.6157 45.9779 58.6869 45.9478C58.7612 45.9177 58.8265 45.8726 58.8799 45.8184C58.9333 45.7643 58.9779 45.6981 59.0076 45.6259C59.0372 45.5537 59.0521 45.4725 59.0521 45.3853C59.0521 45.271 59.0194 45.1597 58.9571 45.0544V45.0574Z"
        fill={color}
      />
      <path
        d="M45.5724 10.1864L38.1083 14.5463L30.6769 10.2406C30.0576 9.87976 29.2627 10.0986 28.9003 10.7252C28.5379 11.3517 28.7495 12.1512 29.3688 12.512L36.8018 16.8204L36.7732 25.4871C36.7724 26.2101 37.3495 26.7927 38.0685 26.791C38.786 26.7868 39.3682 26.2012 39.3716 25.4767L39.4001 16.81L46.8642 12.4501C47.4869 12.0864 47.7007 11.2839 47.3455 10.6616C46.9904 10.0393 46.1966 9.82533 45.5739 10.189L45.5724 10.1864Z"
        fill={color}
      />
      <path
        d="M36.0935 8.77285C36.2347 9.31605 36.5804 9.77091 37.0629 10.0491C37.5469 10.3298 38.1086 10.4027 38.6497 10.2525C39.1898 10.1064 39.6436 9.75494 39.923 9.26679C40.3119 8.59025 40.2868 7.78569 39.9273 7.15569C39.7512 6.84717 39.4947 6.57874 39.1666 6.39013C38.6827 6.10938 38.121 6.03649 37.5783 6.18408C37.0357 6.33166 36.5844 6.68166 36.305 7.16981C36.023 7.65947 35.9468 8.22598 36.092 8.77026L36.0935 8.77285Z"
        fill={color}
      />
      <path
        d="M33.4002 19.426C33.3513 19.2437 33.2817 19.0735 33.1915 18.9153C33.011 18.599 32.7508 18.3362 32.4308 18.1497C31.9468 17.869 31.3851 17.7961 30.844 17.9463C30.3039 18.0924 29.8501 18.4439 29.5707 18.932C28.9897 19.942 29.3303 21.2329 30.3271 21.8087C30.8111 22.0894 31.3728 22.1623 31.9154 22.0147C32.4555 21.8687 32.9093 21.5172 33.1887 21.029C33.4707 20.5393 33.547 19.9728 33.4017 19.4286L33.4002 19.426Z"
        fill={color}
      />
      <path
        d="M42.8026 20.5134C42.9438 21.0566 43.2895 21.5114 43.772 21.7896C44.256 22.0703 44.8177 22.1432 45.3603 21.9956C45.903 21.848 46.3542 21.498 46.6336 21.0099C47.0225 20.3334 46.9974 19.5288 46.6378 18.8988C46.4618 18.5903 46.2053 18.3219 45.8772 18.1332C44.8789 17.5549 43.598 17.9056 43.0171 18.9155C42.7351 19.4052 42.6588 19.9717 42.8041 20.516L42.8026 20.5134Z"
        fill={color}
      />
      <path
        d="M24.3283 24.1151C25.7072 26.5314 27.7078 28.5465 30.1192 29.9413C31.9709 31.0133 33.9817 31.6881 36.0918 31.9454C38.1281 32.194 40.1739 32.043 42.1669 31.4976C44.1599 30.9522 46.003 30.0415 47.6468 28.7875C49.3481 27.4895 50.7586 25.881 51.8379 24.0061C53.2659 21.5231 54.0135 18.7047 53.9938 15.8575C53.9789 13.0973 53.2485 10.3748 51.8874 7.98967C50.5262 5.60451 48.5078 3.55835 46.0964 2.16346C44.2447 1.0915 42.2339 0.416683 40.1238 0.15937C38.0876 -0.0892316 36.0417 0.0618355 34.0487 0.607233C32.0557 1.15263 30.2126 2.06331 28.5688 3.31729C26.8675 4.61526 25.4571 6.2238 24.3778 8.09869C22.9497 10.5817 22.2047 13.3986 22.2219 16.2473C22.2367 19.0075 22.9657 21.7274 24.3283 24.1151ZM49.3604 22.5738C45.7434 28.8623 37.7725 31.0398 31.5594 27.4374C29.5119 26.2517 27.9111 24.5872 26.8134 22.6635C24.5764 18.7436 24.4268 13.7495 26.8527 9.53251C30.4696 3.24403 38.4406 1.06653 44.6536 4.66892C46.7011 5.85465 48.3019 7.51908 49.3997 9.44276C51.6367 13.3627 51.7863 18.3568 49.3604 22.5738Z"
        fill={color}
      />
      <defs>
        <clipPath id="clip0_521_160">
          <rect width="76" height="52" fill={color} />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
LogoBis.propTypes = {
  color: PropTypes.string,
};
