import React from "react";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";

export function Main({ children }) {
  return (
    <Box pt={{ base: 16 }} w="100%" bg="energieBlack.500" position="relative">
      {children}
    </Box>
  );
}
Main.propTypes = {
  children: PropTypes.node,
};
