import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  StackDivider,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { Logo } from "components/Logo";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { LanguagePicker } from "components/Language/LanguagePicker";
import IconLoupe from "components/Icons/icons-generated/IconLoupe";
import { Main } from "components/Layout/Main";
import { Footer } from "components/Layout/Footer";
import { FormLogin } from "./_partials/FormLogin";

export function PageLogin() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();
  const [hoverColor, setHoverColor] = useState("#E71D73");
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/dashboard");
  };
  return (
    <SlideIn>
      <Main>
        {isMobile ? (
          <Stack>
            <LanguagePicker />
            <Logo text={t("app:logoText")} />
          </Stack>
        ) : (
          <Stack mx="auto" maxW="80%" position="relative">
            <LanguagePicker />
            <Logo text={t("app:logoText")} />
          </Stack>
        )}

        <Box my={8}>
          {isMobile ? (
            <Box textAlign="center">
              <Text
                mx={4}
                fontWeight={500}
                fontSize="20px"
                lineHeight="24px"
                color="energieRose"
                textTransform="uppercase"
              >
                {t("auth:login.actions.login")}
              </Text>
              <FormLogin onSuccess={handleLogin} />
            </Box>
          ) : (
            <HStack
              alignSelf="center"
              alignItems="start"
              m="auto"
              w="80%"
              spacing={8}
              divider={<StackDivider border="1px solid rgba(255, 255, 255, 0.5);" />}
            >
              <Stack flex={1}>
                <HStack spacing={0} p={4} justifyContent="end">
                  <Image
                    pt={14}
                    alignSelf="start"
                    src="/right.png"
                    mb={4}
                    maxW="60px"
                    maxH="110px"
                  />
                  <Stack py={20} textAlign="center" gap={8}>
                    <Text maxW="350px" color="#B0B0B0" fontSize={32} lineHeight="38px">
                      {`${t("app:core.citation")}`}
                    </Text>
                    <Text pr={4} color="#B0B0B0" textAlign="right" fontSize={16}>{`${t(
                      "app:core.citationAuthor"
                    )}`}</Text>
                    <Button
                      alignSelf="end"
                      _hover={{ cursor: "pointer" }}
                      onMouseEnter={() => setHoverColor("#FFFFFF")}
                      onMouseLeave={() => setHoverColor("#E71D73")}
                      color={hoverColor}
                      as={Link}
                      to="/divinenergie"
                      fontSize="14px"
                      lineHeight="17px"
                      textTransform="uppercase"
                      variant="link"
                      whiteSpace="initial"
                    >
                      {`${t("app:core.decouvrir")}`}
                      <IconLoupe ml={2} color={hoverColor} w="38.4px" />
                    </Button>
                  </Stack>
                  <Image
                    flex={2}
                    alignSelf="center"
                    src="/left.png"
                    pb={8}
                    maxW="60px"
                    maxH="110px"
                  />
                </HStack>
              </Stack>
              <Stack py={16} flex={1} spacing={4}>
                <Text
                  mx={4}
                  fontWeight={500}
                  fontSize="20px"
                  lineHeight="24px"
                  color="energieRose"
                  textTransform="uppercase"
                >
                  {t("auth:login.actions.login")}
                </Text>
                <FormLogin onSuccess={handleLogin} />
              </Stack>
            </HStack>
          )}
        </Box>
      </Main>
      <Footer />
    </SlideIn>
  );
}
