/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext();

export const useLanguageContext = () => useContext(LanguageContext);
export function LanguageProvider(props) {
  const { i18n } = useTranslation();

  const handleLanguage = (language) => {
    setLang(language);
    localStorage.setItem("lang", language);
  };
  const [lang, setLang] = useState(
    localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : (navigator.language || navigator.userLanguage) ?? "en"
  );

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <LanguageContext.Provider
      value={{
        lang,
        setLang: handleLanguage,
      }}
      {...props}
    />
  );
}
