import React, { useContext, useState, useMemo } from "react";

export const NavbarContext = React.createContext();
export const useNavbarContext = () => useContext(NavbarContext);
export function NavbarProvider(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLockedExpanded, setIsLockedExpanded] = useState(false);

  const memoizedValue = useMemo(() => ({
    isExpanded,
    setIsExpanded,
    isLockedExpanded,
    setIsLockedExpanded,
  }), [isExpanded, isLockedExpanded]);

  return (
    <NavbarContext.Provider
      value={memoizedValue}
      {...props}
    />
  );
}
