import Axios from "axios";
import { APPENV } from "config/config";
import {  useQuery } from "react-query";

export const useGetCollectifsAdmin = () => {
  const {data, isLoading, isFetching } = useQuery('admin-collectifs',
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/admin/collectifs`
      ),
  );

  const collectifs = data?.data ?? null;
  return {collectifs, isLoading, isFetching};
}
