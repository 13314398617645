import React, { useEffect } from "react";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";
import PropTypes from "prop-types";
import { FormGroup } from "components/form-group";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "./css/react-datepicker.css";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";
import { useLanguageContext } from "context/lang-context";
import { Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const propTypes = {
  inputProps: PropTypes.instanceOf(Object),
  isReadOnly: PropTypes.bool,
  horizontal: PropTypes.bool,
  leftElement: PropTypes.node,
  isNumeric: PropTypes.bool,
  ...fieldPropTypes,
};

const defaultProps = {
  inputProps: undefined,
  leftElement: null,
  horizontal: true,
  isReadOnly: false,
  isNumeric: false,
  ...fieldDefaultProps,
};

export function FieldDatePicker(props) {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
  const {
    inputProps,
    label,
    type,
    required,
    isDisabled,
    placeholder,
    children,
    isReadOnly,
    horizontal,
    leftElement,
    activeRightButton,
    handleClickRightButton,
    isNumeric,
    ...otherProps
  } = props;
  const { lang } = useLanguageContext();
  const { t } = useTranslation();
  useEffect(() => {
    registerLocale("fr", fr);
    registerLocale("enUS", enUS);
    registerLocale("es", es);
  }, []);

  useEffect(() => {
    setDefaultLocale(lang ?? "fr");
  }, [lang]);

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  // Props de l'input par défaut
  const defaultInputProps = {
    textAlign: "center",
    setState: () => null,
  };

  // Merge des props par défaut + custom
  const fullInputProps = {
    ...defaultInputProps,
    ...inputProps,
  };

  const { setState, handleBlur, ...otherInputProps } = fullInputProps;
  return (
    <FormGroup {...formGroupProps}>
      <DatePicker
        id={id}
        value={value}
        onChange={(e) => {
          setValue(e);
          setState(e);
        }}
        onBlur={(e) => {
          setIsTouched(true);
          if (handleBlur) {
            handleBlur(e);
          }
        }}
        placeholderText={t("app:core.form.label.formatHeure")}
        selected={value}
        dateFormat="P"
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        maxDate={new Date()}
        customInput={
          <Input
            border=""
            _autofill={{
              backgroundColor: "#B0B0B0",
              color: "energieBlack.500",
            }}
            _active={{
              backgroundColor: "#B0B0B0",
              color: "energieBlack.500",
            }}
          />
        }
        {...otherInputProps}
      />
    </FormGroup>
  );
}

FieldDatePicker.propTypes = propTypes;
FieldDatePicker.defaultProps = defaultProps;
