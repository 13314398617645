import debounce from "lodash.debounce";
import {APPENV} from "config/config";
import Axios from "axios";

export const handleResearchUserByMobile = debounce(({e, setChoices, setRefBenef}) => {

    if (!e?.target?.value) return null;
    //supprimer le + et les espaces
    const mobile = e.target.value.replace(/\s/g, '').replace('+', '');
    Axios.get(
        `${APPENV.HOST_RB_API}/user/search/m/${mobile}`
    )
        .then((result) => {
            const res = result?.data?.map((r) => ({
                label: r?.identite + ' - ' + r?.clePublique,
                value: r?.clePublique
            }))
            if (res?.length === 1) {
                setRefBenef(res[0]?.value)
            }
            setChoices(res)
        })
        .catch((error) => console.log(error))
    //   setChoices(
    //     result?.features?.map((city) => ({
    //       city: e.target.valuey,
    //       value: e.target.value,
    //       label: e.target.value,
    //     }))
    //   ));
}, 1000);

export const handleResearchUser = debounce(({e, setChoices}) => {
    Axios.get(
        `${APPENV.HOST_RB_API}/user/search/${e.target.value}`
    )
        .then((result) => {
            const res = result?.data?.map((r) => ({
                label: r?.identite + ' - ' + r?.clePublique,
                value: r?.clePublique
            }))
            setChoices(res)
        })
        .catch((error) => console.log(error))
    //   setChoices(
    //     result?.features?.map((city) => ({
    //       city: e.target.valuey,
    //       value: e.target.value,
    //       label: e.target.value,
    //     }))
    //   ));
}, 1000);
