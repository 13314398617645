import { useToast } from "@chakra-ui/react";
import Axios from "axios";
import { MyToast } from "components/Toast/MyToast";
import { APPENV } from "config/config";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

export const useEnvoyerDon = ({onSuccess}) => {
  const toast = useToast()
  const { t } = useTranslation();

  return useMutation(
    (data) =>
      Axios.post(
        `${APPENV.HOST_RB_API}/envoyerDons`,
        data
      ),
    {
      onError: (error) => {
        let descriptionError = "dons:errors.createText"
        if (error?.response?.request.response) {
          const errorObj = JSON.parse(error?.response?.request.response);
          if (
            errorObj.statusCode === 501 &&
            errorObj?.error?.type === "AppException"
          ) {
            descriptionError = errorObj?.error?.description;
          }
        } 
        toast({
          duration: 9000,
          position: "top",
          render: () => (
            <MyToast status="error" backgroundColor="#EA5B0C" color="white" title={t('dons:errors.envoie')} text={t(descriptionError)} />
          ),
        });

      },
      onSuccess:async ({data}) => {
        toast({
          duration: 9000,
          position: "top",
          render: () => (
            <MyToast status="success" backgroundColor="#EA5B0C" color="white" title={t('dons:success.envoie')} text={t('dons:success.envoieText')} />
          ),
        });
        if(onSuccess){
          await onSuccess(data);
        }

      },
    }
  );

};
