import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, FormErrorMessage, FormHelperText } from "@chakra-ui/react";

const propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.node,
  helper: PropTypes.node,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  showError: PropTypes.bool,
  showText: PropTypes.bool,
  labelShowText: PropTypes.string,
  helperUnderline: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  formatValue: PropTypes.instanceOf(Object),
  asyncValidations: PropTypes.instanceOf(Array),
  keepValue: PropTypes.bool,
};
const defaultProps = {
  children: "",
  errorMessage: "",
  helper: "",
  isRequired: false,
  label: "",
  helperUnderline: "",
  labelShowText: "",
  fontSize: "16px",
  showError: false,
  showText: false,
  formatValue: {},
  asyncValidations: [],
  keepValue: false,
  fontColor: "gray.600",
};

export function FormGroup({
  children,
  errorMessage,
  helper,
  isRequired,
  label,
  showError,
  showText,
  labelShowText,
  ...props
}) {
  const {
    formatValue,
    asyncValidations,
    keepValue,
    fontSize,
    fontColor,
    id,
    helperUnderline,
    ...rest
  } = props;

  return (
    <FormControl isInvalid={showError} isRequired={isRequired} {...rest}>
      {!!label && (
        <FormLabel
          htmlFor={id}
          color={fontColor}
          fontSize={fontSize}
          transition="0.2s"
          fontWeight="bold"
        >
          {label}
          {!!helperUnderline && (
            <FormHelperText
              fontSize={fontSize}
              fontStyle="italic"
              key={`${id}-helper`}
              textAlign="left"
              mt="0"
              mb="3"
            >
              {helperUnderline}
            </FormHelperText>
          )}
        </FormLabel>
      )}
      {!!helper && (
        <FormHelperText mt="0" mb="3">
          {helper}
        </FormHelperText>
      )}
      {children}
      {showError && (
        <FormErrorMessage fontWeight="bold" color="#FF9494" fontSize="14px" id={`${id}-error`}>
          {errorMessage}
        </FormErrorMessage>
      )}

      {showText && !showError && (
        <FormHelperText key={`${id}-text`} textAlign="left" mt="3" mb="3">
          {labelShowText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;
