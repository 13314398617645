import { useToast } from "@chakra-ui/react";
import Axios from "axios";
import { MyToast } from "components/Toast/MyToast";
import { APPENV } from "config/config";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

export const useTranslateDon = ({onSuccess}) => {
  const toast = useToast()
  const { t } = useTranslation();

  return useMutation(
    (data) =>
      Axios.post(
        `${APPENV.HOST_RB_API}/deepl`,
        data
      ),
    {
      onError: (error) => {
        // let descriptionError = "dons:errors.translateText"
        // if (error?.response?.request.response) {
        //   const errorObj = JSON.parse(error?.response?.request.response);
        //   if (
        //     errorObj.statusCode === 501 &&
        //     errorObj?.error?.type === "AppException"
        //   ) {
        //     descriptionError = errorObj?.error?.description;
        //   }
        // } 
        // toast({
        //   duration: 9000,
        //   position: "top",
        //   render: () => (
        //     <MyToast status="error" backgroundColor="#EA5B0C" color="white" title={t('dons:errors.translate')} text={t(descriptionError)} />
        //   ),
        // });

      },
      onSuccess:async ({data}) => {
        if(onSuccess){
          await onSuccess(data);
        }
      },
    }
  );

};
