
export const GetDonsColor = (don) => {
    let color = '';
    switch(don.statusDon){
        case 'RECU':
            color = 'blue';  
            break;
        case 'ACCEPTE':
            color = 'green';  
            break;
        case 'REFUSE':
            color = 'red';  
            break;
        default: color = 'blue';         
    }

    return color;
};
