import { extendTheme } from "@chakra-ui/react";
import { Input } from "./components/input";
import Select from "./components/select";
import { Textarea } from "./components/textarea";
import Divider from "./components/divider";
import FormLabel from "./components/formLabel";
import { TableApps } from "./components/tableApps";
import { FormLayout } from "./components/formLayout";
import { ReactSelect } from "./components/reactSelect";
import { Button } from "./components/button";
import { IconButton } from "./components/iconButton";
import tailwindColors from "./tailwindColors";

const fontSizes = {
  list: "0.8rem",
  "3xs": "0.6rem",
  "2xs": "0.8rem",
  xs: "1rem",
  sm: "1.125rem",
  md: "1.25rem",
  lg: "1.375rem",
  xl: "1.5rem",
  footerLink: '14px'

};

const fontWeight = {
  medium: "500",
};

const borderRadius = {
  radii: {
    none: "0",
    base: "0.25rem",
    xs: "0.3rem",
    sm: "0.5rem",
    md: "0.375rem",
    lg: "0.8rem",
    xl: "1.5rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
};

const spacing = {
  space: {
    xs: "0.3rem",
    sm: "0.5rem",
    md: "0.7rem",
    lg: "1rem",
    xl: "1.5rem",
    "2xl": "2rem",
  },
};

const colors = {
  energieBlack: {
    50: '#f8f0f2',
    100: '#d9d9d9',
    200: '#bfbfbf',
    300: '#a6a6a6',
    400: '#191919',
    500: '#000000',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#120b0d',
  },
    energieCyan: "#36A9E1",
    cyan:{
      50: '#ddf8ff',
      100: '#b5e4f9',
      200: '#8cd0f0',
      300: '#61bde9',
      400: '#37aae1',
      500: '#1e90c8',
      600: '#10709c',
      700: '#045071',
      800: '#003147',
      900: '#00121d',
    },
    energieOrange:
      {
        50: '#ffedde',
        100: '#ffceb2',
        200: '#fbae84',
        300: '#f78f54',
        400: '#f46e25',
        500: '#EA5B0C',
        600: '#ab4107',
        700: '#7a2e03',
        800: '#4b1a00',
        900: '#1f0600',
      },
    energieRose: "#E71D73",
    gray: tailwindColors.coolGray,
};

const shadows = {
  card: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const sizes = {
  circularProgress: {
    xs: "1rem",
    sm: "1.5rem",
    md: "2rem",
    lg: "2.5rem",
    xl: "3rem",
    "2xl": "3.5rem",
  },
};


const fonts = {
  body: "Urbanist, sans-serif",
  medium: "Urbanist, sans-serif",
  heading: "Urbanist, sans-serif",
  roboto: "Urbanist, sans-serif",
  nunito: "Urbanist, sans-serif",
};
const styles = {
  // global: {
    ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
      overflow: 'initial !important',
    },
    'html, body': {
      fontFamily: 'Urbanist',
      maximumWidth: '100%',
      fontStyle: "normal",
      width: "100vw",
      backgroundColor: "#000000",
    },
    h3: {
      fontStyle: "medium",
    },
    title: {
      fontFamily: 'Urbanist',
      fontStyle: "medium",
    },
    p: {
      fontFamily: 'Urbanist',
      fontStyle: "medium",
      overflow: 'hidden',
      wordBreak: 'normal',
      textOverflow: 'ellipsis'
    },
  // },
};

export const customTheme = extendTheme({
  components: {
    Input,
    FormLabel,
    Select,
    Textarea,
    Divider,
    TableApps,
    FormLayout,
    ReactSelect,
    Button,
    IconButton,
  },
  styles,
  sizes,
  fonts,
  fontSizes,
  fontWeight,
  ...borderRadius,
  ...spacing,
  colors,
  shadows,
  menuWidth: {
    base: "10rem",
    xs: "5rem",
  },
  headerHeight: 16,
});
