/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { Stack, StackDivider, useRadioGroup } from "@chakra-ui/react";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";
import { RadioCard } from "components/radio/FieldRadio";
import { FormGroup } from "components/form-group";

const propTypes = {
  label: PropTypes.node,
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  options: PropTypes.instanceOf(Array),
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  horizontal: PropTypes.bool,
  ...fieldPropTypes,
};

const defaultProps = {
  label: "",
  direction: "row",
  options: [],
  helper: "",
  isDisabled: false,
  isReadOnly: false,
  horizontal: true,
  ...fieldDefaultProps,
};

export function ReactRadioInput(props) {
  const { id, isValid, isSubmitted, name, setValue, value, errorMessage } = useField(props);

  const {
    label,
    isDisabled,
    required,
    size,
    children,
    callBackOnChange,
    direction,
    isReadOnly,
    horizontal,
    options,
    ...otherProps
  } = props;
  const { formatValue, asyncValidations, keepValue, color, ...rest } = otherProps;

  const showError = !isValid && isSubmitted;
  const formGroupProps = {
    errorMessage,
    id,
    label,
    isRequired: !!required,
    showError,
    isDisabled,
    ...rest,
  };

  const handleChange = (e) => {
    setValue(e);
    if (callBackOnChange !== undefined) {
      callBackOnChange(e);
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: value,
    onChange: handleChange,
  });

  const group = getRootProps();

  return (
    <FormGroup {...formGroupProps}>
      <Stack divider={<StackDivider border={`1px solid ${color}66`} />} {...group}>
        {options.map((opt) => {
          const { value, label } = opt;
          const radio = getRadioProps({ value });
          if (isDisabled && !radio?.isChecked) {
            return null;
          }
          return (
            <RadioCard
              key={value}
              color={color}
              isDisabled={isDisabled}
              borderColor={color}
              cursor={isDisabled ? "not-allowed" : "pointer"}
              {...radio}
            >
              {label}
            </RadioCard>
          );
        })}
      </Stack>
    </FormGroup>
  );
}
ReactRadioInput.propTypes = propTypes;
ReactRadioInput.defaultProps = defaultProps;
