import { ErrorBoundary } from "components/ErrorBoundary";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useAuthentificationContext } from "context/auth-context";
import { useApp } from "context/App/useApp";

export function PublicOnlyRouteGuard({ children }) {
  const { isAuthenticated } = useAuthentificationContext();
  const {
    setPageColor,
    setBackgroundColorNotif,
    setColorNotif,
    setColorNotifHover,
  } = useApp();

  useEffect(() => {
    setPageColor("#E71D73");
    setBackgroundColorNotif("#E71D73");
    setColorNotif("#FFFFFF");
    setColorNotifHover("#000000");
  }, [
    setPageColor,
    setBackgroundColorNotif,
    setColorNotifHover,
    setColorNotif,
  ]);

  return isAuthenticated ? (
    <Navigate to="/" replace />
  ) : (
    <ErrorBoundary>{children}</ErrorBoundary>
  );
}

PublicOnlyRouteGuard.propTypes = {
  children: PropTypes.node,
};
