import { Button, Text } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  icon: null,
  isLoading: false,
  isDisabled: false,
  color: "white",
  className: "",
  text: "",
};

export function ButtonFilters(props) {
  const {
    color,
    isLoading = false,
    isDisabled = false,
    onClick,
    className,
    text,
    icon,
    ...rest
  } = props;

  return (
    <Button
      _hover={{
        backgroundColor: "energieBlack.500",
        borderColor: "white",
        color: "white",
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      backgroundColor="energieBlack.500"
      borderRadius="30px"
      borderColor={color}
      color={color}
      className={className}
      onClick={onClick}
      {...rest}
    >
      <Text fontSize="12px">{text}</Text>
    </Button>
  );
}
ButtonFilters.propTypes = propTypes;
ButtonFilters.defaultProps = defaultProps;
