/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconNext({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_554_2930)" id="nextButton">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.91674 29.0207C6.11187 32.2089 10.4448 34 14.9633 34C24.3662 34 32 26.3826 32 17C32 7.61737 24.3662 0 14.9633 0C10.4448 0 6.11187 1.79107 2.91674 4.97933C2.44214 5.45366 2.44214 6.22246 2.91674 6.69679C3.39133 7.17112 4.16254 7.17112 4.63789 6.69679C7.37593 3.96464 11.0905 2.42933 14.9633 2.42933C23.023 2.42933 29.5662 8.95839 29.5662 17.0008C29.5662 25.0431 23.023 31.5722 14.9633 31.5722C11.0905 31.5722 7.37593 30.0369 4.63789 27.3047C4.16254 26.8304 3.39209 26.8304 2.91674 27.3047C2.44214 27.7783 2.44214 28.5479 2.91674 29.0222V29.0207Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2169 18.4982H24.151C24.8226 18.4982 25.3679 17.954 25.3679 17.2839C25.3679 16.6137 24.8226 16.0696 24.151 16.0696H1.2169C0.545325 16.0696 0 16.6137 0 17.2839C0 17.954 0.545325 18.4982 1.2169 18.4982Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3415 24.9133L23.9281 19.9453C24.8263 19.3564 25.3678 18.3561 25.3678 17.2838C25.3678 16.2114 24.8263 15.2104 23.9281 14.6222L16.3415 9.65427C15.7802 9.28695 15.0249 9.44253 14.656 10.0034C14.2879 10.5635 14.4438 11.3171 15.0059 11.6844L22.5925 16.6524C22.8063 16.792 22.9348 17.0295 22.9348 17.2838C22.9348 17.538 22.8063 17.7756 22.5933 17.9152L15.0059 22.8832C14.4446 23.2512 14.2879 24.0049 14.656 24.5649C15.0249 25.125 15.7802 25.2814 16.3415 24.914V24.9133Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_554_2930">
          <rect width="32" height="34" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
IconNext.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconNext;
