import React from "react";
import { Badge, Collapse, HStack, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import IconHistoTriangleBas from "components/Icons/icons-generated/IconHistoTriangleBas";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { ButtonsEditer } from "components/Buttons/ButtonsEditer";
import { ButtonsSupprimer } from "components/Buttons/ButtonsSupprimer";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { FormatDate } from "functions/common-scope/utils";
import { useLocation, useNavigate } from "react-router";
import { useQueryClient } from "react-query";
import { useValiderDons } from "App/dons/services/useValiderDons";
import { GetDonsColor } from "App/dons/funtions/GetDonsColor";
import { useRefuserDons } from "App/dons/services/useRefuserDons";
import { GetDonsStatut } from "App/dons/funtions/GetDonsStatut";

const CardHistoriqueMobile = React.memo(({ user, don, defaultIsOpen = false }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const isMyDon = user?.id === don?.usager?.id;
  const colorDon = isMyDon ? "#8CA0A0 " : "#36A9E1";
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });
  const isSelected = Number(state?.id) === don?.dons?.id;
  const queryClient = useQueryClient();
  const onSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ["dons"], exact: false });
  };

  const navigate = useNavigate();
  const { mutate: validerDon } = useValiderDons({ onSuccess });
  const { mutate: refuserDon } = useRefuserDons({ onSuccess });
  const handleValider = () => {
    validerDon(don);
  };
  const handleRefuser = () => {
    refuserDon(don);
  };
  const handleModifier = () => {
    navigate({ pathname: `/donsEnergie/${don.dons.id}` });
  };

  return (
    <Stack
      w="90vw"
      h="100%"
      background={isSelected ? "rgba(255,255,255,0.1)" : ""}
      border={isSelected ? `#E71D73 inset 1px` : `${colorDon} inset 1px`}
      borderRadius="10px"
      spacing={0}
    >
      <Badge m={1} fontSize="12px" colorScheme={GetDonsColor(don.dons)} mr="auto">
        {GetDonsStatut({ don, user })}
      </Badge>
      <HStack p={2} borderBottom="#B0B0B066 1px solid">
        <HStack flex={1}>
          <Text fontSize="12px" color="#5A5A5A">
            {t("dons:reference")}
          </Text>
          <Text fontSize="12px" color={colorDon}>
            {don?.dons?.reference}
          </Text>
        </HStack>
        <Stack pr={8}>
          {isOpen ? (
            <CloseIcon onClick={onToggle} textAlign="right" color={colorDon} />
          ) : (
            <SearchIcon onClick={onToggle} textAlign="right" color={colorDon} />
          )}
        </Stack>
      </HStack>
      <Stack>
        <Collapse in={isOpen} height="100%" animateOpacity>
          <Stack py={2} px={8}>
            <Text fontSize="20px" fontWeight="bold" color="#B0B0B0">
              {t(don?.dons?.nom)}
            </Text>
            <Text fontSize="14px" color="#B0B0B0" maxW={400}>
              {t(don?.dons?.description)}
            </Text>
          </Stack>
          <HStack borderTop="#B0B0B066 1px solid" justifyContent="space-between" px={6}>
            {don?.dons?.statusDon === "RECU" && (isMyDon || don?.destinataire?.id === user?.id) ? (
              <HStack flex={1}>
                {isMyDon && don?.dons?.statusDon === "RECU" && isMyDon ? (
                  <ButtonsEditer onClick={handleModifier} color={colorDon} h="30px" w="30px" />
                ) : null}
                {don?.dons?.statusDon === "RECU" && don?.destinataire?.id === user?.id ? (
                  <>
                    <ButtonsSupprimer onClick={handleRefuser} color={colorDon} h="30px" w="30px" />
                    <ButtonsValider onClick={handleValider} color={colorDon} h="25px" w="25px" />
                  </>
                ) : null}
              </HStack>
            ) : null}
          </HStack>
        </Collapse>
      </Stack>
      <HStack
        p={2}
        backgroundColor={colorDon}
        h="100%"
        position="relative"
        spacing={0}
        alignItems="center"
        justifyContent="space-between"
        textAlign="center"
      >
        <Text flex={1} fontSize="14px" color="#000000" fontWeight={600} zIndex={2}>
          {FormatDate(don?.dons?.dateCreation)}
        </Text>
        <Text flex={1} fontSize="20px" fontWeight={700} zIndex={2}>
          {isMyDon ? "-" : "+"} {don?.dons?.quantite}
        </Text>
        <Text flex={1} fontSize="14px" fontWeight={600} color="#000000" zIndex={2}>
          {don?.dons?.frequenceDon}
        </Text>
        <IconEnergieSansPlus
          left="50%"
          zIndex={3}
          bottom="0"
          position="absolute"
          transform="translate(-50%, 50%);"
          color="black"
          w="18px"
          h="18px"
        />

        <IconHistoTriangleBas
          color={colorDon}
          margin={0}
          bottom="0"
          w="110px"
          h="42px"
          left="50%"
          position="absolute"
          transform="translate(-50%, 50%);"
        />
      </HStack>
      <HStack justifyContent="space-between">
        <Stack p={2}>
          <Stack>
            <Text fontSize="12px" color="#5A5A5A">
              {t("dons:destinataire")}
            </Text>
            <Text fontSize="12px" color={colorDon}>
              {don?.destinataire?.clePublique}
            </Text>
          </Stack>
        </Stack>
      </HStack>
    </Stack>
  );
});

CardHistoriqueMobile.propTypes = {
  don: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  defaultIsOpen: PropTypes.bool.isRequired,
};

export { CardHistoriqueMobile };
