export const Button = {
  baseStyle: {
    fontWeight: "normal",
    borderRadius: "md",
    width: "fit-content",
  },
  sizes: {
    xs: {
      fontSize: "0.7em",
      paddingX: "0.6em",
      paddingY: "0.2em",
    },
    sm: {
      fontSize: "2xs",
      paddingX: "0.8em",
      paddingY: "0.2em",
    },
    md: {
      fontSize: "md",
      paddingX: "8",
      paddingY: "5",
    },
  },
  variants: {
    linkFooterMb: {
      textDecoration: "underline",
      fontWeight: 500,
      fontSize: "12px",
      fontStyle: "medium",
      color :"#B0B0B0",
     ":hover":{color: "energieRose" }
   },
    linkFooter: {
       textDecoration: "underline",
       fontWeight: 500,
       fontSize: "14px",
       lineHeight: "17px",
       fontStyle: "medium",
       color :"#B0B0B0",
      ":hover":{color: "energieRose" }
    },
    linkFooterIco: {
      fontWeight: 500,
      fontSize: '18px',
      w: '20px',
      h: '20px',
      color :"#B0B0B0",
     ":hover":{color: "energieRose" }
   },
    default: {
      backgroundColor: "white",
      color: "primary.gray",
      borderColor: "primary.gray",
      border: "1px",
      ":hover": {
        backgroundColor: "blue.500",
        color: "white",
      },
    },
    info: {
      backgroundColor: "primary.gray",
      color: "white",
      ":hover": {
        backgroundColor: "blue.500",
        color: "white",
      },
    },
    cancel: {
      backgroundColor: "red.500",
      color: "white",
      ":hover": { backgroundColor: "red.400" },
      ":disabled&:hover": { backgroundColor: "red.500" },
    },
    blue: {
      backgroundColor: "blue.500",
      color: "white",
      ":hover": { backgroundColor: "blue.400" },
      ":disabled&:hover": { backgroundColor: "blue.500" },
    },
    warn: {
      backgroundColor: "orange.500",
      color: "white",
      ":hover": { backgroundColor: "orange.400" },
      ":disabled&:hover": { backgroundColor: "orange.500" },
    },
    confirm: {
      backgroundColor: "green.500",
      color: "white",
      ":hover": { backgroundColor: "green.400" },
      ":disabled&:hover": { backgroundColor: "green.500" },
    },
    purple: {
      backgroundColor: "purple.500",
      color: "white",
      ":hover": { backgroundColor: "purple.400" },
      ":disabled&:hover": { backgroundColor: "purple.500" },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "default",
  },
};
