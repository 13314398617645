import React from "react";

import { Button, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Logo } from "components/Logo";
// import { Illustration403 } from "./Illustration403";
// import { Illustration404 } from "./Illustration404";
// import { IllustrationDefault } from "./IllustrationDefault";

// const SupportedErrors = {
//   default: { illustration: <IllustrationDefault /> },
//   403: { illustration: <Illustration403 /> },
//   404: { illustration: <Illustration404 /> },
// };

export function ErrorPage({ errorCode = "default" }) {
  const { t } = useTranslation(["components"]);
  const errorType = errorCode;
  return (
    <Center flex="1" p="8">
      <Stack direction={{ base: "column-reverse", md: "row" }} align="center" spacing={4}>
        <Stack
          textAlign={{ base: "center", md: "left" }}
          alignItems={{ base: "center", md: "flex-start" }}
        >
          <Button as={Link} to="/" size="md" variant="link" whiteSpace="initial">
            <Logo my={4} />
          </Button>
          <Heading>{t(`components:errorPage.${errorType}.title`)}</Heading>
          <Text>{t(`components:errorPage.${errorType}.description`)}</Text>
          {!!errorCode && (
            <Text color="gray.500" _dark={{ color: "gray.400" }} fontSize="sm" mt={4}>
              {t(`components:errorPage.errorCode`, {
                code: errorCode,
              })}
            </Text>
          )}
        </Stack>
      </Stack>
    </Center>
  );
}
ErrorPage.propTypes = {
  errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
