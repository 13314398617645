/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconFavoris({ fill = "#E71D73", stroke = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="42"
      height="31"
      viewBox="0 0 42 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="42" height="31" rx="15" stroke={stroke} />
      <path
        d="M21.5 6L24.4037 11.5035L30.535 12.5643L26.1982 17.0265L27.084 23.1857L21.5 20.44L15.916 23.1857L16.8018 17.0265L12.465 12.5643L18.5963 11.5035L21.5 6Z"
        fill={fill}
      />
      <path
        d="M21.5 7.07149L23.9614 11.7368L24.0762 11.9542L24.3184 11.9961L29.516 12.8954L25.8397 16.6781L25.6683 16.8544L25.7033 17.0977L26.4542 22.3188L21.7206 19.9913L21.5 19.8828L21.2794 19.9913L16.5458 22.3188L17.2967 17.0977L17.3317 16.8544L17.1603 16.6781L13.484 12.8954L18.6816 11.9961L18.9238 11.9542L19.0386 11.7368L21.5 7.07149Z"
        stroke={stroke}
      />
    </chakra.svg>
  );
}
IconFavoris.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconFavoris;
