/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconDoubleMains({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="27"
      height="29"
      viewBox="0 0 27 29"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_521_389)">
        <path
          d="M18.8606 21.7037L15.8489 23.3001C15.3489 22.4999 14.4852 22.0194 13.5593 22.0254L10.6354 22.0476C10.1905 22.0503 9.75076 21.9507 9.34841 21.7556L9.04566 21.6082C7.52539 20.8624 5.75151 20.8941 4.25745 21.693L4.25417 21.2064C4.2522 20.9089 4.01564 20.67 3.726 20.672L0.520314 20.6969C0.230675 20.6989 -0.00195351 20.9419 1.23685e-05 21.2393L0.053091 28.4649C0.0550569 28.7617 0.290962 29.0013 0.580601 28.9993C0.580601 28.9993 0.580601 28.9993 0.581257 28.9993L3.78695 28.9744C4.07658 28.9724 4.30921 28.7294 4.30725 28.432L4.30528 28.1473L5.06149 27.7064C5.35506 27.5301 5.70368 27.4783 6.03394 27.5617L11.0515 28.8149C11.0627 28.8176 11.0744 28.8203 11.0862 28.8223C12.2461 29.0262 13.4394 28.8439 14.4911 28.3014C14.5134 28.29 14.5344 28.2765 14.5547 28.2624L21.5952 23.2167C21.8225 23.0538 21.8868 22.7368 21.7419 22.4939C21.1404 21.483 19.8724 21.135 18.8606 21.7037ZM1.09763 27.9191L1.05241 21.7704L3.20963 21.7535L3.25485 27.9023L1.09697 27.9191H1.09763ZM13.9853 27.3619C13.1504 27.7852 12.2081 27.9292 11.2894 27.7758L11.2802 27.7744L6.28099 26.5206C5.68991 26.3705 5.06542 26.462 4.53857 26.7756L4.29545 26.917L4.26662 22.9448C5.54182 22.0335 7.19446 21.8955 8.59679 22.582L8.89953 22.7294C9.44474 22.9939 10.0411 23.1292 10.6439 23.1258L13.5678 23.1029C14.2769 23.0982 14.9092 23.5586 15.1405 24.2464L10.944 24.2787C10.6544 24.2807 10.4211 24.523 10.4231 24.8205C10.4244 25.0823 10.6092 25.3051 10.8608 25.3482C10.891 25.3535 10.9211 25.3556 10.9519 25.3556L15.7578 25.3185C16.0475 25.3165 16.2801 25.0736 16.2781 24.7761C16.2768 24.6186 16.2624 24.4618 16.2355 24.307L19.3475 22.6574C19.3514 22.6554 19.3554 22.6534 19.3593 22.6507C19.7178 22.4488 20.1548 22.4642 20.4989 22.6917L13.9853 27.3612V27.3619Z"
          fill={color}
        />
        <path
          d="M26.9463 0.534395C26.9443 0.236918 26.7078 -0.00200637 26.4181 1.27032e-05L23.2125 0.0249146C22.9228 0.0269337 22.6902 0.269896 22.6922 0.567373L22.6941 0.852063L21.9379 1.29289C21.6443 1.46855 21.2957 1.52105 20.9655 1.4376L15.9479 0.184422C15.9367 0.18173 15.9249 0.179038 15.9132 0.177018C14.7533 -0.0269083 13.56 0.155482 12.5083 0.69794C12.486 0.709382 12.465 0.722842 12.4447 0.736976L5.40423 5.78265C5.17685 5.94552 5.11263 6.26252 5.25745 6.50548C5.85835 7.51636 7.127 7.86499 8.13942 7.29628L11.1505 5.70054C11.6498 6.50144 12.5142 6.98265 13.4401 6.97458L16.364 6.95169C16.8089 6.949 17.2486 7.04861 17.651 7.24379L17.9537 7.39118C19.474 8.13689 21.2479 8.10526 22.742 7.30638L22.7452 7.79298C22.7472 8.05411 22.9313 8.27621 23.1823 8.31995C23.2125 8.32534 23.2426 8.32736 23.2734 8.32736L26.4791 8.30246C26.7687 8.30044 27.0014 8.05748 26.9994 7.76L26.9463 0.534395ZM18.4026 6.41866L18.0999 6.27127C17.5547 6.00677 16.9583 5.87081 16.3555 5.87485L13.4316 5.89774C12.7225 5.90245 12.0902 5.4421 11.8589 4.75427L16.0554 4.72196C16.345 4.72196 16.5796 4.48102 16.5796 4.18354C16.5796 3.88606 16.345 3.64512 16.0554 3.64512C16.0527 3.64512 16.0501 3.64512 16.0468 3.64512L11.2409 3.68214C10.9513 3.68415 10.7187 3.92712 10.7206 4.22459C10.7219 4.38141 10.7363 4.53822 10.7632 4.69302L7.65188 6.34261C7.64795 6.34462 7.64402 6.34664 7.64009 6.34934C7.2823 6.55192 6.84456 6.53711 6.50054 6.3103L13.0148 1.63816C13.8496 1.21482 14.7919 1.07012 15.71 1.22425L15.7192 1.22559L20.7184 2.47944C21.3095 2.62952 21.934 2.53799 22.4608 2.22436L22.7039 2.0837L22.7328 6.0559C21.4576 6.96717 19.8049 7.10582 18.4033 6.41866H18.4026ZM23.7898 7.24648L23.7445 1.09772L25.9018 1.08089L25.947 7.22965L23.7898 7.24648Z"
          fill={color}
        />
        <path
          d="M15.8266 17.9994V15.0731L18.2938 13.6099C18.4995 13.4881 18.5696 13.2176 18.451 13.0062C18.3324 12.7949 18.069 12.7229 17.8632 12.8447L15.3954 14.3079L12.9276 12.8447C12.7218 12.7229 12.459 12.7949 12.3398 13.0062C12.2212 13.2176 12.2913 13.4874 12.497 13.6099L14.9649 15.0731V17.9994C14.9649 18.243 15.1575 18.4409 15.3954 18.4409C15.6333 18.4409 15.8253 18.243 15.8253 17.9994H15.8266Z"
          fill={color}
        />
        <path
          d="M17.8056 15.4527C17.6267 15.4035 17.4399 15.4284 17.2794 15.524C17.1188 15.6189 17.0041 15.7724 16.9563 15.9561C16.9085 16.1398 16.9327 16.3316 17.0258 16.4965C17.1542 16.7247 17.3875 16.8526 17.6273 16.8526C17.7446 16.8526 17.8639 16.8216 17.9727 16.757C18.1332 16.6621 18.2479 16.5087 18.2957 16.3249C18.3436 16.1412 18.3193 15.9494 18.2263 15.7845C18.1339 15.6196 17.9845 15.5018 17.8056 15.4527Z"
          fill={color}
        />
        <path
          d="M15.2171 12.8615C15.2768 12.8777 15.3377 12.8857 15.3973 12.8857C15.5173 12.8857 15.6359 12.8534 15.7427 12.7902C15.9032 12.6953 16.0179 12.5418 16.0657 12.3581C16.1136 12.1743 16.0893 11.9825 15.9963 11.8176C15.8049 11.4778 15.3803 11.3607 15.0494 11.5572C14.8888 11.6521 14.7742 11.8055 14.7263 11.9893C14.6785 12.173 14.7027 12.3648 14.7958 12.5297C14.8882 12.6946 15.0376 12.8124 15.2165 12.8615H15.2171Z"
          fill={color}
        />
        <path
          d="M13.3463 15.452C13.1674 15.4029 12.9807 15.4278 12.8201 15.5233C12.6596 15.6182 12.5449 15.7717 12.4971 15.9554C12.4492 16.1392 12.4735 16.331 12.5665 16.4959C12.695 16.724 12.9283 16.8519 13.1681 16.8519C13.2854 16.8519 13.4047 16.8209 13.5134 16.7563C13.8444 16.5598 13.9584 16.1237 13.767 15.7838C13.6746 15.6189 13.5252 15.5011 13.3463 15.452Z"
          fill={color}
        />
        <path
          d="M15.3928 9.41226C14.4734 9.41226 13.5652 9.6633 12.7651 10.1371C12.1504 10.5019 11.6229 10.9797 11.1976 11.5572C10.7874 12.1151 10.4899 12.741 10.313 13.4188C10.1361 14.0965 10.0889 14.7918 10.1728 15.4856C10.2599 16.2044 10.4834 16.8889 10.8385 17.5209C11.309 18.3581 11.9886 19.05 12.8044 19.5231C13.5947 19.9815 14.4924 20.2244 15.4007 20.2244C16.3089 20.2244 17.2283 19.9741 18.0277 19.4996C18.6424 19.1348 19.1699 18.6569 19.5952 18.0795C20.0054 17.5215 20.3029 16.8956 20.4798 16.2179C20.6568 15.5402 20.7039 14.8449 20.6201 14.151C20.5329 13.4322 20.3095 12.7478 19.9543 12.1158C19.4838 11.2792 18.8043 10.5867 17.9891 10.1136C17.1988 9.65522 16.301 9.41226 15.3928 9.41226ZM11.6609 17.0336C10.4696 14.9142 11.1767 12.2046 13.2402 10.9811C13.9197 10.5779 14.6615 10.3868 15.3928 10.3868C16.8836 10.3868 18.3338 11.1816 19.1326 12.6031C20.3239 14.7224 19.6168 17.432 17.5533 18.6556C16.8738 19.0587 16.132 19.2499 15.4007 19.2499C13.9099 19.2499 12.4597 18.455 11.6609 17.0336Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_521_389">
          <rect width="27" height="29" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
IconDoubleMains.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconDoubleMains;
