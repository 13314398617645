import { useToast } from "@chakra-ui/react";
import Axios from "axios";
import { MyToast } from "components/Toast/MyToast";
import { APPENV } from "config/config";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteDons = ({onSuccess}) => {
  const toast = useToast()
  const { t } = useTranslation();
  const queryClient = useQueryClient()

  return useMutation(
    (data) =>
      Axios.post(
        `${APPENV.HOST_RB_API}/dons/${data.dons.id}/delete`,
        data
      ),
    {
      onError: (error) => {
        let descriptionError = "dons:delete.error"
        if (error?.response?.request.response) {
          const errorObj = JSON.parse(error?.response?.request.response);
          if (
            errorObj.statusCode === 501 &&
            errorObj?.error?.type === "AppException"
          ) {
            descriptionError = errorObj?.error?.description;
          }
        } 
        toast({
          duration: 9000,
          position: "top",
          render: () => (
            <MyToast status="error" backgroundColor="#EA5B0C" color="white" title={t('dons:delete.title')} text={t(descriptionError)} />
          ),
        });

      },
      onSuccess: async  () => {
        toast({
          duration: 9000,
          position: "top",
          render: () => (
            <MyToast status="success" backgroundColor="#EA5B0C" color="white" title={t('dons:delete.title')} text={t('dons:delete.success')} />
          ),
        });
        if(onSuccess){
          await onSuccess();
        }
      },
    }
  );

};
