/* eslint-disable react/jsx-no-constructed-context-values */
import { useGetUser } from "App/utilisateur/services/useGetUser";
import React, { useState, createContext, useContext, useCallback } from "react";
// import { useQueryClient } from "react-query";

export const AuthentificationContext = createContext();

const updateToken = (newToken) => {
  if (!newToken) {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  } else {
    localStorage.setItem("token", newToken.accessToken);
    localStorage.setItem("refreshToken", newToken.refreshToken);
  }
};

export const useAuthentificationContext = () => useContext(AuthentificationContext);
export function AuthentificationProvider(props) {
  const [accessToken, setAccessToken] = useState(localStorage.getItem("token") ?? null);
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refreshToken") ?? null);
  // const queryClient = useQueryClient();
  // const data = queryClient.getQueryData("user");
  const { user, isLoading } = useGetUser({
    isEnabled: !!accessToken && !!refreshToken,
  });
  const handleUpdateToken = useCallback(
    (newToken) => {
      setAccessToken(newToken?.accessToken ?? null);
      setRefreshToken(newToken?.refreshToken ?? null);
      updateToken(newToken);
    },
    [setAccessToken, setRefreshToken]
  );

  return (
    <AuthentificationContext.Provider
      value={{
        // user: data?.data ?? user,
        user,
        isLoading,
        isAuthenticated: !!accessToken && !!refreshToken,
        updateToken: handleUpdateToken,
      }}
      {...props}
    />
  );
}
