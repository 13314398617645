import Axios from "axios";
import { APPENV } from "config/config";
import { useMutation, useQueryClient } from "react-query";

export const useToggleHidden = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data) =>
      Axios.post(
        `${APPENV.HOST_RB_API}/hidden`,
        data
      ),
    {
      onError: (error) => {
      },
      onSuccess:async ({data}) => {
        await queryClient.invalidateQueries({ queryKey: ["searchDons"], exact: false })
      },
    }
  );

};
