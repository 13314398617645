import React, { useCallback, useEffect, useState } from "react";
import { Box, Heading, Stack, Text, useConst, useDisclosure } from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FieldFactory } from "components/fields/FieldFactory";
import { useLanguageContext } from "context/lang-context";
import { useGlobalEvents } from "hooks/useGlobalEvents";
import { useApp } from "context/App/useApp";
import { useNavigate } from "react-router";
import { ModalCustom } from "components/Modal/Modal";
import { ReactRadioInput } from "components/fields/ReactRadioInput";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { useCreateUser } from "../services/useCreateUser";
import { useGetPays } from "../services/useGetPays";
import { useLogin } from "../services/useLogin";

export function FormRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginForm = useForm({
    subscribe: "form",
  });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { lang } = useLanguageContext();

  const { pageColor = "#EA5B0C" } = useApp();
  const [hoverColor, setHoverColor] = useState({
    isHover: false,
  });
  useGlobalEvents({
    form: loginForm,
    type: "keypress",
    key: "Enter",
    action: "submitForm",
  });

  const currentYear = useConst(new Date().getFullYear());
  const { pays, isLoading: isLoadingPays } = useGetPays();
  const [currentChoice, setCurrentChoice] = useState("PARTICULIER");
  const [libelleIdentite, setLibelleIdentite] = useState(
    t("app:core.form.label.identiteParticulier")
  );
  const [placeHolderLocalisation, setPlaceholderLocalisation] = useState(
    t("app:core.form.placeholder.localisationParticulier")
  );
  const [placeHolderIdentite, setPlaceholderIdentite] = useState(
    t("app:core.form.placeholder.identiteParticulier")
  );
  const [requiredIdentite, setRequiredIdentite] = useState(
    t("app:core.form.required.identiteParticulier")
  );
  const [labelHeure, setLabelHeure] = useState(t("app:core.form.label.heureParticulier"));
  const [aideModal, setAideModal] = useState(t("app:core.form.label.modalAideParticulier"));

  const handleRedirect = () => {
    onClose();
    navigate("/dashboard");
  };
  const { mutate } = useLogin({
    onSuccess: handleRedirect,
  });

  const handleConnexion = (values) => {
    mutate(values);
  };

  const handleSubmit = (values) => {
    if (values?.heurenaissance < values?.heurenaissanceConfirm) {
      return null;
    }
    if (values?.heurenaissance > values?.heurenaissanceConfirm) {
      return null;
    }
    // if (values?.heure?.length === 4 && values?.heure === values?.heureConfirm) {
    createUser(values);
    // }
    return null;
  };

  const handleSuccess = (values) => {
    const datas = {
      password: values?.heurenaissance,
      heure: values?.heure,
      identite: values?.identite,
    };
    handleConnexion(datas);
  };

  const { mutate: createUser, isLoading } = useCreateUser({
    handleSuccess,
  });

  const handleValider = () => {
    loginForm.submit();
  };

  const handleRegister = () => {
    if (loginForm.isValid) {
      onOpen();
    } else {
      loginForm.submit();
    }
  };

  const handleChange = useCallback(
    (e) => {
      setCurrentChoice(e);
      switch (e) {
        case "PARTICULIER":
          setLibelleIdentite(t("app:core.form.label.identiteParticulier"));
          setPlaceholderIdentite(t("app:core.form.placeholder.identiteParticulier"));
          setPlaceholderLocalisation(t("app:core.form.placeholder.localisationParticulier"));
          setRequiredIdentite(t("app:core.form.required.identiteParticulier"));
          setLabelHeure(t("app:core.form.label.heureParticulier"));
          setAideModal(t("app:core.form.label.modalAideParticulier"));
          break;
        case "ENSEIGNE":
          setLibelleIdentite(t("app:core.form.label.identiteEnseigne"));
          setPlaceholderIdentite(t("app:core.form.placeholder.identiteEnseigne"));
          setPlaceholderLocalisation(t("app:core.form.placeholder.localisationEnseigne"));
          setRequiredIdentite(t("app:core.form.required.identiteEnseigne"));
          setLabelHeure(t("app:core.form.label.heureEnseigne"));
          setAideModal(t("app:core.form.label.modalAideEnseigne"));
          break;
        case "SOCIETE":
          setPlaceholderLocalisation(t("app:core.form.placeholder.localisationSociete"));
          setLibelleIdentite(t("app:core.form.label.identiteSociete"));
          setPlaceholderIdentite(t("app:core.form.placeholder.identiteSociete"));
          setRequiredIdentite(t("app:core.form.required.identiteSociete"));
          setLabelHeure(t("app:core.form.label.heureSociete"));
          setAideModal(t("app:core.form.label.modalAideSociete"));
          break;
        default:
          setPlaceholderLocalisation(t("app:core.form.placeholder.localisationParticulier"));
          setLibelleIdentite(t("app:core.form.label.identiteParticulier"));
          setPlaceholderIdentite(t("app:core.form.placeholder.identiteParticulier"));
          setRequiredIdentite(t("app:core.form.required.identiteParticulier"));
          setLabelHeure(t("app:core.form.label.heureParticulier"));
          setAideModal(t("app:core.form.label.modalAideParticulier"));
          break;
      }
    },
    [t]
  );

  useEffect(() => {
    handleChange(currentChoice);
  }, [lang, handleChange, currentChoice]);

  return (
    <Formiz id="register-form" autoForm onValidSubmit={handleSubmit} connect={loginForm}>
      <Stack alignItems="center" mb={8}>
        <Heading textTransform="uppercase" fontSize="24px" color="energieOrange.500">
          {t("account:inscription.title")}
        </Heading>
      </Stack>
      <ModalCustom
        title={t("account:inscription.modalTitle")}
        textDescription={aideModal}
        bgColor={pageColor}
        color="white"
        isOpen={isOpen}
        onClose={onClose}
        handleValider={handleValider}
        className={hoverColor.isHover ? "logoHover" : ""}
        onMouseEnter={() => setHoverColor({ isHover: true })}
        onMouseLeave={() => setHoverColor({ isHover: false })}
        isLoading={isLoading}
      >
        <Stack justifyContent="center" spacing={4} p={4}>
          <FieldFactory
            type="datetime"
            name="heurenaissanceConfirm"
            placeholder={`${t("app:core.form.placeholder.heure")}`}
            required={`${t("app:core.form.required.confirmRegister")}`}
          />
          <Stack alignItems="center">
            <Text>{t("account:inscription.modalDesBis1")}</Text>
            <Text>{t("account:inscription.modalDesBis2")}</Text>
          </Stack>
        </Stack>
      </ModalCustom>

      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        mb={8}
        spacing={4}
      >
        <Box w="100%" flex={1}>
          <Text textTransform="uppercase" fontSize="18px" mb="4" color="energieOrange.500">
            {t("account:donneesProfil.title")}
          </Text>
          <Stack borderLeft="solid #EA5B0C 4px" spacing={4}>
            <ReactRadioInput
              color={pageColor}
              direction="column"
              name="profil"
              required={`${t("app:core.form.required.profil")}`}
              defaultValue="PARTICULIER"
              callBackOnChange={handleChange}
              options={[
                {
                  label: t("account:profil.particulier.label"),
                  value: t("account:profil.particulier.value"),
                },
                {
                  label: t("account:profil.collectif.label"),
                  value: t("account:profil.collectif.value"),
                },
                {
                  label: t("account:profil.entite.label"),
                  value: t("account:profil.entite.value"),
                },
              ]}
            />
          </Stack>
        </Box>
        <Box w="100%" flex={1}>
          <Text textTransform="uppercase" fontSize="18px" mb="4" color="energieOrange.500">
            {t("account:infosObligatoire.title")}
          </Text>
          <Stack borderLeft="solid #EA5B0C 4px" spacing={4} p={4}>
            <FieldFactory
              key={`identite_${lang}`}
              // label={`${libelleIdentite}`}
              type="text"
              name="identite"
              capitalize
              required={`${requiredIdentite}`}
              placeholder={`${libelleIdentite}`}
            />
            <FieldFactory
              key={`heure_${lang}_${currentChoice}`}
              label={`${labelHeure}`}
              type="datetime"
              name="heurenaissance"
              placeholder={`${t("app:core.form.placeholder.heure")}`}
              required={`${t("app:core.form.required.heure")}`}
              validations={[
                {
                  rule: (val) =>
                    currentChoice === "PARTICULIER"
                      ? dayjs(val).isValid() &&
                        dayjs(val).year() !== currentYear &&
                        dayjs(val).year() >= currentYear - 100 &&
                        dayjs(val).year() < currentYear
                      : dayjs(val).isValid(),
                  message: t("app:core.form.label.formatHeure"),
                },
              ]}
            />

            <FieldFactory
              isLoading={isLoadingPays}
              // label={`${t("app:core.form.label.localisation")}`}
              type="select"
              name="localisation"
              placeholder={`${t(placeHolderLocalisation)}`}
              options={pays?.map((p) => ({
                label: p.nom,
                value: p.codeIso,
              }))}
              required={`${t("app:core.form.required.localisation")}`}
            />
          </Stack>
        </Box>
      </Stack>
      <Stack py={8} borderTop="solid #EA5B0C 4px" alignItems="end">
        <ButtonsValider
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          onClick={handleRegister}
          isDisabled={isLoading}
          isLoading={isLoading}
          color={pageColor}
        />
      </Stack>
    </Formiz>
  );
}
