import React, { useState } from "react";
import { Button, Flex, HStack, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LanguagePicker } from "components/Language/LanguagePicker";
import { useAuthentificationContext } from "context/auth-context";
import IconNext from "components/Icons/icons-generated/IconNext";
import "./Header.css";
import IconEnergie from "components/Icons/icons-generated/IconEnergie";
import { useTranslation } from "react-i18next";
import { NotificationsDrawer } from "components/Notifications/NotificationsDrawer";
import { MenuDrawer } from "components/Menu/MenuDrawer";
import { useApp } from "context/App/useApp";
import { LogoChangeDot } from "components/LogoBis/LogoChangeDot";

export function Header() {
  const { isAuthenticated } = useAuthentificationContext();
  const { t } = useTranslation();
  const { pageColor } = useApp();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [hoverColor, setHoverColor] = useState({
    color: "#E71D73",
    isHover: false,
  });
  const location = useLocation();

  const to = location.pathname === "/dashboard" ? "/logout" : `/`;

  const handleEnergie = () => {
    if (location.pathname === "/dashboard") {
      return navigate(`/donsEnergie`);
    }
    if (location.pathname === "/donsEnergie") {
      return navigate(`/account/proposerDon`);
    }
    if (location.pathname === "/account/proposerDon") {
      return navigate(`/dashboard`);
    }
    return navigate(`/donsEnergie`);
  };

  if (isMobile) {
    return (
      <Stack
        minH="120px"
        mb={8}
        borderBottom="solid 1px #B0B0B0"
        position="relative"
        w="100%"
        mx="auto"
      >
        {!isAuthenticated ? <LanguagePicker /> : null}

        <HStack justifyContent="center" m={2}>
          <Button h="80px" as={Link} to={to} size="md" variant="link" whiteSpace="initial">
            {pageColor === "#EA5B0C" ? (
              <LogoChangeDot topDot="#EA5B0C" leftDot="#36A9E1" rightDot="#E71D73" h="100%" />
            ) : null}
            {pageColor === "#E71D73" ? (
              <LogoChangeDot topDot="#E71D73" leftDot="#EA5B0C" rightDot="#36A9E1" h="100%" />
            ) : null}
            {pageColor === "#36A9E1" ? (
              <LogoChangeDot topDot="#36A9E1" leftDot="#E71D73" rightDot="#EA5B0C" h="100%" />
            ) : null}
          </Button>
        </HStack>
        {isAuthenticated ? (
          <Stack justifyContent="center">
            <HStack p={2} justifyContent="space-between" position="relative">
              <Flex onClick={handleEnergie} flex={1} justifyContent="center">
                <IconEnergie color={pageColor} h="30px" />
              </Flex>
              <Flex flex={1} justifyContent="center">
                <MenuDrawer />
              </Flex>
              <Flex flex={1} justifyContent="center">
                <NotificationsDrawer />
              </Flex>
            </HStack>
          </Stack>
        ) : null}
      </Stack>
    );
  }

  return (
    <HStack
      mb={8}
      w="80%"
      mx="auto"
      h="120px"
      borderBottom="solid 1px #B0B0B0"
      direction="column"
      position="relative"
    >
      {!isAuthenticated ? (
        <LanguagePicker />
      ) : (
        <IconEnergie
          onClick={handleEnergie}
          color={pageColor}
          _hover={{ cursor: "pointer" }}
          className={hoverColor.isHover ? "energieDivineHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
        />
      )}
      <HStack w="100%" position="relative" alignItems="center">
        <Flex transform="translate(-60%, -50%);" position="absolute" top="50%" left="50%">
          <Button h="80px" as={Link} to={to} size="md" variant="link" whiteSpace="initial">
            {pageColor === "#EA5B0C" ? (
              <LogoChangeDot topDot="#EA5B0C" leftDot="#36A9E1" rightDot="#E71D73" h="100%" />
            ) : null}
            {pageColor === "#E71D73" ? (
              <LogoChangeDot topDot="#E71D73" leftDot="#EA5B0C" rightDot="#36A9E1" h="100%" />
            ) : null}
            {pageColor === "#36A9E1" ? (
              <LogoChangeDot topDot="#36A9E1" leftDot="#E71D73" rightDot="#EA5B0C" h="100%" />
            ) : null}
          </Button>
        </Flex>
        {!isAuthenticated ? (
          <Flex position="absolute" top="50%" right="0%" alignItems="center" as={Link} to="/">
            <Text
              textTransform="uppercase"
              fontSize="16px"
              className={hoverColor.isHover ? "fadeIn" : "fadeOutText"}
              mr={2}
            >
              {t("auth:login.actions.login")}
            </Text>
            <IconNext
              color={pageColor}
              width="auto"
              right={0}
              className={hoverColor.isHover ? "fadeIn" : "fadeOut"}
              _hover={{ cursor: "pointer" }}
              onMouseEnter={() => setHoverColor({ isHover: true })}
              onMouseLeave={() => setHoverColor({ isHover: false })}
            />
          </Flex>
        ) : (
          <HStack w="100%" justifyContent="end">
            <MenuDrawer hoverColor={hoverColor} />
            <NotificationsDrawer hoverColor={hoverColor} />
          </HStack>
        )}
      </HStack>
    </HStack>
  );
}
