/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconCopier({ color = "#FFFFFF", ...rest }) {
  return (
    <chakra.svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_296_3607)">
        <path
          d="M14.25 0H6.10701C4.67114 0 3.39268 1.27364 3.39268 2.62504L2.60815 2.64275C1.17322 2.64275 0 3.89867 0 5.25007V18.375C0 19.7261 1.27846 21 2.71433 21H12.893C14.3289 21 15.6073 19.7264 15.6073 18.375H16.286C17.7218 18.375 19.0003 17.1013 19.0003 15.7499V5.26569L14.25 0ZM12.893 19.6876H2.71433C2.00183 19.6876 1.35732 19.0439 1.35732 18.375V5.25007C1.35732 4.58143 1.93757 3.95932 2.65007 3.95932L3.39299 3.93771V15.7502C3.39299 17.1013 4.67145 18.3753 6.10732 18.3753H14.2503C14.2503 19.0439 13.6058 19.6879 12.8933 19.6879L12.893 19.6876ZM17.643 15.7502C17.643 16.4189 16.9982 17.0626 16.2857 17.0626H6.10701C5.39451 17.0626 4.75 16.4189 4.75 15.7502V2.62504C4.75 1.95639 5.39451 1.31267 6.10701 1.31267H12.8927C12.8818 2.8241 12.8927 3.95422 12.8927 3.95422C12.8927 5.31793 14.1615 6.56274 15.607 6.56274H17.6427V15.7502H17.643ZM15.6073 5.25007C14.8846 5.25007 14.2503 3.98034 14.2503 3.29909V1.33158L17.6433 5.25007H15.6076H15.6073ZM14.2503 9.19949H8.1433C7.76858 9.19949 7.46464 9.49283 7.46464 9.85493C7.46464 10.217 7.76858 10.5104 8.1433 10.5104H14.2503C14.625 10.5104 14.929 10.217 14.929 9.85493C14.929 9.49283 14.625 9.19949 14.2503 9.19949ZM14.2503 12.4776H8.1433C7.76858 12.4776 7.46464 12.7709 7.46464 13.133C7.46464 13.4951 7.76858 13.7887 8.1433 13.7887H14.2503C14.625 13.7887 14.929 13.4954 14.929 13.133C14.929 12.7706 14.625 12.4776 14.2503 12.4776Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_296_3607">
          <rect width="19" height="21" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
IconCopier.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconCopier;
