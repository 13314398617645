import React from "react";
import { HStack, Stack, Divider, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { ButtonsCopy } from "components/Buttons/ButtonsCopy";
import IconTelegram from "components/Icons/icons-generated/IconTelegram";
import IconEmail from "components/Icons/icons-generated/IconEmail";
import { ButtonsCustom } from "components/Buttons/ButtonsCustom";
import { Link, useNavigate } from "react-router-dom";

export function CardCollectif({ collectif }) {
  const colorDon = "#36A9E1";
  const navigate = useNavigate();

  return (
    <Stack w="100%" h="100%" border={`${colorDon} inset 1px`} borderRadius="10px">
      <Stack spacing={4} p={4}>
        <Text
          cursor="pointer"
          fontSize="20px"
          fontWeight="bold"
          color={colorDon}
          onClick={() => navigate(`/dashboard/dons?collectif=${collectif.collectif.id}`)}
        >
          {collectif?.collectif?.identite}
        </Text>
        <Text fontSize="12px" color={colorDon}>
          {collectif?.collectif?.clePublique}
        </Text>
        <Divider />
        <HStack justifyContent="space-between">
          <ButtonsCustom
            isDisabled={!collectif.collectif.lienCollectif}
            as={Link}
            to={collectif.collectif.lienCollectif}
            color={colorDon}
            icon={<IconTelegram h="20px" color={colorDon} />}
          />
          <ButtonsCustom
            isDisabled={!collectif.collectif.mail && !collectif.collectif.mailCollectif}
            onClick={(e) => {
              if (collectif.collectif.mail || collectif.collectif.mailCollectif) {
                window.location.href = `mailto:${
                  collectif.collectif.mail ?? collectif.collectif.mailCollectif
                }`;
                e.preventDefault();
              }
            }}
            color={colorDon}
            icon={<IconEmail h="20px" color={colorDon} />}
          />
          <ButtonsCopy color={colorDon} copyText={collectif?.collectif?.clePublique ?? ""} />
        </HStack>
      </Stack>
    </Stack>
  );
}
CardCollectif.propTypes = {
  collectif: PropTypes.instanceOf(Object).isRequired,
};
