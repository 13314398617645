import Axios from "axios";
import { APPENV } from "config/config";
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';
import { useAuthentificationContext
 } from "context/auth-context";
import {useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MyToast } from "components/Toast/MyToast";

export const useLogin = ({onSuccess}) => {
  let navigate = useNavigate();
  const toast = useToast()
  const { updateToken } = useAuthentificationContext();
  const axiosAuth = Axios.create()
  const {t} = useTranslation();
  return useMutation(
    (data) =>
      axiosAuth.post(
        `${APPENV.HOST_RB_API}/login`,
        data,
        {
          headers:{
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          }
        }
      ),
    {
      onError: (error) => {
        let descriptionError = "api:inscription.default"
        if (error?.response?.request.response) {
          const errorObj = JSON.parse(error?.response?.request.response);
          if (
            errorObj.statusCode === 501 &&
            errorObj?.error?.type === "AppException"
          ) {
            descriptionError = errorObj?.error?.description;
          }
        } 
        toast({
          duration: 9000,
          position: "top",
          render: () => (
            <MyToast  status="error" backgroundColor="#E71D73" color="white" title={t('app:errors.login')} text={t(descriptionError)} />
          ),
        });
      },
      onSuccess: async ({data}) => {
        //sauvegarde jwt
        await updateToken(data?.data);
        if(onSuccess){
          await onSuccess();
        }
      },
    }
  );

};
