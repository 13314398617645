import React from "react";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";
import { IconButton, InputGroup, InputRightElement, Textarea } from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import PropTypes from "prop-types";
import { FormGroup } from "components/form-group";
import { LabelValeur } from "./LabelValeur";

const propTypes = {
  inputProps: PropTypes.instanceOf(Object),
  isReadOnly: PropTypes.bool,
  horizontal: PropTypes.bool,
  backgroundColor: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  inputProps: undefined,
  isReadOnly: false,
  horizontal: true,
  ...fieldDefaultProps,
};

export function FieldInputTextArea(props) {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
  const {
    inputProps,
    label,
    type,
    required,
    isDisabled,
    placeholder,
    textAlign,
    isReadOnly,
    horizontal,
    backgroundColor,
    ...otherProps
  } = props;

  const { activeRightButton, handleClickRightButton } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  if (isReadOnly) {
    return (
      <LabelValeur label={label} horizontal={horizontal}>
        {value}
      </LabelValeur>
    );
  }

  return (
    <FormGroup h="100%" {...formGroupProps}>
      <InputGroup h="100%">
        <Textarea
          minH="80px"
          h="100%"
          textAlign={textAlign}
          id={id}
          type={type || "text"}
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setIsTouched(true);
          }}
          borderRadius={14}
          border=""
          fontSize=".8rem"
          placeholder={placeholder}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          _autofill={{
            border: "1px solid",
            borderColor: "hsl(0, 0%, 80%)",
            backgroundColor: "#191919",
            color: "#8A8A8A",
          }}
          _active={{
            border: "1px solid",
            borderColor: "hsl(0, 0%, 80%)",
            backgroundColor: "#191919",
            color: "#8A8A8A",
          }}
          _disabled={{
            backgroundColor: "#000000",
            border: "solid #323232 2px",
            opacity: 0.8,
            color: "#FFF",
          }}
        />

        {activeRightButton && (
          <InputRightElement width="4.5rem">
            <IconButton
              onClick={handleClickRightButton}
              colorScheme="defaultTheme"
              variant="ghost"
              icon={FaSave}
              _active={{
                border: "none",
              }}
              _hover={{
                color: "green.200",
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </FormGroup>
  );
}

FieldInputTextArea.propTypes = propTypes;
FieldInputTextArea.defaultProps = defaultProps;
