import React, { useState } from "react";
import {
  HStack,
  Stack,
  Text,
  AccordionItem,
  Accordion,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  StackDivider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "context/lang-context";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import { useAuthentificationContext } from "context/auth-context";
import { ButtonWithIco } from "components/Buttons/ButtonWithIco";
import { BsTranslate } from "react-icons/bs";
import IconDoubleMains from "components/Icons/icons-generated/IconDoubleMains";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useToggleFavoris } from "../services/useToggleFavoris";
import { PopoverDon } from "./PopoverDon";
import { useTranslateDon } from "../services/useTranslateDon";
import { ModalCommentaire } from "./ModalCommentaire";

const CardDonMobile = React.memo(({ don, user }) => {
  const colorDon = "#36A9E1";
  const isMyDon = user?.id === don?.usager?.id;
  const { mutate: toggleFavoris } = useToggleFavoris();
  const navigate = useNavigate();
  const [description, setDescription] = useState(don.dons.description);
  const [nom, setNom] = useState(don.dons.nom);
  const { t } = useTranslation();

  const handleSuccess = (data) => {
    const { text, type } = data;
    if (type === "description") {
      setDescription(text);
    } else {
      setNom(text);
    }
  };

  const { mutate: translateDon } = useTranslateDon({
    onSuccess: handleSuccess,
  });

  const { lang } = useLanguageContext();
  const { user: authenticatedUser } = useAuthentificationContext();

  const handleClick = () => {
    if (don.dons.natDon === "INTERNATIONAL") {
      if (description === don.dons.description) {
        translateDon({
          text: don.dons.description,
          targetLang: lang,
          type: "description",
        });
        translateDon({
          text: don.dons.nom,
          targetLang: lang,
          type: "nom",
        });
      } else {
        setDescription(don.dons.description);
        setNom(don.dons.nom);
      }
    }
    return null;
  };

  return (
    <Stack w="90vw" h="100%" border={`${colorDon} inset 1px`} spacing={0} borderRadius="10px">
      <Accordion allowToggle allowMultiple>
        <AccordionItem border="none" borderRadius="10px">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                _expanded={{ bg: colorDon, color: "black" }}
                w="100%"
                borderTopRadius="10px"
              >
                <HStack textAlign="start" alignItems="center" w="100%">
                  <Stack flex={1} overflow="hidden">
                    <HStack>
                      <HStack>
                        <Text
                          fontSize="20px"
                          fontWeight="bold"
                          color={isExpanded ? "black" : colorDon}
                        >
                          {don?.dons?.quantite}
                        </Text>
                        <IconEnergieSansPlus h="30px" color={isExpanded ? "black" : colorDon} />
                      </HStack>
                      <Text
                        overflow="auto"
                        fontSize="20px"
                        fontWeight="bold"
                        color={isExpanded ? "black" : colorDon}
                      >
                        {nom}
                      </Text>
                    </HStack>

                    <Text fontSize="12px" color={isExpanded ? "black" : colorDon}>
                      {don?.dons?.reference}
                    </Text>
                  </Stack>

                  <AccordionIcon color={isExpanded ? "black" : colorDon} />
                </HStack>
              </AccordionButton>
              <AccordionPanel
                borderBottomRadius="10px"
                w="100%"
                bg={isExpanded ? colorDon : "black"}
              >
                <Stack
                  borderTop="solid 1px"
                  borderColor={isExpanded ? "black" : "#B0B0B066"}
                  divider={
                    <StackDivider h="1px" borderColor={isExpanded ? "black" : "#B0B0B066"} />
                  }
                >
                  <Text fontSize="20px" fontWeight="bold" color={isExpanded ? "black" : colorDon}>
                    {description}
                  </Text>

                  <HStack
                    divider={
                      <StackDivider w="1px" borderColor={isExpanded ? "black" : "#B0B0B066"} />
                    }
                  >
                    <Text fontSize="13px" fontWeight="bold" color={isExpanded ? "black" : colorDon}>
                      {don?.dons?.natDon}
                    </Text>
                    <Text fontSize="13px" fontWeight="bold" color={isExpanded ? "black" : colorDon}>
                      {don?.dons?.frequenceDon}
                    </Text>
                    <Text fontSize="13px" fontWeight="bold" color={isExpanded ? "black" : colorDon}>
                      {don?.dons?.typeDon}
                    </Text>
                  </HStack>
                  {don?.dons?.lieuEchange ? (
                    <Text fontSize="13px" fontWeight="bold" color={isExpanded ? "black" : colorDon}>
                      {don?.dons?.lieuEchange}
                    </Text>
                  ) : null}

                  <Stack alignItems="center">
                    {don.dons.natDon === "INTERNATIONAL" &&
                    don.usager.paysId !== authenticatedUser.paysId ? (
                      <ButtonWithIco
                        className="bg"
                        onClick={handleClick}
                        textTransform="uppercase"
                        bg={colorDon}
                        color="black"
                        _active={{
                          bg: colorDon,
                        }}
                        _hover={{
                          bg: colorDon,
                        }}
                        fontSize="12px"
                        minH="16px"
                        lineHeight="14px"
                        text={t("dons:translate")}
                        icon={<BsTranslate fill="black" stroke="black" />}
                      />
                    ) : null}
                    <HStack spacing={2} h="100%" justifyContent="center">
                      {!isMyDon ? (
                        <ButtonWithIco
                          onClick={() => {
                            navigate({ pathname: `/donsEnergie`, search: `?donId=${don.dons.id}` });
                          }}
                          className="bg"
                          fontSize="12px"
                          lineHeight="14px"
                          minH="16px"
                          textTransform="uppercase"
                          bg={colorDon}
                          color="black"
                          _active={{
                            bg: colorDon,
                          }}
                          _hover={{
                            bg: colorDon,
                          }}
                          text={t("dons:faireUnDon")}
                          icon={<IconDoubleMains fill="black" stroke="black" />}
                        />
                      ) : null}
                      <ButtonWithIco
                        onClick={() => toggleFavoris(don.dons)}
                        bg={colorDon}
                        className="bg"
                        _active={{
                          bg: colorDon,
                        }}
                        _hover={{
                          bg: colorDon,
                        }}
                        color="black"
                        minH="16px"
                        fontSize="12px"
                        lineHeight="14px"
                        iconSpacing={0}
                        icon={don.favoris.id ? <FaStar fill="black" /> : <FaRegStar fill="black" />}
                      />
                      <PopoverDon
                        isMyDon={isMyDon}
                        user={user}
                        don={don}
                        colorDon={colorDon}
                        colorButton={colorDon}
                      />
                    </HStack>
                    <ModalCommentaire don={don} />
                  </Stack>
                </Stack>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Stack>
  );
});
CardDonMobile.propTypes = {
  don: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
};
export { CardDonMobile };
