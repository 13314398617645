export const IconButton = {
  baseStyle: {
    // padding: "0.4em",
    // borderRadius: "md",
    // border: "1px solid red",
    // backgroundColor: "red.500",
  },
  sizes: {
    sm: {},
  },
  variants: {
    // default: {
    //   borderColor: "primary.gray",
    //   color: "red.500",
    // },
  },
  defaultProps: {
    // size: "sm",
    // variant: "default",
  },
};
