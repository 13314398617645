import React, { useState } from "react";
import { HStack, Stack, Text, StackDivider, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FaRegStar, FaStar } from "react-icons/fa";
import { BsTranslate } from "react-icons/bs";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import { useAuthentificationContext } from "context/auth-context";
import { useLanguageContext } from "context/lang-context";
import { ButtonWithIco } from "components/Buttons/ButtonWithIco";
import IconDoubleMains from "components/Icons/icons-generated/IconDoubleMains";
import { useToggleFavoris } from "../services/useToggleFavoris";
import { PopoverDon } from "./PopoverDon";
import { useTranslateDon } from "../services/useTranslateDon";
import { ModalCommentaire } from "./ModalCommentaire";

const CardDon = React.memo(({ don, user }) => {
  const colorDon = "#36A9E1";
  const { t } = useTranslation();
  const { mutate: toggleFavoris } = useToggleFavoris();
  const isMyDon = user?.id === don?.usager?.id;
  const navigate = useNavigate();
  const [description, setDescription] = useState(don.dons.description);
  const [nom, setNom] = useState(don.dons.nom);
  const [translated, setTranslated] = useState(false);

  const handleSuccess = (data) => {
    const { text, type } = data;
    if (type === "description") {
      setDescription(text);
    } else {
      setNom(text);
    }
  };

  const { mutate: translateDon } = useTranslateDon({
    onSuccess: handleSuccess,
  });

  const { lang } = useLanguageContext();
  const { user: authenticatedUser } = useAuthentificationContext();

  const handleClick = () => {
    if (don.dons.natDon === "INTERNATIONAL") {
      setTranslated((old) => !old);
      if (description === don.dons.description) {
        translateDon({
          text: don.dons.description,
          targetLang: lang,
          type: "description",
        });
        translateDon({
          text: don.dons.nom,
          targetLang: lang,
          type: "nom",
        });
      } else {
        setDescription(don.dons.description);
        setNom(don.dons.nom);
      }
    }
    return null;
  };

  return (
    <HStack
      w="100%"
      h={180}
      border={`${colorDon} inset 1px`}
      borderRadius="10px"
      p={4}
      gap={2}
      divider={<StackDivider w="1px" borderColor="#B0B0B066" />}
    >
      <Stack flex={2} h="100%" divider={<StackDivider h="1px" borderColor="#B0B0B066" />}>
        <Stack>
          <Text fontSize="12px" color="#5A5A5A">
            {t("dons:reference")}
          </Text>
          <Text fontSize="12px" color={colorDon}>
            {don?.dons?.reference}
          </Text>
        </Stack>
        <Stack overflow="hidden" maxWidth="240px" minW="200px">
          <Tooltip label={nom}>
            <Text
              fontSize="20px"
              fontWeight="bold"
              color="#B0B0B0"
              sx={{
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "7",
              }}
              display="-webkit-box"
              whiteSpace="normal"
            >
              {nom}
            </Text>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack height="100%" overflow="hidden" minW="300px" maxWidth="370px" flex={3}>
        <Tooltip label={description}>
          <Text
            fontSize="14px"
            sx={{
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "3",
            }}
            display="-webkit-box"
            whiteSpace="normal"
            color="#B0B0B0"
          >
            {description}
          </Text>
        </Tooltip>
      </Stack>
      <HStack h="100%" justifyContent="end" flex={1}>
        <Text fontSize="20px" fontWeight="bold" color={colorDon}>
          {don?.dons?.quantite}
        </Text>
        <IconEnergieSansPlus color={colorDon} />
      </HStack>
      <Stack flex={1}>
        <Text fontSize="14px" fontWeight="bold" color={colorDon}>
          {don?.dons?.typeDon}
        </Text>
        <Text fontSize="14px" fontWeight="bold" color={colorDon}>
          {don?.dons?.frequenceDon}
        </Text>
        <Text fontSize="14px" fontWeight="bold" color={colorDon}>
          {don?.dons?.natDon}
        </Text>
      </Stack>
      <Stack>
        {don?.dons?.lieuEchange ? (
          <Text fontSize="14px" fontWeight="bold" color={colorDon}>
            {don?.dons?.lieuEchange}
          </Text>
        ) : null}
      </Stack>
      <Stack spacing={4} flex={1} h="100%" justifyContent="center" alignItems="center">
        {don.dons.natDon === "INTERNATIONAL" && don.usager.paysId !== authenticatedUser.paysId ? (
          <ButtonWithIco
            fontSize="12px"
            minH="16px"
            className="bg"
            _active={{
              bg: colorDon,
            }}
            _hover={{
              bg: colorDon,
            }}
            lineHeight="14px"
            onClick={handleClick}
            textTransform="uppercase"
            color={colorDon}
            text={t("dons:translate")}
            icon={<BsTranslate />}
          />
        ) : null}

        <ModalCommentaire don={don} />
        {!isMyDon ? (
          <ButtonWithIco
            onClick={() => {
              navigate({
                pathname: `/donsEnergie`,
                search: translated
                  ? `?donId=${don.dons.id}&description=${description}&nom=${nom}`
                  : `?donId=${don.dons.id}`,
              });
            }}
            fontSize="12px"
            lineHeight="14px"
            minH="16px"
            className="bg"
            _active={{
              bg: colorDon,
            }}
            _hover={{
              bg: colorDon,
            }}
            textTransform="uppercase"
            color={colorDon}
            text={t("dons:faireUnDon")}
            icon={<IconDoubleMains color={colorDon} />}
          />
        ) : null}

        {/* <ButtonWithIcoNoBord
          onClick={() => navigate({ pathname: `/donsEnergie`, search: `?donId=${don.dons.id}` })}
          color={colorDon}
          isDisabled={isMyDon}
          icon={<IconFaireUnDon h={15} fill={colorDon} stroke={colorDon} />}
        /> */}
        <HStack>
          <ButtonWithIco
            onClick={() => toggleFavoris(don.dons)}
            color={colorDon}
            className="bg"
            _active={{
              bg: colorDon,
            }}
            _hover={{
              bg: colorDon,
            }}
            minH="16px"
            fontSize="12px"
            lineHeight="14px"
            iconSpacing={0}
            icon={don.favoris.id ? <FaStar /> : <FaRegStar />}
          />
          <PopoverDon
            isMyDon={isMyDon}
            user={user}
            don={don}
            colorDon={colorDon}
            colorButton={colorDon}
          />
        </HStack>
      </Stack>
    </HStack>
  );
});
CardDon.propTypes = {
  don: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
};

export { CardDon };
