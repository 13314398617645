import React, { useEffect } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { useApp } from "context/App/useApp";
import { Header } from "components/Layout/Header";
import { Footer } from "components/Layout/Footer";
import { FormEnvoyerDonsEdit } from "./_partials/FormEnvoyerDonsEdit";

export function PageEnvoyerDonEdit() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover } = useApp();

  useEffect(() => {
    setPageColor("#E71D73");
    setBackgroundColorNotif("#E71D73");
    setColorNotif("#FFFFFF");
    setColorNotifHover("#000000");
  }, [setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover]);
  return (
    <SlideIn>
      <Header />
      <Box p={4} w={isMobile ? "100%" : "70%"} mx="auto">
        <FormEnvoyerDonsEdit />
      </Box>
      <Footer />
    </SlideIn>
  );
}
