import { useTranslation } from "react-i18next";

export const GetDonsStatut = ({don, user}) => {
    const { t } = useTranslation();
    if(don.dons.statusDon === "RECU" && don.usager.id === user.id) {
        return t(`dons:statut.NOTIFIE`);
    }
    if(don.dons.statusDon === "ACCEPTE" && don.usager.id === user.id) {
        return t(`dons:statut.ENVOYE`);
    }
    return t(`dons:statut.${don.dons.statusDon}`);
};
