/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconDownload({ stroke = "#36A9E1", fill = "#36A9E1", ...rest }) {
  return (
    <chakra.svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.5586 4.41748L8.48628 8.98167L4.00673 4.41748"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 1C9 0.723858 8.77614 0.5 8.5 0.5C8.22386 0.5 8 0.723858 8 1H9ZM1.5 7C1.5 6.72386 1.27614 6.5 1 6.5C0.723858 6.5 0.5 6.72386 0.5 7H1.5ZM1 13.5H0.5C0.5 13.7761 0.723858 14 1 14V13.5ZM15.5 13.5V14C15.7761 14 16 13.7761 16 13.5H15.5ZM16 7C16 6.72386 15.7761 6.5 15.5 6.5C15.2239 6.5 15 6.72386 15 7H16ZM9 9V1H8V9H9ZM0.5 7V13.5H1.5V7H0.5ZM1 14H15.5V13H1V14ZM16 13.5V7H15V13.5H16Z"
        fill={fill}
      />
    </chakra.svg>
  );
}
IconDownload.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconDownload;
