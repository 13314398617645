import {useToast} from "@chakra-ui/react";
import Axios from "axios";
import {MyToast} from "components/Toast/MyToast";
import {APPENV} from "config/config";
import {useTranslation} from "react-i18next";
import {useMutation,} from "react-query";

export const useCreateCommentaire = ({id, handleSuccess}) => {
    const toast = useToast()
    const {t} = useTranslation();
    return useMutation(
        (data) =>
            Axios.post(
                `${APPENV.HOST_RB_API}/dons/${id}/messages`,
                data
            ),
        {
            onError: (error) => {
                let descriptionError = "messagerie:errors.createText"
                if (error?.response?.request.response) {
                    const errorObj = JSON.parse(error?.response?.request.response);
                    if (
                        errorObj.statusCode === 501 &&
                        errorObj?.error?.type === "AppException"
                    ) {
                        descriptionError = errorObj?.error?.description;
                    }
                }
                toast({
                    duration: 9000,
                    position: "top",
                    render: () => (
                        <MyToast status="error" backgroundColor="#36A9E1" color="white" title={t('messagerie:errors.create')} text={t(descriptionError)}/>
                    ),
                });
            },
            onSuccess: async ({data}) => {
                toast({
                    duration: 9000,
                    position: "top",
                    render: () => (
                        <MyToast status="success" backgroundColor="#36A9E1" color="white" title={t('messagerie:success.create')} text={t('messagerie:success.createText')}/>
                    ),
                });
                await handleSuccess(data);

            },
        }
    );

};
