import Axios from "axios";
import { APPENV } from "config/config";
import { useQuery } from "react-query";

export const useGetNotifications = () => {
  const {data, isLoading, isFetching } = useQuery('notifications',
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/notifications`
      ),
      {
        refetchOnMount: false,
        refetchInterval: 5000,
      }
  );

  const notifications = data?.data ?? null;
  return {notifications, isLoading, isFetching};
}

