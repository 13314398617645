import React from "react";
import PropTypes from "prop-types";
import { FieldInput } from "./FieldInput";
import { FieldInputTextArea } from "./FieldInputTextArea";
import { FieldReactSelect } from "./FieldReactSelect";
import { FieldDatePicker } from "./FieldDatePicker";
import { PinInputCustom } from "./PinInputCustom";
import { FieldDateTimePicker } from "./FieldDateTimePicker";
import { FieldPhone } from "./FieldPhone";

const propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  type: "text",
  children: null,
};

export function FieldFactory(props) {
  const { type, children, ...rest } = props;
  if (type === "number") {
    return (
      <FieldInput inputMode="numeric" pattern="\d*" isNumeric {...rest}>
        {children}
      </FieldInput>
    );
  }

  if (type === "phone") {
    return <FieldPhone {...rest}>{children}</FieldPhone>;
  }
  if (type === "text") {
    /* Les props de style lié à l'input doivent etre passés dans un objet inputProps={{width:50px, height: 50px ...}} */
    /*
    inputProps={{
        maxLength: 50
        setState: () => {}
    }}
    readonly et horizontal ne sont pas des props du input donc passés ç coté
    isReadOnly => si readonly alors LabelValue,
    horizontal => pour le labelValue si readonly,
    */
    return <FieldInput {...rest}>{children}</FieldInput>;
  }
  if (type === "pin") {
    return <PinInputCustom {...rest}>{children}</PinInputCustom>;
  }
  if (type === "password") {
    return (
      <FieldInput type="password" {...rest}>
        {children}
      </FieldInput>
    );
  }
  if (type === "date") {
    return <FieldDatePicker {...rest} />;
    /*    return (
      <FieldInput
        type="datetime-local"
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
        {...rest}
      >
        {children}
      </FieldInput>
    ); */
  }
  if (type === "datetime") {
    return <FieldDateTimePicker {...rest} />;
    /*    return (
      <FieldInput
        type="datetime-local"
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
        {...rest}
      >
        {children}
      </FieldInput>
    ); */
  }

  if (type === "textarea") {
    /*
    inputProps={{
        textAlign: "center"
    }}
    isReadOnly => si readonly alors LabelValue,
    horizontal => pour le labelValue si readonly,
    */
    return <FieldInputTextArea {...rest}>{children}</FieldInputTextArea>;
  }

  if (type === "select") {
    /*
        options => liste des options [{label:label, value:value}],
        isMulti => autorise le multi choix,
        closeMenuOnSelect => ferme la liste au choix,
        isReadOnly => si readonly alors LabelValue,
        horizontal => pour le labelValue si readonly,
        isLoading,
        setParentValue => callback onchange,
    */
    return <FieldReactSelect {...rest}>{children}</FieldReactSelect>;
  }

  return null;
}

FieldFactory.propTypes = propTypes;
FieldFactory.defaultProps = defaultProps;
