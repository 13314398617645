import Axios from "axios";
import { APPENV } from "config/config";
import { useQuery } from "react-query";

export const useGetUsers = ({params}) => {
  const {data, isLoading, isFetching } = useQuery(['users', params],
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/users`, {
          params: new URLSearchParams(params)
        }
      ),
  );

  const users = data?.data ?? null;
  return {users, isLoading, isFetching};
}

