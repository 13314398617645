/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconEmail({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_296_3605)">
        <path
          d="M20.5828 7.80399V2.22626C20.5828 1.00423 19.6151 0 18.4113 0H2.17158C0.967771 0 0 0.992136 0 2.22626V13.3333C0 14.5554 0.967771 15.5596 2.17158 15.5596H14.3159C15.0241 18.1246 17.3255 20 20.0399 20C21.0903 20 22.1171 19.7217 23.0259 19.1773C23.2855 19.02 23.3681 18.6812 23.2265 18.415C23.0731 18.1488 22.7426 18.052 22.483 18.2093C21.7512 18.6449 20.9015 18.8748 20.0399 18.8748C17.3491 18.8748 15.1657 16.6364 15.1657 13.8778C15.1657 11.1192 17.3491 8.88082 20.0399 8.88082C22.7308 8.88082 24.9142 11.1192 24.9142 13.8778V14.4344C24.9142 15.0514 24.4303 15.5475 23.8284 15.5475C23.2265 15.5475 22.7426 15.0514 22.7426 14.4344V12.2081C22.7426 11.9056 22.4948 11.6515 22.1997 11.6515C22.0581 11.6515 21.9283 11.712 21.8221 11.8088C21.3382 11.3733 20.7245 11.095 20.0281 11.095C18.5293 11.095 17.3137 12.3412 17.3137 13.8778C17.3137 15.4144 18.5293 16.6606 20.0281 16.6606C20.8307 16.6606 21.5506 16.2855 22.0463 15.7169C22.4358 16.2855 23.0849 16.6606 23.8166 16.6606C25.0086 16.6606 25.9882 15.6685 25.9882 14.4344V13.8778C25.9882 10.6957 23.6042 8.08227 20.571 7.79189L20.5828 7.80399ZM2.17158 1.11313H18.4231C18.4231 1.11313 18.4703 1.11313 18.4821 1.12523L10.7399 7.6467C10.4685 7.82819 10.0672 7.79189 9.89015 7.6709L2.10077 1.12523C2.10077 1.12523 2.13618 1.11313 2.15978 1.11313H2.17158ZM19.5089 7.80399C16.4757 8.08227 14.0917 10.7078 14.0917 13.8899C14.0917 14.0714 14.0917 14.265 14.1153 14.4465H2.17158C1.56968 14.4465 1.08579 13.9504 1.08579 13.3333V2.22626C1.08579 2.06897 1.1212 1.92377 1.18021 1.79068L9.25284 8.57834C9.57149 8.78403 9.92556 8.90502 10.3032 8.90502C10.6809 8.90502 11.0113 8.79613 11.3182 8.60254C11.3536 8.59044 11.3772 8.56624 11.4008 8.54204L19.4144 1.79068C19.4734 1.92377 19.5089 2.08106 19.5089 2.23835V7.81609V7.80399ZM20.0517 15.5475C19.1548 15.5475 18.4231 14.7973 18.4231 13.8778C18.4231 12.9583 19.1548 12.2081 20.0517 12.2081C20.9487 12.2081 21.6804 12.9583 21.6804 13.8778C21.6804 14.7973 20.9487 15.5475 20.0517 15.5475Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_296_3605">
          <rect width="26" height="20" fill={color} />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
IconEmail.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconEmail;
