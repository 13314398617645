import React, { useEffect } from "react";
import { Spinner, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { Header } from "components/Layout/Header";
import { useTranslation } from "react-i18next";
import { Footer } from "components/Layout/Footer";
import { useApp } from "context/App/useApp";
import { useGetCollectifs } from "./services/useGetCollectifs";
import { CardCollectif } from "./_partials/CardCollectif";

export function PageCollectifs() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  // remplacer par params pour filter coter backend
  const { collectifs, isLoading: isLoadingCollectifs } = useGetCollectifs();
  const { t } = useTranslation();
  const { pageColor, setPageColor, setBackgroundColorNotif, setColorNotif, setColorNotifHover } =
    useApp();

  useEffect(() => {
    setPageColor("#36A9E1");
    setBackgroundColorNotif("#36A9E1");
    setColorNotif("#FFFFFF");
    setColorNotifHover("#000000");
  }, [setPageColor, setBackgroundColorNotif, setColorNotifHover, setColorNotif]);

  return (
    <SlideIn>
      <Header />

      <Stack mx="auto" minW={isMobile ? "100%" : "500px"} p={isMobile ? 4 : 4}>
        {isLoadingCollectifs ? (
          <Spinner />
        ) : (
          <Stack mb={8} spacing={8} alignItems="center">
            <Stack alignItems="center">
              <Text textTransform="uppercase" fontSize="24px" color={pageColor}>
                {t("collectifs:title")}
              </Text>
            </Stack>

            {collectifs?.length === 0 ? (
              <Stack
                justifyContent="center"
                width="100%"
                border={`${pageColor} inset 1px`}
                minHeight="180px"
                textAlign="center"
                borderRadius="10px"
                alignContent="center"
              >
                <Text color="#FFF" fontSize="16px">
                  {t("collectifs:notfound")}
                </Text>
              </Stack>
            ) : (
              <Stack w="100%" spacing={8}>
                {collectifs?.map((collectif) => (
                  <CardCollectif key={collectif?.collectif?.id} collectif={collectif} />
                ))}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
      <Footer />
    </SlideIn>
  );
}
