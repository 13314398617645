import React from "react";
import PropTypes from "prop-types";
import IconCopier from "components/Icons/icons-generated/IconCopier";
import { IconButton, useToast } from "@chakra-ui/react";
import CopyToClipboard from "react-copy-to-clipboard";

const propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  color: PropTypes.string,
  copyText: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
  isDisabled: false,
  color: "white",
  className: "",
  copyText: "",
};

export function ButtonsCopy(props) {
  const toast = useToast();
  const {
    copyText,
    color,
    isLoading = false,
    isDisabled = false,
    onMouseEnter,
    onMouseLeave,
    onClick,
    className,
    ...rest
  } = props;

  return (
    <CopyToClipboard
      text={copyText}
      onCopy={() =>
        toast({
          status: "success",
          duration: 2000,
          position: "top",
          description: copyText,
        })
      }
    >
      <IconButton
        _hover={{
          backgroundColor: "transparent",
        }}
        color={color}
        isDisabled={isDisabled}
        isLoading={isLoading}
        background="transparent"
        border="none"
        w="50px"
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        minH="50px"
        icon={<IconCopier cursor="pointer" color={color} {...rest} />}
      />
    </CopyToClipboard>
  );
}
ButtonsCopy.propTypes = propTypes;
ButtonsCopy.defaultProps = defaultProps;
