/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconLoupeDashboard({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_521_409)">
        <path
          d="M25.7536 24.5853L19.0408 17.9796C20.7987 16.0696 21.8787 13.544 21.8787 10.7648C21.8778 4.81927 16.9803 0 10.9387 0C4.89709 0 0 4.81927 0 10.7648C0 16.7102 4.89752 21.5295 10.9391 21.5295C13.5497 21.5295 15.9437 20.6268 17.8244 19.1254L24.5632 25.757C24.8916 26.0803 25.4248 26.0803 25.7532 25.757C26.0824 25.4337 26.0824 24.9086 25.754 24.5849L25.7536 24.5853ZM10.9387 19.8735C5.82663 19.8735 1.68278 15.7956 1.68278 10.7648C1.68278 5.73393 5.82706 1.65641 10.9391 1.65641C16.0512 1.65641 20.195 5.73435 20.195 10.7652C20.195 15.796 16.0512 19.874 10.9391 19.874L10.9387 19.8735Z"
          fill={color}
        />
        <path
          d="M10.9391 18.2885C10.8198 18.2885 10.7008 18.2575 10.5938 18.1959L4.6762 14.7794C4.46251 14.6562 4.33081 14.4281 4.33081 14.1812V7.34828C4.33081 7.10146 4.46251 6.87332 4.6762 6.75012L10.5938 3.33364C10.8074 3.21044 11.0708 3.21044 11.2845 3.33364L17.2021 6.75012C17.4158 6.87332 17.5475 7.10146 17.5475 7.34828V14.1812C17.5475 14.4281 17.4158 14.6562 17.2021 14.7794L11.2845 18.1959C11.1775 18.2575 11.0585 18.2885 10.9391 18.2885ZM5.71238 13.7823L10.9391 16.7999L16.1659 13.7823V7.74677L10.9391 4.72921L5.71238 7.74677V13.7823Z"
          fill={color}
        />
        <path
          d="M10.3745 12.0333L10.3571 11.8073C10.3049 11.3383 10.4612 10.8255 10.8958 10.3042C11.2867 9.84373 11.5037 9.50514 11.5037 9.11429C11.5037 8.6712 11.2259 8.37594 10.6783 8.36702C10.3656 8.36702 10.0181 8.4711 9.801 8.63636L9.59241 8.08918C9.87917 7.88059 10.3741 7.74167 10.8346 7.74167C11.8338 7.74167 12.2854 8.35852 12.2854 9.01871C12.2854 9.60922 11.9553 10.0349 11.5382 10.5303C11.1558 10.9818 11.0169 11.3642 11.0428 11.8073L11.0513 12.0333H10.3737H10.3745ZM10.1834 13.2496C10.1834 12.928 10.4004 12.7024 10.7046 12.7024C11.0088 12.7024 11.217 12.9284 11.217 13.2496C11.217 13.5538 11.0173 13.7883 10.6957 13.7883C10.3915 13.7883 10.1834 13.5538 10.1834 13.2496Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_521_409">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
IconLoupeDashboard.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconLoupeDashboard;
