import React from "react";
import { HStack, Wrap, WrapItem } from "@chakra-ui/react";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { Formiz, useForm } from "@formiz/core";
import { useTranslation } from "react-i18next";
import { ButtonFilters } from "components/Buttons/ButtonFilters";
import { FieldFactory } from "components/fields/FieldFactory";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

export function SearchCardDesktop({
  handleClickFilters,
  handleSearchDon,
  isLoadingDons,
  selectedValue,
  pageColor,
  type,
  frequence,
  localisation,
}) {
  const { t } = useTranslation();
  const form = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDonsSearch = searchParams.get("donsId");
  return (
    <HStack spacing={8} justifyContent="space-between">
      <Wrap justify="center">
        {isDonsSearch ? (
          <Wrap>
            <WrapItem key={isDonsSearch}>
              <ButtonFilters
                _hover={{
                  borderColor: "white",
                  bgColor: "energieCyan",
                  color: "black",
                }}
                maxH="40px"
                onClick={() => {
                  searchParams.delete("donsId");
                  setSearchParams(searchParams);
                }}
                bgColor="energieCyan"
                color="black"
                text={t("messagerie:supprimerFiltre")}
              />
            </WrapItem>
          </Wrap>
        ) : null}
      </Wrap>

      <Wrap justify="center">
        {localisation.map((filtre) => (
          <WrapItem key={filtre.value}>
            <ButtonFilters
              _hover={{
                borderColor: "white",
                bgColor:
                  selectedValue.localisation === filtre.value && !isDonsSearch
                    ? "energieCyan"
                    : "black",
                color:
                  selectedValue.localisation === filtre.value && !isDonsSearch
                    ? "black"
                    : "energieCyan",
              }}
              maxH="40px"
              onClick={() => handleClickFilters("localisation", filtre)}
              bgColor={
                selectedValue.localisation === filtre.value && !isDonsSearch
                  ? "energieCyan"
                  : "black"
              }
              color={
                selectedValue.localisation === filtre.value && !isDonsSearch
                  ? "black"
                  : "energieCyan"
              }
              text={filtre.label}
            />
          </WrapItem>
        ))}
      </Wrap>
      <Wrap>
        {type.map((filtre) => (
          <WrapItem key={filtre.value}>
            <ButtonFilters
              _hover={{
                borderColor: "white",
                bgColor:
                  selectedValue.type === filtre.value && !isDonsSearch ? "energieCyan" : "black",
                color:
                  selectedValue.type === filtre.value && !isDonsSearch ? "black" : "energieCyan",
              }}
              maxH="40px"
              onClick={() => handleClickFilters("type", filtre)}
              bgColor={
                selectedValue.type === filtre.value && !isDonsSearch ? "energieCyan" : "black"
              }
              color={selectedValue.type === filtre.value && !isDonsSearch ? "black" : "energieCyan"}
              text={filtre.label}
            />
          </WrapItem>
        ))}
      </Wrap>
      <Wrap justify="center">
        {frequence.map((filtre) => (
          <WrapItem key={filtre.value}>
            <ButtonFilters
              _hover={{
                borderColor: "white",
                bgColor:
                  selectedValue.frequence === filtre.value && !isDonsSearch
                    ? "energieCyan"
                    : "black",
                color:
                  selectedValue.frequence === filtre.value && !isDonsSearch
                    ? "black"
                    : "energieCyan",
              }}
              maxH="40px"
              onClick={() => handleClickFilters("frequence", filtre)}
              bgColor={
                selectedValue.frequence === filtre.value && !isDonsSearch ? "energieCyan" : "black"
              }
              color={
                selectedValue.frequence === filtre.value && !isDonsSearch ? "black" : "energieCyan"
              }
              text={filtre.label}
            />
          </WrapItem>
        ))}
      </Wrap>
      <Formiz id="dons-form" autoForm onValidSubmit={handleSearchDon} connect={form}>
        <HStack>
          <FieldFactory
            type="text"
            textAlign="center"
            name="search"
            placeholder={`${t("dons:search")}`}
          />
          <ButtonsValider color={pageColor} onClick={form.submit} isDisabled={isLoadingDons} />
        </HStack>
      </Formiz>
    </HStack>
  );
}
SearchCardDesktop.propTypes = {
  type: PropTypes.instanceOf(Object).isRequired,
  localisation: PropTypes.instanceOf(Object).isRequired,
  frequence: PropTypes.instanceOf(Object).isRequired,
  handleClickFilters: PropTypes.func,
  handleSearchDon: PropTypes.func,
  isLoadingDons: PropTypes.bool,
  selectedValue: PropTypes.instanceOf(Object).isRequired,
  pageColor: PropTypes.string,
};
