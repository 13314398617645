import React from "react";
import { Badge, HStack, Stack, StackDivider, Text, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import "./CardHistorique.css";
import IconHistoTriangle from "components/Icons/icons-generated/IconHistoTriangle";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { ButtonsSupprimer } from "components/Buttons/ButtonsSupprimer";
import { ButtonsEditer } from "components/Buttons/ButtonsEditer";
import { FormatDate } from "functions/common-scope/utils";
import { useLanguageContext } from "context/lang-context";
import { useLocation, useNavigate } from "react-router";
import { useValiderDons } from "App/dons/services/useValiderDons";
import { useQueryClient } from "react-query";
import { GetDonsColor } from "App/dons/funtions/GetDonsColor";
import { useRefuserDons } from "App/dons/services/useRefuserDons";
import { GetDonsStatut } from "App/dons/funtions/GetDonsStatut";

const CardHistorique = React.memo(({ user, don }) => {
  const { t } = useTranslation();
  const isMyDon = user?.id === don?.usager?.id;
  const colorDon = isMyDon ? "#8CA0A0 " : "#36A9E1";
  const { lang } = useLanguageContext();
  const { state } = useLocation();
  const isSelected = Number(state?.id) === don?.dons?.id;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const onSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: ["dons"], exact: false });
  };
  const { mutate: validerDon } = useValiderDons({ onSuccess });
  const { mutate: refuserDon } = useRefuserDons({ onSuccess });

  const handleValider = () => {
    validerDon(don);
  };

  const handleRefuser = () => {
    refuserDon(don);
  };

  const handleModifier = () => {
    navigate({ pathname: `/donsEnergie/${don.dons.id}` });
  };

  return (
    <Stack>
      <HStack
        w="100%"
        background={isSelected ? "rgba(255,255,255,0.1)" : ""}
        border={isSelected ? `#FFFFFF66 inset 1px` : `${colorDon} inset 1px`}
        borderRadius="10px"
        h={200}
        spacing={0}
      >
        <HStack
          w={{ base: "100%", lg: 600 }}
          h="100%"
          p={4}
          alignItems="center"
          justifyContent="left"
          divider={<StackDivider border="1px solid #B0B0B066" />}
        >
          <Stack h="100%" flex={2} divider={<StackDivider border="1px solid #B0B0B066" />}>
            <Badge m={1} fontSize="12px" colorScheme={GetDonsColor(don.dons)} mr="auto">
              {GetDonsStatut({ don, user })}
            </Badge>
            <Stack>
              <Text fontSize="12px" color="#5A5A5A">
                {t("dons:reference")}
              </Text>
              <Text fontSize="12px" color={colorDon}>
                {don.dons.reference}
              </Text>
            </Stack>
            <Stack overflow="hidden" textOverflow="ellipsis" maxWidth="240px" minW="240px">
              <Tooltip label={t(don.dons.nom)}>
                <Text
                  fontSize="20px"
                  fontWeight="bold"
                  color="#B0B0B0"
                  sx={{
                    "-webkit-box-orient": "vertical",
                    "-webkit-line-clamp": "2",
                  }}
                  display="-webkit-box"
                  whiteSpace="normal"
                >
                  {t(don.dons.nom)}
                </Text>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack overflow="hidden" height="100%" minW="300px" maxWidth="370px" flex={3}>
            <Tooltip label={t(don.dons.description)}>
              <Text
                fontSize="14px"
                color="#B0B0B0"
                sx={{
                  "-webkit-box-orient": "vertical",
                  "-webkit-line-clamp": "8",
                }}
                display="-webkit-box"
                whiteSpace="normal"
                maxW={400}
              >
                {t(don.dons.description)}
              </Text>
            </Tooltip>
          </Stack>
        </HStack>
        <Stack
          p={2}
          backgroundColor={colorDon}
          h={200}
          w="120px"
          position="relative"
          spacing={0}
          justifyContent="space-between"
          textAlign="right"
        >
          <Text fontSize="14px" color="#000000" fontWeight={600} zIndex={2}>
            {FormatDate(don.dons.dateCreation, t("app:core.dateFormatDaysjs", lang))}
          </Text>
          <HStack color="#000000" zIndex={2} w="100%" justifyContent="end" position="relative">
            <Text fontSize="20px" fontWeight="bold">
              {isMyDon ? "-" : "+"} {don.dons.quantite}
            </Text>
            <IconEnergieSansPlus color="black" />
          </HStack>
          <Text fontSize="14px" fontWeight={600} color="#000000" zIndex={2}>
            {don.dons.frequenceDon}
          </Text>
          <IconHistoTriangle
            color={colorDon}
            margin={0}
            h="100%"
            top={0}
            position="absolute"
            right="-45px"
            transform="translate(0%, 0%);"
          />
        </Stack>
        <HStack
          minW="270px"
          flex={1}
          justifyContent="space-between"
          divider={<StackDivider border="1px solid #B0B0B066" />}
        >
          <Stack pl="40px">
            <Stack>
              <Text fontSize="12px" color="#5A5A5A">
                {t("dons:destinataire")}
              </Text>
              <Text fontSize="12px" color={colorDon}>
                {don.destinataire.clePublique}
              </Text>
            </Stack>
          </Stack>
          {don.dons.statusDon === "RECU" && (isMyDon || don?.destinataire?.id === user?.id) ? (
            <HStack>
              {isMyDon && don.dons.statusDon === "RECU" && isMyDon ? (
                <ButtonsEditer onClick={handleModifier} color={colorDon} h="30px" w="30px" />
              ) : null}
              {don?.dons?.statusDon === "RECU" && don?.destinataire?.id === user?.id ? (
                <>
                  <ButtonsSupprimer onClick={handleRefuser} color={colorDon} h="30px" w="30px" />
                  <ButtonsValider onClick={handleValider} color={colorDon} h="25px" w="25px" />
                </>
              ) : null}
            </HStack>
          ) : null}
        </HStack>
      </HStack>
    </Stack>
  );
});
CardHistorique.propTypes = {
  don: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

export { CardHistorique };
