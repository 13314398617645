import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  GridItem,
  HStack,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { isEmail } from "@formiz/validations";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import { Formiz, useForm } from "@formiz/core";
import { FormGroup } from "components/form-group";
import { useTranslation } from "react-i18next";
import { FieldFactory } from "components/fields/FieldFactory";
import { useGlobalEvents } from "hooks/useGlobalEvents";
import { useApp } from "context/App/useApp";
import { useGetPays } from "App/register/services/useGetPays";
import { ButtonsCopy } from "components/Buttons/ButtonsCopy";
import { useGetCollectifs } from "App/collectifs/services/useGetCollectifs";
import { useAuthentificationContext } from "context/auth-context";
import { ReactRadioInput } from "components/fields/ReactRadioInput";
import { ButtonsSupprimer } from "components/Buttons/ButtonsSupprimer";
import { ButtonsPause } from "components/Buttons/ButtonsPause";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { ModalCustom } from "components/Modal/Modal";
import { handleResearch } from "../../dons/services/useSearchLieu";
import { useUpdateUser } from "../services/useUpdateUser";
import { usePauseUser } from "../services/usePauseUser";
import { useDeleteUser } from "../services/useDeleteUser";

export function FormProfil() {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const loginForm = useForm({
    subscribe: "form",
  });
  const pauseForm = useForm({
    subscribe: "form",
  });
  const deleteForm = useForm({
    subscribe: "form",
  });
  const { pageColor = "#EA5B0C" } = useApp();
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const { isOpen: isOpenPause, onClose: onClosePause, onOpen: onOpenPause } = useDisclosure();
  const [hoverColor, setHoverColor] = useState({
    isHoverPause: false,
    isHoverValider: false,
    isHoverSupprimer: false,
  });
  useGlobalEvents({
    form: loginForm,
    type: "keypress",
    key: "Enter",
    action: "submitForm",
  });
  const { user, isLoading } = useAuthentificationContext();
  const { mutate: updateUser, isLoading: isLoadingUpdate } = useUpdateUser();
  const [selectedChoice, setSelectedChoices] = useState();
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setSelectedChoices({
        label: user?.lieuEchange,
        value: user?.lieuEchange,
        city: user?.ville,
      });

      if (user?.lieuEchange && user?.ville) {
        setChoices([
          {
            label: user?.lieuEchange,
            value: user?.lieuEchange,
            city: user?.ville,
          },
        ]);
      }
    }
  }, [isLoading, user]);

  const handleSuccessPause = () => {
    onClosePause();
  };

  const { mutate: pauseUser, isLoading: isLoadingPause } = usePauseUser({
    onSuccess: handleSuccessPause,
  });

  const handleSuccessDelete = () => {
    onCloseDelete();
    navigate("/logout");
  };

  const { mutate: deleteUser, isLoading: iseLoadingDelete } = useDeleteUser({
    onSuccess: handleSuccessDelete,
  });

  const { pays, isLoading: isLoadingPays } = useGetPays();
  const { collectifs, isLoading: isLoadingCollectifs } = useGetCollectifs();

  const [selectedCollectif, setSelectedCollectif] = useState();

  const handleRegister = (values) => {
    const datas = { ...values, lieu: selectedChoice };
    updateUser(datas);
  };

  const handlePause = (values) => {
    pauseUser(values);
  };

  const handleSupprimer = (values) => {
    deleteUser(values);
  };

  useEffect(() => {
    if (!isLoadingCollectifs) {
      setSelectedCollectif(collectifs?.filter((c) => c?.collectif?.id === user?.collectifId)?.[0]);
    }
  }, [collectifs, user, isLoadingCollectifs]);

  const handleChange = (val) => {
    setSelectedChoices(val);
  };

  if (isLoadingPays || isLoading) {
    return null;
  }

  const currentPays = pays?.filter((p) => p?.id === user?.paysId)?.[0] ?? null;
  return (
    <>
      <Formiz id="pause-form" autoForm onValidSubmit={handlePause} connect={pauseForm}>
        <ModalCustom
          title={
            !user?.estActif
              ? t("account:profil.pause.modalActifTitle")
              : t("account:profil.pause.modalTitle")
          }
          textDescription={
            !user?.estActif
              ? t("account:profil.pause.modalActifDescription")
              : t("account:profil.pause.modalDescription")
          }
          bgColor={pageColor}
          color="white"
          isOpen={isOpenPause}
          onClose={onClosePause}
          handleValider={pauseForm.submit}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          isLoading={isLoadingPause}
        >
          <Stack justifyContent="center" spacing={4} p={4}>
            <FieldFactory
              type="datetime"
              name="heurenaissanceConfirmPause"
              placeholder={`${t("app:core.form.placeholder.heure")}`}
              required={`${t("app:core.form.required.heure")}`}
            />
          </Stack>
        </ModalCustom>
      </Formiz>
      <Formiz id="delete-form" autoForm onValidSubmit={handleSupprimer} connect={deleteForm}>
        <ModalCustom
          title={t("account:profil.delete.modalTitle")}
          textDescription={t("account:profil.delete.modalDescription")}
          bgColor={pageColor}
          color="white"
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          handleValider={deleteForm.submit}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          isLoading={iseLoadingDelete}
        >
          <Stack justifyContent="center" spacing={4} p={4}>
            <FieldFactory
              type="datetime"
              name="heurenaissanceConfirmDelete"
              placeholder={`${t("app:core.form.placeholder.heure")}`}
              required={`${t("app:core.form.required.heure")}`}
            />
          </Stack>
        </ModalCustom>
      </Formiz>
      <Formiz id="register-form" autoForm onValidSubmit={handleRegister} connect={loginForm}>
        <Stack mb={8}>
          <Stack alignItems="center" mb={8}>
            <Text textTransform="uppercase" fontSize="24px" color="energieOrange.500">
              {t("account:profil.title")}
            </Text>
          </Stack>
          <Stack spacing={0}>
            <HStack
              p={1}
              alignItems="center"
              justifyContent="center"
              borderTopRadius="10px"
              backgroundColor={pageColor}
            >
              <Text fontSize="16px" color="energieBlack.500" fontWeight="bold">
                {user?.clePublique}
              </Text>
              <ButtonsCopy color="black" copyText={user?.clePublique ?? ""} />
            </HStack>
            <Stack p={1} background="rgba(234, 91, 12, 0.4)" position="relative">
              <HStack alignSelf="center" alignItems="center">
                <Text color="white">{`${t("account:energieDisponible")}`} </Text>
                <IconEnergieSansPlus color="white" />
                <Text color="white">{user?.energiesDisponible ?? 0} </Text>
              </HStack>
            </Stack>
          </Stack>
        </Stack>
        <Grid
          rowGap={8}
          templateAreas={[
            `"connexion" 
        "profil" 
        "contact"
        "stats" 
        "dons" 
        "maj"`,
            `
                  "connexion profil contact"
                  "stats dons maj"`,
          ]}
        >
          <GridItem area="connexion">
            <Stack spacing={2}>
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("account:infosObligatoire.title")}
              </Text>
              <Stack p={4} borderLeft="solid #EA5B0C 4px" spacing={4}>
                <ReactRadioInput
                  color={pageColor}
                  direction="column"
                  name="profil"
                  isDisabled
                  required={`${t("app:core.form.required.profil")}`}
                  defaultValue={user?.profil}
                  options={[
                    {
                      label: t("account:profil.particulier.label"),
                      value: t("account:profil.particulier.value"),
                    },
                    {
                      label: t("account:profil.collectif.label"),
                      value: t("account:profil.collectif.value"),
                    },
                    {
                      label: t("account:profil.entite.label"),
                      value: t("account:profil.entite.value"),
                    },
                  ]}
                />
                <FieldFactory
                  isDisabled
                  label={`${t("app:core.form.label.identite")}`}
                  type="text"
                  name="identite"
                  required={`${t("app:core.form.required.identite")}`}
                  placeholder={`${t("app:core.form.placeholder.identite")}`}
                  defaultValue={user?.identite}
                  capitalize
                />
                <FieldFactory
                  isDisabled
                  isLoading={isLoadingPays}
                  label={`${t("app:core.form.label.localisation")}`}
                  type="select"
                  name="localisation"
                  placeholder={`${t("app:core.form.placeholder.localisation")}`}
                  options={pays?.map((p) => ({
                    label: p.nom,
                    value: p.id,
                  }))}
                  required={`${t("app:core.form.required.localisation")}`}
                  defaultValue={user?.paysId}
                />
              </Stack>
            </Stack>
          </GridItem>

          <GridItem area="profil">
            <Stack spacing={2}>
              {isMobile ? <Divider /> : null}
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("account:donneesProfil.title")}
              </Text>
              <Stack p={4} borderLeft="solid #EA5B0C 4px" spacing={5}>
                <FieldFactory
                  key={selectedChoice}
                  type="select"
                  noOptionsMessage="Saisissez une adresse..."
                  name="lieuEchange"
                  label={`${t("app:core.form.label.lieuEchange")}`}
                  placeholder={`${t("app:core.form.placeholder.lieuEchange")}`}
                  options={choices}
                  onKeyUp={(e) => handleResearch({ e, setChoices })}
                  required={`${t("app:core.form.required.lieuEchange")}`}
                  setParentValue={handleChange}
                  defaultValue={`${user?.lieuEchange}`}
                  withObject
                />
                {!isLoadingCollectifs ? (
                  <FieldFactory
                    isDisabled={user?.statut === 2}
                    isLoading={isLoadingCollectifs}
                    label={`${t("collectifs:choixLabel")}`}
                    type="select"
                    defaultValue={user?.statut === 2 ? user.id : user.collectifId}
                    name="collectif"
                    setParentValue={(collec) =>
                      setSelectedCollectif(
                        collectifs?.filter((c) => c?.collectif?.id === collec)?.[0]
                      )
                    }
                    placeholder={`${t("collectifs:choixPlaceholder")}`}
                    options={collectifs?.map((c) => ({
                      label: c.collectif.identite,
                      value: c.collectif.id,
                    }))}
                  />
                ) : null}
                {user?.profil === t("account:profil.collectif.value") ? (
                  <FieldFactory
                    label={`${t("app:core.form.label.lienCollectif")}`}
                    type="text"
                    name="lienCollectif"
                    placeholder={`${t("app:core.form.placeholder.lienCollectif")}`}
                    defaultValue={user?.lienCollectif}
                  />
                ) : (
                  <FormGroup id="profil" label={t("app:core.form.label.lienCollectif")}>
                    <Link
                      maxW={300}
                      color="white"
                      textDecoration="underline"
                      href={selectedCollectif?.collectif?.lienCollectif}
                      isExternal
                    >
                      {t("app:core.lienSite")}
                    </Link>
                  </FormGroup>
                )}
              </Stack>
            </Stack>
          </GridItem>
          <GridItem area="contact">
            <Stack spacing={2}>
              {isMobile ? <Divider /> : null}
              <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
                {t("account:contact.title")}
              </Text>
              <Stack p={4} spacing={4} borderLeft="solid #EA5B0C 4px">
                <FieldFactory
                  type="text"
                  name="mail"
                  validations={[
                    {
                      rule: isEmail(),
                      message: "Le format n'est pas valide.",
                    },
                  ]}
                  placeholder={`${t("app:core.form.placeholder.mail")}`}
                  defaultValue={user?.mail}
                />
                <FieldFactory
                  country={currentPays?.codeIso ?? "FR"}
                  label={`${t("app:core.form.label.fixe")}`}
                  type="phone"
                  name="fixe"
                  placeholder={`${t("app:core.form.placeholder.fixe")}`}
                  defaultValue={user?.fixe}
                />
                <FieldFactory
                  country={currentPays?.codeIso ?? "FR"}
                  label={`${t("app:core.form.label.mobile")}`}
                  type="phone"
                  name="mobile"
                  placeholder={`${t("app:core.form.placeholder.mobile")}`}
                  defaultValue={user?.mobile}
                />
              </Stack>
            </Stack>
          </GridItem>
          <GridItem py={4} area="stats" borderTop="solid rgba(255, 255, 255, 0.4) 1px">
            <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
              {t("account:stats.title")}
            </Text>
            <Stack p={4} borderLeft="solid #EA5B0C 4px">
              <Text textTransform="uppercase" fontSize="16px" color="white">
                {t("account:stats.cumulRecues")}
              </Text>
              <HStack>
                <IconEnergieSansPlus color={pageColor} />
                <Text textTransform="uppercase" fontSize="14px" color="energieOrange.500">
                  {user?.energiesRecues}
                </Text>
              </HStack>
            </Stack>
            <Stack p={4} borderLeft="solid #EA5B0C 4px">
              <Text textTransform="uppercase" fontSize="16px" color="white">
                {t("account:stats.cumulEnvoye")}
              </Text>
              <HStack>
                <IconEnergieSansPlus color={pageColor} />
                <Text textTransform="uppercase" fontSize="14px" color="energieOrange.500">
                  {user?.energiesDonnees}
                </Text>
              </HStack>
            </Stack>
          </GridItem>
          <GridItem py={4} area="dons" borderTop="solid rgba(255, 255, 255, 0.4) 1px">
            <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
              {t("account:dons.title")}
            </Text>
            <Stack p={4} borderLeft="solid #EA5B0C 4px">
              <Text textTransform="uppercase" fontSize="16px" color="white">
                {t("account:dons.mensuelDon")}
              </Text>
              <Text textTransform="uppercase" fontSize="12px" color="energieOrange.500">
                {user?.refDonDeLaSource}
              </Text>
              <Text textTransform="uppercase" fontSize="12px" color="white">
                {t("account:dons.mensuelDonHelp")}
              </Text>
            </Stack>
            <Stack p={4} borderLeft="solid #EA5B0C 4px">
              <Text textTransform="uppercase" fontSize="16px" color="white">
                {t("account:dons.projetVie")}
              </Text>
              <Text textTransform="uppercase" fontSize="12px" color="energieOrange.500">
                {user?.refProjetDeVie}
              </Text>
              <Text textTransform="uppercase" fontSize="12px" color="white">
                {t("account:dons.projetVieHelp")}
              </Text>
            </Stack>
          </GridItem>
          <GridItem py={4} area="maj" borderTop="solid rgba(255, 255, 255, 0.4) 1px">
            <Text textTransform="uppercase" fontSize="18px" color="energieOrange.500">
              {t("account:etat.title")}
            </Text>
            <Stack p={4} borderLeft="solid #EA5B0C 4px">
              <Text textTransform="uppercase" fontSize="16px" color="white">
                {t("account:etat.majProfil")}
              </Text>
              <Text textTransform="uppercase" fontSize="12px" color="energieOrange.500">
                {user?.dateActualisation}
              </Text>
              <Text textTransform="uppercase" fontSize="12px" color="white">
                {t("account:statut")}
              </Text>
              <Text
                borderTop="solid #EA5B0C66 1px"
                textTransform="uppercase"
                fontSize="18px"
                color="energieOrange.500"
              >
                {user?.estActif ? t("account:etat.actif") : t("account:etat.absent")}
              </Text>
            </Stack>
          </GridItem>
        </Grid>
        <HStack
          borderTop="solid #EA5B0C 4px"
          alignItems="center"
          px={1}
          py={4}
          h="100%"
          justifyContent="space-between"
        >
          <ButtonsSupprimer
            minW="80px"
            onClick={onOpenDelete}
            isDisabled={iseLoadingDelete}
            isLoading={iseLoadingDelete}
            color={hoverColor.isHoverSupprimer ? "white" : pageColor}
            stroke={hoverColor.isHoverSupprimer ? "white" : pageColor}
            onMouseEnter={() => setHoverColor({ isHoverSupprimer: true })}
            onMouseLeave={() => setHoverColor({ isHoverSupprimer: false })}
          />

          <ButtonsPause
            minW="80px"
            onClick={onOpenPause}
            isDisabled={isLoadingPause}
            isLoading={isLoadingPause}
            color={hoverColor.isHoverPause ? "white" : pageColor}
            stroke={hoverColor.isHoverPause ? "white" : pageColor}
            onMouseEnter={() => setHoverColor({ isHoverPause: true })}
            onMouseLeave={() => setHoverColor({ isHoverPause: false })}
            isPause={!user?.estActif}
          />

          <ButtonsValider
            minW="80px"
            onClick={loginForm.submit}
            isDisabled={isLoadingUpdate}
            isLoading={isLoadingUpdate}
            color={hoverColor.isHoverValider ? "white" : pageColor}
            stroke={hoverColor.isHoverValider ? "white" : pageColor}
            onMouseEnter={() => setHoverColor({ isHoverValider: true })}
            onMouseLeave={() => setHoverColor({ isHoverValider: false })}
          />
        </HStack>
      </Formiz>
    </>
  );
}
