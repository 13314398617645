import React from "react";
import { HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import { RxCaretRight } from "react-icons/rx";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

export function Item({ color = "#fff", colorHover = "#000", text, to, onClose, ...rest }) {
  const navigate = useNavigate();
  const handleClick = () => {
    onClose();
    navigate(`${to}`, {
      replace: true,
    });
  };
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <HStack
      cursor="pointer"
      color={color}
      _hover={{ color: colorHover }}
      textTransform="uppercase"
      onClick={handleClick}
      {...rest}
    >
      <RxCaretRight fontSize={isMobile ? "22px" : "30px"} />
      <Text fontSize={isMobile ? "16px" : "16px"}>{text}</Text>
    </HStack>
  );
}
Item.propTypes = {
  onClose: PropTypes.func,
  to: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  text: PropTypes.string.isRequired,
};
