import React from "react";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";
import { PinInput, PinInputField } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FormGroup } from "components/form-group";
import "./css/input.css";

const propTypes = {
  inputProps: PropTypes.instanceOf(Object),
  isReadOnly: PropTypes.bool,
  horizontal: PropTypes.bool,
  leftElement: PropTypes.node,
  isNumeric: PropTypes.bool,
  ...fieldPropTypes,
};

const defaultProps = {
  inputProps: undefined,
  leftElement: null,
  horizontal: true,
  isReadOnly: false,
  isNumeric: false,
  ...fieldDefaultProps,
};

export function PinInputCustom(props) {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
  const {
    inputProps,
    label,
    type,
    required,
    isDisabled,
    placeholder,
    children,
    isReadOnly,
    horizontal,
    leftElement,
    activeRightButton,
    handleClickRightButton,
    isNumeric,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  // Props de l'input par défaut
  const defaultInputProps = {
    textAlign: "center",
    setState: () => null,
  };

  // Merge des props par défaut + custom
  const fullInputProps = {
    ...defaultInputProps,
    ...inputProps,
  };

  const { setState, handleBlur, ...otherInputProps } = fullInputProps;

  return (
    <FormGroup {...formGroupProps}>
      <PinInput
        id={id}
        type="number"
        value={value ?? ""}
        onChange={(e) => {
          setValue(e);
        }}
        onBlur={(e) => {
          setIsTouched(true);
          if (handleBlur) {
            handleBlur(e);
          }
        }}
        onKeyPress={(event) => {
          if (isNumeric) {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }
        }}
        placeholder={placeholder}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
        focusBorderColor="#191919"
        _autofill={{
          border: "1px solid",
          borderColor: "hsl(0, 0%, 80%)",
          backgroundColor: "#191919",
          color: "#8A8A8A",
        }}
        _active={{
          border: "1px solid",
          borderColor: "hsl(0, 0%, 80%)",
          backgroundColor: "#191919",
          color: "#8A8A8A",
        }}
        _disabled={{
          backgroundColor: "#000000",
          border: "solid #323232 2px",
          opacity: 0.8,
          color: "#FFF",
        }}
        {...otherInputProps}
      >
        <PinInputField color="#B0B0B0" mr={2} />
        <PinInputField color="#B0B0B0" mr={2} />
        <PinInputField color="#B0B0B0" mr={2} />
        <PinInputField color="#B0B0B0" />
      </PinInput>
    </FormGroup>
  );
}

PinInputCustom.propTypes = propTypes;
PinInputCustom.defaultProps = defaultProps;
