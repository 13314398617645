/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";
import { ReactSelectApps } from "components/react-select/ReactSelectApps";
import { FormGroup } from "components/form-group";
import { LabelValeur } from "./LabelValeur";

const propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.node,
    })
  ),
  isMulti: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  horizontal: PropTypes.bool,
  withObject: PropTypes.bool,
  inputProps: PropTypes.instanceOf(Object),
  ...fieldPropTypes,
};

const defaultProps = {
  noOptionsMessage: "Aucune option disponible",
  label: "",
  helper: "",
  withObject: false,
  isMulti: false,
  isDisabled: false,
  options: [],
  isReadOnly: false,
  isLoading: false,
  horizontal: true,
  inputProps: undefined,
  ...fieldDefaultProps,
};

// Ce composant est une surcouche de FieldReactSelect dans composant mais il introduit la traduction
export function FieldReactSelect(props) {
  const { t } = useTranslation();
  const { id, isValid, isSubmitted, resetKey, setValue, value, errorMessage } = useField(props);
  const {
    noOptionsMessage,
    inputProps,
    label,
    isDisabled,
    required,
    placeholder,
    options,
    isMulti,
    closeMenuOnSelect,
    isReadOnly,
    isLoading,
    horizontal,
    withObject,
    setParentValue,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => {
    let filtered = Array.isArray(e) ? e?.map((el) => el.value) : e.value;
    setValue(filtered);
    if (typeof setParentValue !== "undefined") {
      if (withObject) {
        filtered = Array.isArray(e) ? e?.map((el) => el) : e;
        setParentValue(filtered);
      } else {
        setParentValue(filtered);
      }
    }
  };

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  // let val = value;
  // if (!Array.isArray(value) && Object.prototype.toString.call(value) === "[object String]") {
  //   val = value?.split(",");
  // }

  if (isReadOnly) {
    return (
      <LabelValeur label={label} horizontal={horizontal}>
        {t(
          options.filter(
            ({ value: v, label: l, disabled: d }) => v.toString() === value?.toString()
          )[0]?.label
        )}
      </LabelValeur>
    );
  }

  const defaultInputProps = {
    textAlign: "left",
    height: "auto",
    maxLength: undefined,
    size: "lg",
    backgroundColor: "#191919",
    color: "#8A8A8A",
  };

  const fullInputProps = {
    ...defaultInputProps,
    ...inputProps,
  };

  return (
    <FormGroup {...formGroupProps}>
      <ReactSelectApps
        id={id}
        key={resetKey}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={handleChange}
        defaultValue={
          Array.isArray(value)
            ? options?.filter((option) => value?.includes(option?.value))
            : options?.filter((option) => option.value === value)
        }
        isMulti={isMulti}
        checked={value}
        onBlur={handleBlur}
        placeholder={placeholder}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
        disabled={isDisabled}
        noOptionsMessage={({ inputValue }) => noOptionsMessage}
        options={options}
        _autofill={{
          backgroundColor: "#B0B0B0",
          color: "energieBlack.500",
        }}
        _active={{
          backgroundColor: "#B0B0B0",
          color: "energieBlack.500",
        }}
        {...fullInputProps}
      />
    </FormGroup>
  );
}
FieldReactSelect.propTypes = propTypes;
FieldReactSelect.defaultProps = defaultProps;
