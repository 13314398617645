import React, { useEffect } from "react";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";
import PropTypes from "prop-types";
import { FormGroup } from "components/form-group";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "./css/react-datepicker.css";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";
import { useLanguageContext } from "context/lang-context";
import { IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const propTypes = {
  inputProps: PropTypes.instanceOf(Object),
  isReadOnly: PropTypes.bool,
  horizontal: PropTypes.bool,
  leftElement: PropTypes.node,
  isNumeric: PropTypes.bool,
  customOnKeyDown: PropTypes.func,
  textAlign: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  inputProps: undefined,
  leftElement: null,
  horizontal: true,
  isReadOnly: false,
  textAlign: "center",
  isNumeric: false,
  ...fieldDefaultProps,
};

export function FieldDateTimePicker(props) {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
  const {
    inputProps,
    label,
    type,
    required,
    customOnKeyDown,
    isDisabled,
    placeholder,
    children,
    isReadOnly,
    horizontal,
    leftElement,
    activeRightButton,
    handleClickRightButton,
    isNumeric,
    textAlign,
    setState,
    ...otherProps
  } = props;
  const { lang } = useLanguageContext();
  const { t } = useTranslation();
  useEffect(() => {
    registerLocale("fr", fr);
    registerLocale("es", es);
    registerLocale("enUS", enUS);
  }, []);

  useEffect(() => {
    setDefaultLocale(lang ?? "fr");
  }, [lang]);

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  // Props de l'input par défaut
  const defaultInputProps = {
    textAlign,
  };

  // Merge des props par défaut + custom
  const fullInputProps = {
    ...defaultInputProps,
    ...inputProps,
  };

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const { handleBlur, ...otherInputProps } = fullInputProps;
  return (
    <FormGroup {...formGroupProps}>
      <DatePicker
        id={id}
        value={value}
        onChange={(date, e) => {
          setValue(date);
        }}
        onChangeRaw={(e) => {
          if (setState) {
            setState(e?.target?.value);
          }
        }}
        // onKeyDown={(e) => {
        //   console.log(e.target.value);
        // }}
        onBlur={(e) => {
          setIsTouched(true);
          if (handleBlur) {
            handleBlur(e);
          }
        }}
        placeholderText={t("app:core.form.label.formatHeure")}
        selected={value}
        showTimeSelect
        timeFormat="p"
        open={false}
        timeIntervals={1}
        dateFormat={t("app:core.dateFormat")}
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        maxDate={new Date()}
        customInput={
          <InputGroup size="md">
            <Input
              textAlign={textAlign}
              placeholder={t("app:core.form.label.formatHeure")}
              inputMode="numeric"
              pattern="\d*"
              type={show ? "text" : "password"}
              border=""
              _autofill={{
                backgroundColor: "#B0B0B0",
                color: "energieBlack.500",
              }}
              _active={{
                backgroundColor: "#B0B0B0",
                color: "energieBlack.500",
              }}
            />
            <InputRightElement width="4.5rem">
              <IconButton
                _hover={{
                  backgroundColor: "white",
                  color: "energieBlack.500",
                }}
                background="transparent"
                border="none"
                color="#B0B0B0"
                onClick={handleClick}
                icon={show ? <ViewOffIcon /> : <ViewIcon />}
              />
            </InputRightElement>
          </InputGroup>
        }
        {...otherInputProps}
      />
    </FormGroup>
  );
}

FieldDateTimePicker.propTypes = propTypes;
FieldDateTimePicker.defaultProps = defaultProps;
