import React from "react";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { FaSave } from "react-icons/fa";
import PropTypes from "prop-types";
import { FormGroup } from "components/form-group";
import "./css/input.css";

const propTypes = {
  inputProps: PropTypes.instanceOf(Object),
  isReadOnly: PropTypes.bool,
  horizontal: PropTypes.bool,
  capitalize: PropTypes.bool,
  toUpperCase: PropTypes.bool,
  leftElement: PropTypes.node,
  isNumeric: PropTypes.bool,
  textAlign: PropTypes.string,
  handleChange: PropTypes.func,
  handleKeyUp: PropTypes.func,
  ...fieldPropTypes,
};

const defaultProps = {
  inputProps: undefined,
  leftElement: null,
  horizontal: true,
  textAlign: "left",
  capitalize: false,
  toUpperCase: false,
  isReadOnly: false,
  isNumeric: false,
  handleChange: null,
  handleKeyUp: null,
  ...fieldDefaultProps,
};

export function FieldInput(props) {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
  const {
    inputProps,
    label,
    type,
    required,
    isDisabled,
    placeholder,
    children,
    isReadOnly,
    horizontal,
    leftElement,
    activeRightButton,
    handleClickRightButton,
    isNumeric,
    capitalize,
    toUpperCase,
    textAlign,
    handleChange,
    handleKeyUp,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = React.useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  // Props de l'input par défaut
  const defaultInputProps = {
    textAlign,
    setState: () => null,
  };

  // Merge des props par défaut + custom
  const fullInputProps = {
    ...defaultInputProps,
    ...inputProps,
  };

  const { setState, handleBlur, ...otherInputProps } = fullInputProps;
  let border = "";
  if (!isValid && (isTouched || isSubmitted)) {
    border = "1px solid hsl(0, 0%, 80%)";
  }
  return (
    <FormGroup {...formGroupProps}>
      <InputGroup>
        {children}
        {leftElement !== null && <InputLeftElement height="100%">{leftElement}</InputLeftElement>}
        <Input
          id={id}
          type={type || "text"}
          value={value ?? ""}
          onChange={(e) => {
            if (toUpperCase) {
              setValue(e.target.value.toUpperCase());
            } else if (capitalize) {
              setValue(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1));
            } else {
              setValue(e.target.value);
            }

            if (handleChange) {
              handleChange(e.target.value);
            }
          }}
          onBlur={(e) => {
            setIsTouched(true);
            if (handleBlur) {
              handleBlur(e);
            }
          }}
          onKeyUp={(event) => {
            if (isNumeric) {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }
            if (handleKeyUp) {
              handleKeyUp(event.target.value);
            }
          }}
          border={border}
          placeholder={placeholder}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          _autofill={{
            border: "1px solid",
            borderColor: "hsl(0, 0%, 80%)",
            backgroundColor: "#191919",
            color: "#8A8A8A",
          }}
          _active={{
            border: "1px solid",
            borderColor: "hsl(0, 0%, 80%)",
            backgroundColor: "#191919",
            color: "#8A8A8A",
          }}
          _disabled={{
            backgroundColor: "#000000",
            border: "solid #323232 2px",
            opacity: 0.8,
            color: "#FFF",
          }}
          {...otherInputProps}
        />

        {activeRightButton && (
          <InputRightElement width="4.5rem">
            <IconButton
              onClick={handleClickRightButton}
              icon={FaSave}
              _active={{
                border: "none",
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </FormGroup>
  );
}

FieldInput.propTypes = propTypes;
FieldInput.defaultProps = defaultProps;
