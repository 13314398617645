import debounce from "lodash.debounce";
import { APPENV } from "config/config";

export const handleResearch = debounce(({e, setChoices}) => {
    const requestOptions = {
      method: "GET",
    };

    fetch(
      `https://api.geoapify.com/v1/geocode/autocomplete?text=${e.target.value}&apiKey=${APPENV.API_KEY}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>
        setChoices(
          result?.features?.map((city) => ({
            city: city.properties.city,
            value: city.properties.formatted,
            label: city.properties.formatted,
            iso: city.properties.country_code
          }))
        )
      )
      .catch((error) => console.log('err'))
    //   setChoices(
    //     result?.features?.map((city) => ({
    //       city: e.target.valuey,
    //       value: e.target.value,
    //       label: e.target.value,
    //     }))
    //   ));
  }, 1000);