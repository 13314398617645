/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconNotifActif({ fill = "#FF0000", color = "#FF0000", ...rest }) {
  return (
    <chakra.svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M22.7325 20.8727C22.7028 20.8478 22.6716 20.8245 22.6366 20.8017C21.805 20.0789 21.1491 19.1795 20.6877 18.1273C20.5318 17.7694 20.1749 17.5371 19.7793 17.5371C19.6441 17.5371 19.5125 17.5642 19.3879 17.6172H19.3863L19.3847 17.6188C18.8857 17.8378 18.6572 18.4222 18.8741 18.9217C19.4733 20.2889 20.3361 21.4529 21.4391 22.3825L21.4434 22.3862L21.4476 22.3894C21.4561 22.3963 21.4651 22.4032 21.4741 22.4101C21.4879 22.4318 21.4959 22.4578 21.4959 22.4848C21.4959 22.5628 21.4322 22.6264 21.3543 22.6264H2.12125C2.0433 22.6264 1.97966 22.5628 1.97966 22.4848C1.97966 22.4583 1.98709 22.4329 2.00034 22.4106C2.01201 22.4016 2.02262 22.3931 2.03216 22.3851L2.03588 22.382L2.03959 22.3788C4.15815 20.5763 5.3736 17.9507 5.3736 15.1762V11.7372C5.3736 8.74363 7.498 6.12552 10.4247 5.51143C10.6841 5.45681 10.9063 5.30514 11.051 5.08401C11.1958 4.86234 11.2451 4.59772 11.1905 4.33893C11.0929 3.88287 10.6851 3.55249 10.2206 3.55249C10.1527 3.55249 10.0848 3.55938 10.0185 3.5737C6.17966 4.37923 3.39345 7.81241 3.39345 11.7372V15.1762C3.39345 17.3298 2.46224 19.3778 0.837391 20.7995C0.802391 20.8223 0.770042 20.8473 0.740345 20.8722C0.269436 21.2768 -0.000488281 21.8639 -0.000488281 22.4838C-0.000488281 23.6536 0.950875 24.605 2.12072 24.605H7.46671C7.67353 25.4959 8.15822 26.3062 8.85504 26.9155C9.65368 27.6139 10.6772 27.9989 11.7372 27.9989C12.7973 27.9989 13.8208 27.6145 14.6194 26.9155C15.3163 26.3062 15.801 25.4959 16.0078 24.605H21.3538C22.5236 24.605 23.475 23.6536 23.475 22.4838C23.475 21.8633 23.2045 21.2757 22.7325 20.8717V20.8727ZM13.9279 24.6061C13.545 25.4492 12.6934 26.0204 11.7372 26.0204C10.7811 26.0204 9.92944 25.4498 9.54656 24.6061H13.9279Z"
        fill={color}
      />
      <path
        d="M20.222 0C15.9335 0 12.4441 3.48886 12.4441 7.77795C12.4441 12.067 15.933 15.5559 20.222 15.5559C24.5111 15.5559 28 12.067 28 7.77795C28 3.48886 24.5111 0 20.222 0ZM26.0198 7.77795C26.0198 10.9752 23.4187 13.5758 20.222 13.5758C17.0254 13.5758 14.4242 10.9746 14.4242 7.77795C14.4242 4.58129 17.0254 1.98015 20.222 1.98015C23.4187 1.98015 26.0198 4.58129 26.0198 7.77795Z"
        fill={fill}
      />
      <path
        d="M19.5438 9.56611L19.5454 9.56771C19.73 9.75066 19.9665 9.84717 20.2285 9.84717C20.4687 9.84717 20.6962 9.75437 20.8876 9.57831L20.8892 9.57672L20.8908 9.57513C21.0902 9.38846 21.1952 9.14877 21.1952 8.88096V3.93589C21.1952 3.67657 21.0997 3.44005 20.9189 3.25233C20.736 3.06036 20.4968 2.95801 20.2285 2.95801C19.9601 2.95801 19.7236 3.05983 19.5385 3.25233C19.3582 3.43952 19.2633 3.67604 19.2633 3.93642V8.88149C19.2633 9.14346 19.3598 9.37998 19.5428 9.56452L19.5444 9.56611H19.5438Z"
        fill={fill}
      />
      <path
        d="M20.2285 10.3473C19.8997 10.3473 19.6043 10.4687 19.3747 10.6984C19.1446 10.9285 19.0226 11.2239 19.0226 11.5532C19.0226 11.8825 19.144 12.1779 19.3747 12.408C19.6043 12.6377 19.8997 12.7591 20.2285 12.7591C20.5573 12.7591 20.8394 12.6382 21.0701 12.4091L21.0711 12.408L21.0722 12.407C21.3013 12.1763 21.4222 11.8815 21.4222 11.5532C21.4222 11.2249 21.3024 10.9428 21.0754 10.7095C20.8447 10.4724 20.552 10.3473 20.229 10.3473H20.2285Z"
        fill={fill}
      />
    </chakra.svg>
  );
}
IconNotifActif.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconNotifActif;
