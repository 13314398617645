import React, { useEffect } from "react";
import {
  Box,
  Heading,
  Hide,
  Highlight,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { useTranslation } from "react-i18next";
import { Footer } from "components/Layout/Footer";
import { Header } from "components/Layout/Header";
import { useApp } from "context/App/useApp";

export function PageDecouvrir() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();
  const { setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover } = useApp();

  useEffect(() => {
    setPageColor("#E71D73");
    setBackgroundColorNotif("#E71D73");
    setColorNotif("#FFFFFF");
    setColorNotifHover("#000000");
  }, [setBackgroundColorNotif, setPageColor, setColorNotif, setColorNotifHover]);

  return (
    <SlideIn>
      <Header />
      <Stack mx="auto" p={12}>
        <Heading
          fontSize="32px"
          lineHeight="38px"
          fontWeight="medium"
          color="energieRose"
          textTransform="uppercase"
        >
          {t("app:core.leProjet")}
        </Heading>
        <SimpleGrid
          w={isMobile ? "100%" : "80rem"}
          justifyItems="center"
          columns={isMobile ? 1 : 2}
          spacing={10}
        >
          <Stack borderLeft="inset 4px #E71D73" margin="auto" spacing={8} p={4} textAlign="justify">
            <Text color="#B0B0B0" fontSize="18px">
              <Highlight query="la vie" styles={{ fontWeight: "bold", color: "white" }}>
                {t("app:core.descriptionProjet")}
              </Highlight>
            </Text>
            <Text fontSize="18px" color="#B0B0B0">
              {t("app:core.descriptionProjet2")}
            </Text>
            <Text fontSize="18px" color="#B0B0B0">
              {t("app:core.descriptionProjet3")}
            </Text>
            {isMobile ? (
              <Box boxSize="sm" width="100%">
                <Image src="/Illustration_partage.png" alt="Partage" />
              </Box>
            ) : null}
          </Stack>
          <Hide below="sm">
            <Box p={4} boxSize="sm">
              <Image src="/Illustration_partage.png" alt="Partage" />
            </Box>
          </Hide>
        </SimpleGrid>
      </Stack>
      <Footer />
    </SlideIn>
  );
}
