export const TableApps = {
  baseStyle: {
    table: {
      width: "100%",
    },
    head: {
      display: "inline-block",
      width: "100%",
    },
    th: {
      textAlign: "center",
    },
    td: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  parts: ["table", "head", "body", "btnAdd"],
  variants: {
    default: {
      head: {
        paddingTop: "10px",
        borderRadius: "16px 16px 0 0",
        border: "solid 1px #C4C4C4",
      },
      td: {
        padding: "5px",
      },
      tr: {
        display: "flex",
        paddingLeft: "2px",
        paddingRight: "2px",
        border: "solid 1px #C4C4C4",
        hover: {
          cursor: "pointer",
          backgroundColor: "gray.200",
          "th, td": {
            borderBottomWidth: "1px",
          },
          td: {
            backgroundColor: "gray.200",
          },
        },
      },
      footer: {
        display: "none",
      },
      btnAdd: {
        color: "white",
      },
    },
  },
  // On change le variant ici par application
  defaultProps: {
    variant: "default",
  },
};
