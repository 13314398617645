/**
 * @description https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */

import React, { useMemo, useState } from "react";
// import { useIsOnline } from 'react-use-is-online';
import refbuild from "config/build.json";

/**
 * Creation du context
 */
const AppContext = React.createContext([{}, () => {}]);

/**
 * Déclaration des data
 * @param {*} props
 * @returns
 */
function AppProvider(props) {
  //   const { isOnline } = useIsOnline();

  /**
   * Déclaration des datas
   */
  const [state, setState] = useState({
    providerName: AppProvider.name,
    refBuild: refbuild ?? "---refBuild---",
    isOnline: true, // Test si on est connecté au réseau
    isMobile: false,
    pageColor: "#E71D73",
    colorNotif: "#000000",
    colorNotifHover: "#ffffff",
    backgroundColorNotif: "#000000",
    isFormChanged: false,
  });

  const memoizedValue = useMemo(() => [state, setState], [state]);

  //   useEffect(() => {
  //     setState((data) => ({ ...data, isOnline }));
  //   }, [isOnline]);

  // useEffect(() => {
  //     setState((data) => ({ ...data, isMobile }));
  // }, [isMobile]);

  return <AppContext.Provider value={memoizedValue} {...props} />;
}

export { AppContext, AppProvider };
