import * as React from 'react';

export const nl2br = (str) => {
  const newlineRegex = /(\r\n|\r|\n)/g;
  if (typeof str !== 'string') {
    return str;
  }

  const prefix = `br_${Math.random() * 100}`;
  let i = 0;
  return str.split(newlineRegex).map((line) => {
    i += 1;
    if (line.match(newlineRegex)) {
      return (
        <React.Fragment key={prefix + i}>
          <br />
        </React.Fragment>
      );
    }
    return line;
  });
};
