/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconCle({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="22"
      height="30"
      viewBox="0 0 22 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18.9451 3.66676H17.1117V3.05544C17.1117 2.71799 16.8379 2.44413 16.5004 2.44413H13.995C13.7114 1.05133 12.4756 0 11.0003 0C9.52499 0 8.28917 1.05133 8.00557 2.4447H5.50014C5.16269 2.4447 4.88883 2.71856 4.88883 3.05602V3.66734H3.05544C1.36988 3.66676 0 5.03836 0 6.72221V26.2781C0 27.9625 1.36988 29.3335 3.05544 29.3335H18.9446C20.6301 29.3335 22 27.9619 22 26.2781V6.72221C22 5.03779 20.6301 3.66676 18.9446 3.66676H18.9451ZM6.11146 3.66676H8.55616C8.89362 3.66676 9.16748 3.3929 9.16748 3.05544C9.16748 2.04479 9.99021 1.22206 11.0009 1.22206C12.0115 1.22206 12.8342 2.04479 12.8342 3.05544C12.8342 3.3929 13.1081 3.66676 13.4456 3.66676H15.8903V5.50014C15.8903 5.8376 15.6153 6.11146 15.2789 6.11146H6.72221C6.3859 6.11146 6.11089 5.8376 6.11089 5.50014V3.66676H6.11146ZM20.7785 26.2787C20.7785 27.2893 19.9558 28.112 18.9451 28.112H3.05544C2.04479 28.112 1.22206 27.2893 1.22206 26.2787V6.72221C1.22206 5.71156 2.04479 4.88883 3.05544 4.88883H4.88883V5.50014C4.88883 6.51079 5.71156 7.33352 6.72221 7.33352H15.2778C16.2884 7.33352 17.1112 6.51079 17.1112 5.50014V4.88883H18.9446C19.9552 4.88883 20.7779 5.71156 20.7779 6.72221V26.2781L20.7785 26.2787Z"
        fill={color}
      />
      <path
        d="M18.9451 9.77826H3.05541C2.71795 9.77826 2.44409 10.0521 2.44409 10.3896V25.0566C2.44409 25.3941 2.71795 25.6679 3.05541 25.6679H18.9445C19.282 25.6679 19.5558 25.3941 19.5558 25.0566V10.389C19.5558 10.0515 19.2826 9.77826 18.9451 9.77826ZM7.33349 17.1118H3.66673V14.6671H7.33349V17.1118ZM8.55555 14.6671H18.3338V17.1118H8.55555V14.6671ZM3.66673 18.3338H7.33349V20.7785H3.66673V18.3338ZM8.55555 18.3338H9.5C11.134 16.9543 11.9338 16.938 13.2112 18.3338H15.6284H18.3338V20.7785H8.55555V18.3338ZM18.3338 13.445H8.55555V11.0003H18.3338V13.445ZM7.33349 11.0003V13.445H3.66673V11.0003H7.33349ZM3.66673 22.0006H7.33349V24.4453H3.66673V22.0006ZM8.55555 24.4453V22.0006H18.3338V24.4453H8.55555Z"
        fill={color}
      />
      <path
        d="M14.0369 22.7676C13.8697 22.6005 13.6789 22.4483 13.4708 22.3146C12.891 22.5878 12.2428 22.7421 11.5619 22.7421C10.8812 22.7421 10.2364 22.5886 9.65915 22.3175C8.67035 22.9534 8.06227 24.0369 8.06227 25.2423C8.06227 26.0704 7.39124 26.7422 6.56233 26.7422C5.73343 26.7422 5.0624 26.0704 5.0624 25.2423C5.0624 23.2251 5.97914 21.3805 7.49661 20.1701C7.21836 19.5857 7.06191 18.9317 7.06191 18.2421C7.06191 15.7599 9.08036 13.7422 11.5622 13.7422C14.0439 13.7422 16.0622 15.7598 16.0619 18.2419L14.0369 22.7676ZM14.0369 22.7676C14.6998 23.4307 15.0625 24.3064 15.0625 25.242C15.0625 26.0701 15.7336 26.742 16.5625 26.742C17.3914 26.742 18.0624 26.0701 18.0624 25.242C18.0624 23.5081 17.3844 21.8732 16.1583 20.6465C15.9897 20.4779 15.8114 20.3202 15.6258 20.1734C15.905 19.588 16.0619 18.9328 16.0619 18.242L14.0369 22.7676ZM11.5622 16.7423C12.3884 16.7423 13.0623 17.4161 13.0623 18.2424C13.0623 19.0687 12.3885 19.7425 11.5622 19.7425C10.7358 19.7425 10.062 19.0687 10.062 18.2424C10.062 17.4161 10.7358 16.7423 11.5622 16.7423Z"
        fill={color}
        stroke="black"
        strokeWidth="2"
      />
    </chakra.svg>
  );
}
IconCle.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconCle;
