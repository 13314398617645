import React, { useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  HStack,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useGetUserById } from "App/utilisateur/services/useGetUserById";
import IconEnergieSansPlus from "components/Icons/icons-generated/IconEnergieSansPlus";
import { Formiz, useForm } from "@formiz/core";
import { useTranslation } from "react-i18next";
import { FieldFactory } from "components/fields/FieldFactory";
import { FormatDate } from "functions/common-scope/utils";
import { useGetPaysCurrentUser } from "App/register/services/useGetPaysCurrentUser";
import { useLanguageContext } from "context/lang-context";
import { useGlobalEvents } from "hooks/useGlobalEvents";
import { useApp } from "context/App/useApp";
import { useNavigate, useParams } from "react-router";
import { isEmail, isNumber } from "@formiz/validations";
import IconEnergieProposerDon from "components/Icons/icons-generated/IconEnergieProposerDon";
import IconCle from "components/Icons/icons-generated/IconCle";
import { ModalCustom } from "components/Modal/Modal";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { ButtonsSupprimer } from "components/Buttons/ButtonsSupprimer";
import { useAuthentificationContext } from "context/auth-context";
import { useGetDonsById } from "../services/useGetDonsById";
import { useDeleteDons } from "../services/useDeleteDons";
import { useUpdateDons } from "../services/useUpdateDons";

export function FormEnvoyerDonsEdit() {
  const { t } = useTranslation();
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const { isOpen: isOpenValider, onClose: onCloseValider, onOpen: onOpenValider } = useDisclosure();
  const loginForm = useForm({
    subscribe: "form",
  });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const deleteForm = useForm();
  const { pageColor } = useApp();
  const [hoverColor, setHoverColor] = useState({
    isHover: false,
  });
  const { id } = useParams();
  const { dons, isLoading: isLoadingDons, isFetching } = useGetDonsById({ donId: id });
  const { user: userBenef, isLoading: isLoadingBenef } = useGetUserById({ id: dons?.destinataire });
  const { user, isLoading } = useAuthentificationContext();
  const { lang } = useLanguageContext();

  const [refBenef, setRefBenef] = useState(userBenef?.clePublique);

  useEffect(() => {
    if (userBenef?.clePublique) {
      setRefBenef(userBenef.clePublique);
    }
  }, [userBenef]);

  const navigate = useNavigate();
  useGlobalEvents({
    form: loginForm,
    type: "keypress",
    key: "Enter",
    action: "submitForm",
  });

  const onSuccess = () => {
    navigate("/dashboard/historique");
  };

  const { mutate: envoyerDon, isLoading: isLoadingCreate } = useUpdateDons({ onSuccess });

  const handleSuccessDelete = async () => {
    onCloseDelete();
    navigate("/dashboard/historique");
  };
  const { mutate: deleteDon, isLoading: iseLoadingDelete } = useDeleteDons({
    onSuccess: handleSuccessDelete,
  });
  const handleSupprimer = (values) => {
    deleteDon({ ...values, dons: { ...dons } });
  };

  const handleEnvoyerDon = (values) => {
    const datas = { ...values, id: dons.id };
    envoyerDon(datas);
  };

  const handleColler = async () => {
    try {
      const text = navigator.clipboard.readText();
      return setRefBenef(text);
    } catch (error) {
      return error;
    }
  };
  const { pays, isLoading: isLoadingPays } = useGetPaysCurrentUser();

  if (isLoadingDons || isLoading || isLoadingBenef || isFetching || isLoadingPays) {
    return null;
  }
  const date = new Date();
  return (
    <>
      <Formiz id="dons-form" autoForm onValidSubmit={handleEnvoyerDon} connect={loginForm}>
        <Stack mb={8}>
          <Stack alignItems="center" mb={8}>
            <Text textTransform="uppercase" fontSize="24px" color="energieRose">
              {t("dons:envoyer")}
            </Text>
          </Stack>
          <Stack alignItems="center" borderTopRadius="10px" backgroundColor={pageColor}>
            <Text fontSize="16px" color="energieBlack.500" fontWeight="bold">
              {`${t("dons:reference")}: ${pays.codeIso}${user.identite}_${FormatDate(
                date,
                "YYYY-MM-DD H:m:s",
                lang
              )}`}
            </Text>
          </Stack>
          <Stack background="#E71D7366" position="relative">
            <HStack p={1} alignSelf="center" alignItems="center">
              <Text color="white">{`${t("account:energieDisponible")}`} </Text>
              <IconEnergieSansPlus color="white" />
              <Text color="white">{user?.energiesDisponible ?? 0} </Text>
            </HStack>
          </Stack>
        </Stack>
        <Grid
          rowGap={isMobile ? 8 : 2}
          columnGap={4}
          padding={isMobile ? 2 : 0}
          templateAreas={[
            `"reference" 
              "description" 
              "contact" 
              "quantite"`,
            `"reference quantite"
            "contact ."
            "description description"
            `,
          ]}
        >
          <GridItem h="100%" area="reference">
            <Text textTransform="uppercase" fontSize="18px" color="energieRose">
              {t("dons:referenceBenef")}
            </Text>
            {userBenef !== null && refBenef === undefined ? (
              <Spinner />
            ) : (
              <HStack p={4} minH="120px" borderLeft="solid #E71D73 4px" spacing={4}>
                <FieldFactory
                  isDisabled
                  handleChange={setRefBenef}
                  defaultValue={refBenef}
                  type="text"
                  name="refBenef"
                  placeholder={`${t("dons:referenceBenefPlaceholder")}`}
                  required={`${t("dons:required.referenceBenef")}`}
                />
                <IconCle cursor="pointer" onClick={handleColler} />
              </HStack>
            )}
          </GridItem>
          <GridItem h="100%" area="quantite">
            <Stack>
              <Text textTransform="uppercase" fontSize="18px" color="energieRose">
                {t("dons:quantiteEnvoye")}
              </Text>
              <HStack
                minH="120px"
                p={4}
                marginTop="0px!important;"
                borderLeft="solid #E71D73 4px"
                spacing={4}
              >
                <IconEnergieProposerDon color={pageColor} />
                <FieldFactory
                  type="number"
                  name="quantite"
                  textAlign="center"
                  validations={[
                    {
                      rule: isNumber(),
                      message: t("app:core.form.label.formatEmail"),
                    },
                    {
                      rule: (val) => val >= 1,
                      message: t("app:core.form.label.number"),
                    },
                  ]}
                  defaultValue={dons?.quantite ?? 1}
                />
              </HStack>
            </Stack>
          </GridItem>

          <GridItem area="contact">
            <Text textTransform="uppercase" fontSize="18px" color="energieRose">
              {t("dons:contact")}
            </Text>
            <Stack p={4} spacing={4} borderLeft="solid #E71D73 4px">
              <FieldFactory
                isDisabled
                label={`${t("app:core.form.label.mail")}`}
                type="text"
                name="mail"
                validations={[
                  {
                    rule: isEmail(),
                    message: t("app:core.form.label.formatEmail"),
                  },
                ]}
                placeholder={`${t("app:core.form.placeholder.mail")}`}
                defaultValue={userBenef?.mail}
              />
              <FieldFactory
                isDisabled
                country={pays?.codeIso ?? "FR"}
                label={`${t("app:core.form.label.fixe")}`}
                type="phone"
                name="fixe"
                placeholder={`${t("app:core.form.placeholder.fixe")}`}
                defaultValue={userBenef?.fixe}
              />
              <FieldFactory
                country={pays?.codeIso ?? "FR"}
                isDisabled
                label={`${t("app:core.form.label.mobile")}`}
                type="phone"
                name="mobile"
                placeholder={`${t("app:core.form.placeholder.mobile")}`}
                defaultValue={userBenef?.mobile}
              />
            </Stack>
          </GridItem>

          <GridItem h="100%" area="description">
            <Text textTransform="uppercase" fontSize="18px" color="energieRose">
              {t("dons:description.title")}
            </Text>
            <Stack p={4} mb={4} borderLeft="solid #E71D73 4px" spacing={4}>
              <FieldFactory
                defaultValue={dons?.nom}
                label={`${t("dons:titre")}`}
                type="text"
                name="nom"
                placeholder={`${t("dons:titre")}`}
                toUpperCase
                required={`${t("dons:required.nom")}`}
              />
              <FieldFactory
                maxHeight="120px"
                name="descriptif"
                label={`${t("dons:descriptif.autre")}`}
                type="textarea"
                placeholder={`${t("dons:descriptif.autre")}`}
                required={`${t("dons:required.descriptif")}`}
                defaultValue={dons?.description}
              />
            </Stack>
          </GridItem>
        </Grid>
        <HStack
          borderTop="solid #E71D73 4px"
          alignItems="center"
          px={1}
          spacing={4}
          py={4}
          my={8}
          h="100%"
          justifyContent="end"
        >
          <ButtonsSupprimer
            minW="80px"
            onClick={onOpenDelete}
            isDisabled={iseLoadingDelete}
            isLoading={iseLoadingDelete}
            color={hoverColor.isHoverSupprimer ? "white" : pageColor}
            stroke={hoverColor.isHoverSupprimer ? "white" : pageColor}
            onMouseEnter={() => setHoverColor({ isHoverSupprimer: true })}
            onMouseLeave={() => setHoverColor({ isHoverSupprimer: false })}
          />
          <ButtonsValider
            minW="80px"
            className={hoverColor.isHover ? "logoHover" : ""}
            onMouseEnter={() => setHoverColor({ isHover: true })}
            onMouseLeave={() => setHoverColor({ isHover: false })}
            onClick={onOpenValider}
            isDisabled={isLoadingCreate}
            isLoading={isLoadingCreate}
            color={pageColor}
          />
        </HStack>
        <ModalCustom
          title={t("dons:valider.modalTitle")}
          textDescription={t("dons:valider.modalDescription")}
          bgColor="energieRose"
          color="white"
          isOpen={isOpenValider}
          onClose={onCloseValider}
          handleValider={loginForm.submit}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          isLoading={isLoadingCreate}
        >
          <Stack justifyContent="center" spacing={4} p={4}>
            <FieldFactory
              type="datetime"
              name="heurenaissanceConfirm"
              placeholder={`${t("app:core.form.placeholder.heure")}`}
              required={`${t("app:core.form.required.heure")}`}
            />
          </Stack>
        </ModalCustom>
      </Formiz>
      <Formiz id="delete-form" autoForm onValidSubmit={handleSupprimer} connect={deleteForm}>
        <ModalCustom
          title={t("dons:delete.modalTitle")}
          textDescription={t("dons:delete.modalDescription")}
          bgColor="energieRose"
          color="white"
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          handleValider={deleteForm.submit}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          isLoading={iseLoadingDelete}
        >
          <Stack justifyContent="center" spacing={4} p={4}>
            <FieldFactory
              type="datetime"
              name="heurenaissanceConfirmDelete"
              placeholder={`${t("app:core.form.placeholder.heure")}`}
              required={`${t("app:core.form.required.heure")}`}
            />
          </Stack>
        </ModalCustom>
      </Formiz>
    </>
  );
}
