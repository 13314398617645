/**
 * @description https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react
 */
/* eslint no-unused-vars: "warn" */
import { useCallback, useContext } from "react";
import { AppContext } from "./AppProvider";

/**
 * Creation du custom hook
 */
export const useApp = () => {
  const [state, setState] = useContext(AppContext);
  /**
   * Test si une connexion existe
   * @returns bool
   */
  const isOnline = () => state.isOnline;
  const isMobile = () => state.isMobile;
  const getProviderName = () => state.providerName;

  /**
   * Retourne la version du build
   * @returns string
   */
  const getRefBuild = () => state.refBuild;

  const { isFormChanged } = state;
  const setFormChanged = useCallback(
    (status = true) => {
      setState((data) => ({ ...data, isFormChanged: status }));
    },
    [setState]
  );
  const setPageColor = useCallback(
    (color) => {
      setState((data) => ({ ...data, pageColor: color }));
    },
    [setState]
  );
  const setBackgroundColorNotif = useCallback(
    (color) => {
      setState((data) => ({ ...data, backgroundColorNotif: color }));
    },
    [setState]
  );
  const setColorNotif = useCallback(
    (color) => {
      setState((data) => ({ ...data, colorNotif: color }));
    },
    [setState]
  );
  const setColorNotifHover = useCallback(
    (color) => {
      setState((data) => ({ ...data, colorNotifHover: color }));
    },
    [setState]
  );

  return {
    ...state,
    setPageColor,
    getProviderName,
    getRefBuild,
    isOnline,
    isMobile,
    setColorNotif,
    setColorNotifHover,
    setBackgroundColorNotif,
    isFormChanged,
    setFormChanged,
  };
};
