/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconEnergieSansPlus({
  color = "#E71D73",
  dropShadowColor = "#E71D73",
  ...rest
}) {
  return (
    <chakra.svg
      width="33"
      height="30"
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.8743 24.3408V16.8979L21.3188 13.1764C21.8563 12.8666 22.0395 12.1785 21.7296 11.641C21.4198 11.1035 20.7317 10.9203 20.1943 11.2301L13.748 14.9516L7.30182 11.2301C6.76435 10.9203 6.07796 11.1035 5.76643 11.641C5.45662 12.1785 5.63977 12.8649 6.17724 13.1764L12.6235 16.8979V24.3408C12.6235 24.9604 13.1267 25.4637 13.748 25.4637C14.3694 25.4637 14.8709 24.9604 14.8709 24.3408H14.8743Z"
        fill={color}
      />
      <path
        d="M20.042 17.8633C19.5747 17.7383 19.0868 17.8017 18.6675 18.0447C18.2481 18.2861 17.9486 18.6764 17.8236 19.1437C17.6987 19.611 17.762 20.0989 18.0051 20.5183C18.3405 21.0986 18.9499 21.4238 19.5764 21.4238C19.8828 21.4238 20.1943 21.3451 20.4784 21.1808C20.8978 20.9394 21.1974 20.5491 21.3223 20.0818C21.4473 19.6145 21.3839 19.1266 21.1409 18.7072C20.8995 18.2878 20.5093 17.9883 20.042 17.8633Z"
        fill={color}
      />
      <path
        d="M13.2808 11.2729C13.4365 11.314 13.5957 11.3345 13.7515 11.3345C14.0647 11.3345 14.3746 11.2524 14.6536 11.0914C15.0729 10.8501 15.3725 10.4598 15.4974 9.99247C15.6224 9.52515 15.559 9.03729 15.316 8.6179C14.8162 7.75344 13.707 7.45559 12.8426 7.95544C12.4232 8.1968 12.1237 8.58709 11.9987 9.05441C11.8738 9.52173 11.9371 10.0096 12.1802 10.429C12.4215 10.8484 12.8118 11.1479 13.2791 11.2729H13.2808Z"
        fill={color}
      />
      <path
        d="M8.3956 17.8633C7.92831 17.7383 7.44048 17.8017 7.02112 18.0447C6.60175 18.2861 6.30221 18.6764 6.17725 19.1437C6.0523 19.611 6.11563 20.0989 6.35869 20.5183C6.69419 21.0986 7.30355 21.4238 7.93002 21.4238C8.23642 21.4238 8.54794 21.3451 8.83208 21.1808C9.69649 20.6809 9.99432 19.5717 9.49451 18.7072C9.25316 18.2878 8.86289 17.9883 8.3956 17.8633Z"
        fill={color}
      />
      <path
        d="M13.7395 2.5C11.338 2.5 8.96558 3.1385 6.8756 4.3436C5.27004 5.2714 3.89213 6.48677 2.78124 7.95549C1.70973 9.37457 0.932619 10.9665 0.470463 12.6903C0.0100182 14.4158 -0.113223 16.1841 0.104161 17.9472C0.331816 19.7754 0.915502 21.5163 1.84324 23.1237C3.07223 25.2532 4.84725 27.0129 6.97831 28.2163C9.0426 29.382 11.3876 30 13.76 30C16.1324 30 18.5339 29.3632 20.6222 28.1564C22.2277 27.2286 23.6057 26.0132 24.7165 24.5445C25.7881 23.1254 26.5652 21.5335 27.0273 19.8097C27.4895 18.0859 27.6127 16.3176 27.3936 14.5528C27.166 12.7246 26.5823 10.9837 25.6546 9.37628C24.4256 7.24852 22.6505 5.48708 20.5212 4.28369C18.4552 3.11625 16.1102 2.5 13.7395 2.5ZM3.99141 21.8844C0.879556 16.4939 2.72647 9.60224 8.11487 6.4902C9.88989 5.46483 11.8275 4.97868 13.7378 4.97868C17.6319 4.97868 21.4198 7.00031 23.5064 10.6156C26.6182 16.0061 24.7713 22.8978 19.3812 26.0098C17.6062 27.0352 15.6686 27.5213 13.7583 27.5213C9.86421 27.5213 6.07625 25.4997 3.9897 21.8844H3.99141Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconEnergieSansPlus.propTypes = {
  color: PropTypes.string,
  dropShadowColor: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconEnergieSansPlus;
