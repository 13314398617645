import React, { useCallback, useState } from "react";
import {
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { RxCaretRight } from "react-icons/rx";
import { ButtonWithIco } from "components/Buttons/ButtonWithIco";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formiz, useForm } from "@formiz/core";
import { ModalCustom } from "components/Modal/Modal";
import { FieldFactory } from "components/fields/FieldFactory";
import { useDeleteDons } from "../services/useDeleteDons";
import { useToggleHidden } from "../services/useToggleHidden";

export function PopoverDon({ don, user, isMyDon, colorDon, colorButton }) {
  const [, setSearchParams] = useSearchParams();
  const [hoverColor, setHoverColor] = useState({
    isHoverSupprimer: false,
  });
  const deleteForm = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const handleSuccessDelete = async () => {
    onCloseDelete();
    await queryClient.invalidateQueries({ queryKey: ["searchDons"], exact: false });
  };
  const { mutate: deleteDon, isLoading: iseLoadingDelete } = useDeleteDons({
    onSuccess: handleSuccessDelete,
  });

  const { mutate: toggleHidden } = useToggleHidden();
  // const handleProposer = () => {};
  const handleCollectif = () => {
    navigate({ pathname: `/collectifs`, search: `?paysId=${don.usager.paysId}` });
  };
  const handleDons = useCallback(() => {
    if (isMyDon) {
      setSearchParams((old) => ({ ...old, donRef: user.clePublique }));
    } else {
      setSearchParams((old) => ({ ...old, donRef: don.usager.clePublique }));
    }
  }, [isMyDon, don, user, setSearchParams]);

  const handleModifier = () => {
    navigate({ pathname: `/account/proposerDon`, search: `?donId=${don.dons.id}` });
  };
  const handleSupprimer = (values) => {
    deleteDon({ ...values, ...don });
  };
  // const handleRdv = () => {};
  const handleNePlusVoir = () => {
    toggleHidden(don.dons);
  };
  // const handleAlerter = () => {};

  return (
    <>
      <Formiz id="delete-form" autoForm onValidSubmit={handleSupprimer} connect={deleteForm}>
        <ModalCustom
          title={t("dons:delete.modalTitle")}
          textDescription={t("dons:delete.modalDescription")}
          bgColor="energieOrange.500"
          color="white"
          isOpen={isOpenDelete}
          onClose={onCloseDelete}
          handleValider={deleteForm.submit}
          className={hoverColor.isHover ? "logoHover" : ""}
          onMouseEnter={() => setHoverColor({ isHover: true })}
          onMouseLeave={() => setHoverColor({ isHover: false })}
          isLoading={iseLoadingDelete}
        >
          <Stack justifyContent="center" spacing={4} p={4}>
            <FieldFactory
              type="datetime"
              name="heurenaissanceConfirmDelete"
              placeholder={`${t("app:core.form.placeholder.heure")}`}
              required={`${t("app:core.form.required.heure")}`}
            />
          </Stack>
        </ModalCustom>
      </Formiz>
      <Popover>
        <PopoverTrigger>
          <Stack>
            <ButtonWithIco
              textTransform="uppercase"
              color={colorDon}
              minH="16px"
              fontSize="12px"
              lineHeight="14px"
              iconSpacing={0}
              icon={<BsThreeDots fill={colorButton} stroke={colorButton} />}
            />
          </Stack>
        </PopoverTrigger>
        <PopoverContent
          borderRadius="10px"
          borderColor={colorDon}
          backgroundColor="energieBlack.500"
        >
          <PopoverBody>
            {isMyDon ? (
              <Stack cursor="pointer" fontSize={12} textTransform="uppercase">
                {/* <HStack color={colorDon} onClick={handleProposer}>
                  <RxCaretRight />
                  <Text>{t("dons:partager")}</Text>
                </HStack> */}
                <HStack color={colorDon} onClick={handleDons}>
                  <RxCaretRight />
                  <Text>{t("dons:mesAutresDons")}</Text>
                </HStack>
                <HStack color={colorDon} onClick={handleCollectif}>
                  <RxCaretRight />
                  <Text>{t("dons:collectif")}</Text>
                </HStack>
                <Divider />
                <HStack color="energieOrange.500" onClick={handleModifier}>
                  <RxCaretRight />
                  <Text>{t("dons:modifier")}</Text>
                </HStack>
                <HStack color="energieOrange.500" onClick={onOpenDelete}>
                  <RxCaretRight />
                  <Text>{t("dons:supprimer")}</Text>
                </HStack>
              </Stack>
            ) : (
              <Stack cursor="pointer" fontSize={12} textTransform="uppercase">
                {/* <HStack color={colorDon} onClick={handleRdv}>
                  <RxCaretRight />
                  <Text>{t("dons:fixerRdv")}</Text>
                </HStack> */}
                <HStack color={colorDon} onClick={handleDons}>
                  <RxCaretRight />
                  <Text>{t("dons:autresDons")}</Text>
                </HStack>
                <HStack color={colorDon} onClick={handleCollectif}>
                  <RxCaretRight />
                  <Text>{t("dons:collectif")}</Text>
                </HStack>
                <Divider />
                <HStack color="energieOrange.500" onClick={handleNePlusVoir}>
                  <RxCaretRight />
                  <Text>{t("dons:nePlusVoir")}</Text>
                </HStack>
                {/* <HStack color="energieOrange.500" onClick={handleAlerter}>
                  <RxCaretRight />
                  <Text>{t("dons:alerter")}</Text>
                </HStack> */}
              </Stack>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
PopoverDon.propTypes = {
  isMyDon: PropTypes.bool,
  colorDon: PropTypes.string,
  colorButton: PropTypes.string,
  don: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
};
