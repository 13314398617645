import Axios from "axios";
import { APPENV } from "config/config";
import { useQuery } from "react-query";

export const useGetDons = ({params = new URLSearchParams()}) => {
  const {data, isLoading, isFetching } = useQuery(['dons', params.toString()],
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/dons`, {
          params
        }
      ),
  );

  const dons = data?.data ?? null;
  return {dons, isLoading, isFetching};
}

