import React, { useState, useEffect } from "react";
import { HStack, Spinner, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useLocation } from "react-router";
import { useAuthentificationContext } from "context/auth-context";
import { SlideIn } from "components/SlideIn";
import { Header } from "components/Layout/Header";
import { ButtonFilters } from "components/Buttons/ButtonFilters";
import { useTranslation } from "react-i18next";
import { Footer } from "components/Layout/Footer";
import { useGetDons } from "App/dons/services/useGetDons";
import { Formiz, useForm } from "@formiz/core";
import { FieldFactory } from "components/fields/FieldFactory";
import { ButtonsValider } from "components/Buttons/ButtonsValider";
import { useApp } from "context/App/useApp";
import { CardHistorique } from "./_partials/CardHistorique";
import { CardHistoriqueMobile } from "./_partials/CarHistoriqueMobile";

export function PageHistorique() {
  const { user, isLoading } = useAuthentificationContext();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { search, state } = useLocation();
  const params = new URLSearchParams(search);
  const form = useForm({
    subscribe: "form",
  });
  // remplacer par params pour filter coter backend
  const { dons, isLoading: isLoadingDons } = useGetDons({
    params: new URLSearchParams(),
  });

  const { pageColor } = useApp();
  const [data, setData] = useState(dons);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState({
    frequence: params.get("frequencedon_dons") ?? t("dons:frequence.tous.value"),
    search: null,
  });

  // filtrage coté front
  useEffect(() => {
    if (selectedValue.frequence === t("dons:frequence.tous.value")) {
      setData(dons);
    } else {
      setData(dons?.filter((d) => d?.dons?.frequenceDon === selectedValue.frequence));
    }
    if (state?.id) {
      setData((old) => [...(old ?? [])].sort((a) => (a?.dons?.id === Number(state?.id) ? -1 : 1)));
    }

    if (selectedValue.search) {
      setData(
        dons
          ?.filter(
            (d) =>
              d.dons.description.toLowerCase().indexOf(selectedValue.search.toLowerCase()) > -1 ||
              d.dons.nom.toLowerCase().indexOf(selectedValue.search.toLowerCase()) > -1
          )
          .sort((a, b) => new Date(a?.dons?.dateCreation) - new Date(b?.dons?.dateCreation))
      );
    }
  }, [selectedValue, state, dons, t]);

  const handleSearchDon = (values) => {
    setSelectedValue((old) => ({ ...old, search: values.search }));
  };

  return (
    <SlideIn>
      <Header />
      <Stack mx="auto" p={isMobile ? 4 : 4} minH="500px">
        <Stack mb={8} spacing={8}>
          <Stack alignItems="center">
            <Text textTransform="uppercase" fontSize="24px" color="energieCyan">
              {t("dons:historique")}
            </Text>
          </Stack>
          <Formiz id="dons-form" autoForm onValidSubmit={handleSearchDon} connect={form}>
            <HStack>
              <FieldFactory
                type="text"
                textAlign="center"
                name="search"
                placeholder={`${t("dons:search")}`}
              />
              <ButtonsValider color={pageColor} onClick={form.submit} isDisabled={isLoadingDons} />
            </HStack>
          </Formiz>
          <HStack justifyContent="center" spacing={2}>
            <ButtonFilters
              maxH="40px"
              onClick={() =>
                setSelectedValue((old) => ({
                  ...old,
                  frequence: t("dons:frequence.tous.value"),
                }))
              }
              bgColor={selectedValue === t("dons:frequence.tous.value") ? "energieCyan" : "black"}
              color={selectedValue === t("dons:frequence.tous.value") ? "black" : "energieCyan"}
              text={t("dons:frequence.tous.label")}
            />
            <ButtonFilters
              maxH="40px"
              onClick={() =>
                setSelectedValue((old) => ({
                  ...old,
                  frequence: t("dons:frequence.ponctuel.value"),
                }))
              }
              bgColor={
                selectedValue === t("dons:frequence.ponctuel.value") ? "energieCyan" : "black"
              }
              color={selectedValue === t("dons:frequence.ponctuel.value") ? "black" : "energieCyan"}
              text={t("dons:frequence.ponctuel.label")}
            />
            <ButtonFilters
              maxH="40px"
              onClick={() =>
                setSelectedValue((old) => ({
                  ...old,
                  frequence: t("dons:frequence.mensuel.value"),
                }))
              }
              bgColor={
                selectedValue === t("dons:frequence.mensuel.value") ? "energieCyan" : "black"
              }
              color={selectedValue === t("dons:frequence.mensuel.value") ? "black" : "energieCyan"}
              text={t("dons:frequence.mensuel.label")}
            />
            <ButtonFilters
              maxH="40px"
              onClick={() =>
                setSelectedValue((old) => ({
                  ...old,
                  frequence: t("dons:frequence.permanent.value"),
                }))
              }
              bgColor={
                selectedValue === t("dons:frequence.permanent.value") ? "energieCyan" : "black"
              }
              color={
                selectedValue === t("dons:frequence.permanent.value") ? "black" : "energieCyan"
              }
              text={t("dons:frequence.permanent.label")}
            />
          </HStack>
          {data?.length === 0 && !isLoadingDons ? (
            <Stack
              justifyContent="center"
              width="100%"
              border="#36A9E1 inset 1px"
              minHeight="180px"
              textAlign="center"
              borderRadius="10px"
              alignContent="center"
            >
              <Text color="#FFF" fontSize="16px">
                {t("notifications:historique.notfound")}
              </Text>
            </Stack>
          ) : null}
          {!isLoadingDons && !isLoading ? (
            data?.map((don) =>
              isMobile ? (
                <CardHistoriqueMobile
                  key={`mobile_${don.dons.id}_${state?.id ?? ""}`}
                  user={user}
                  don={don}
                  defaultIsOpen={don.dons.id === Number(state?.id)}
                />
              ) : (
                <CardHistorique key={don.dons.id} user={user} don={don} />
              )
            )
          ) : (
            <Spinner />
          )}
        </Stack>
      </Stack>
      <Footer />
    </SlideIn>
  );
}
