import React from "react";
import {
  Tr,
  Stack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Th,
  Tbody,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useGetCollectifsAdmin } from "App/collectifs/services/useGetCollectifsAdmin";
import { useTranslation } from "react-i18next";
import { RowCollectif } from "./RowCollectif";

function FormCollectifsAdmin() {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { collectifs } = useGetCollectifsAdmin();

  return (
    <Stack mx="auto" p={isMobile ? 4 : 12} minH="500px" w={isMobile ? "100%" : "80%"}>
      <TableContainer overflow="scroll">
        <Table variant="simple">
          <TableCaption>{t("account:admin.utilisateurs")}</TableCaption>
          <Thead>
            <Tr>
              <Th minW="300px">{t("account:identite")}</Th>
              <Th minW="300px">{t("account:profil.title")}</Th>
              <Th>{t("account:statut")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {collectifs?.map((usr) => (
              <RowCollectif key={usr.id} user={usr} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export { FormCollectifsAdmin };
