import Axios from "axios";
import { APPENV } from "config/config";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateNotifications = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data) =>
      Axios.post(
        `${APPENV.HOST_RB_API}/notifications/${data?.id}`,
        data
      ),
    {
      onError: () => {
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['notifications'] })
        await queryClient.invalidateQueries({ queryKey: ['user'] })
      },
    }
  );

};
