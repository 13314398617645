/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconChecked({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.02181 14.7593C7.17934 14.9146 7.38849 15 7.61154 15C7.83459 15 8.04374 14.9146 8.20127 14.7593L21.7564 1.40283C22.0814 1.08261 22.0814 0.561522 21.7564 0.240652C21.5989 0.0854348 21.3898 0 21.1667 0C20.9436 0 20.7345 0.0854348 20.577 0.240652L8.2198 12.4167L7.61154 13.0161L7.00328 12.4167L1.42369 6.91891C1.26616 6.7637 1.05701 6.67826 0.83396 6.67826C0.610909 6.67826 0.401757 6.7637 0.244231 6.91891C0.0867054 7.07413 0 7.28022 0 7.5C0 7.71978 0.0867054 7.92587 0.244231 8.08109L7.02181 14.7593Z"
        fill={color}
      />
    </chakra.svg>
  );
}
IconChecked.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconChecked;
