import React from "react";
import { Center, Divider, Highlight, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsDot, BsSearch, BsDownload } from "react-icons/bs";
import IconEnergie from "components/Icons/icons-generated/IconEnergie";
import { useAuthentificationContext } from "context/auth-context";
import PropTypes from "prop-types";
import IconChecked from "components/Icons/icons-generated/IconChecked";
import { useNavigate } from "react-router";
import { useUpdateNotifications } from "./services/useUpdateNotifications";

export function CardNotif({ onClose, notif, color = "#FFFFFF" }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthentificationContext();
  const { mutate } = useUpdateNotifications();
  const handleClick = () => {
    onClose();
    mutate(notif?.notifications);
    if (isMessagerie) {
      return navigate(`/dashboard/dons?donsId=${notif?.messagerie?.dons}`, {
        state: { id: `${notif?.messagerie?.dons}` },
        replace: true,
      });
    }
    return navigate(`/dashboard/historique`, {
      state: { id: `${notif?.dons?.id}` },
      replace: true,
    });
  };

  const isMessagerie = notif?.messagerie?.commentaire;
  const emetteur = isMessagerie ? notif?.emetteurMessagerie : notif?.usager;
  const destinataire = isMessagerie ? notif?.destinataireMessagerie : notif?.destinataire;
  return (
    <HStack color={color} justifyContent="space-between" w="100%">
      {!notif?.notifications?.lu ? (
        <Stack>
          <BsDot color="red" />
        </Stack>
      ) : (
        <Stack width="18px" />
      )}
      <Stack width="18px">
        <IconEnergie color={color} />
      </Stack>
      <Stack flex={4}>
        <Text fontSize="14px">
          {!isMessagerie ? (
            <Highlight
              query={[`${notif?.dons?.quantite}`, t("notifications:energie")]}
              styles={{ color: "#FFFFFF", fontWeight: "bold" }}
            >
              {destinataire?.id === user?.id
                ? t("notifications:recue", { nb: notif?.dons?.quantite })
                : t("notifications:envoye", { nb: notif?.dons?.quantite })}
            </Highlight>
          ) : (
            t("messagerie:nouveauMessage")
          )}
        </Text>
        {destinataire?.id === user?.id ? (
          <Text fontSize="12px">{emetteur?.clePublique}</Text>
        ) : (
          <Text fontSize="12px">{destinataire?.clePublique}</Text>
        )}
      </Stack>
      <Center height="50px">
        <Divider orientation="vertical" />
      </Center>
      {!isMessagerie ? (
        <HStack spacing={4} w="100%" h="100%" flex={1} justifyContent="center">
          {notif?.dons?.statusDon === "RECU" ? (
            <>
              <Icon
                as={BsSearch}
                _hover={{
                  color: "rgba(255,255,255,0.4)",
                  cursor: "pointer",
                }}
                onClick={handleClick}
                boxSize={16}
                h="16px"
                w="16px"
              />
              <Icon
                as={BsDownload}
                _hover={{
                  color: "rgba(255,255,255,0.4)",
                  cursor: "pointer",
                }}
                onClick={handleClick}
                boxSize={16}
                h="16px"
                w="16px"
              />
            </>
          ) : null}
          {notif?.dons?.statusDon === "ACCEPTE" ? (
            <>
              <Icon
                as={BsSearch}
                _hover={{
                  color: "rgba(255,255,255,0.4)",
                  cursor: "pointer",
                }}
                onClick={handleClick}
                boxSize={16}
                h="16px"
                w="16px"
              />
              <Icon as={IconChecked} boxSize={16} h="22px" w="22px" />
            </>
          ) : null}
          {notif?.dons?.statusDon === "REFUSE" ? (
            <Icon
              as={BsSearch}
              _hover={{
                color: "rgba(255,255,255,0.4)",
                cursor: "pointer",
              }}
              onClick={handleClick}
              boxSize={16}
              h="16px"
              w="16px"
            />
          ) : null}
        </HStack>
      ) : (
        <HStack spacing={4} w="100%" h="100%" flex={1} justifyContent="center">
          <Icon
            as={BsSearch}
            _hover={{
              color: "rgba(255,255,255,0.4)",
              cursor: "pointer",
            }}
            onClick={handleClick}
            boxSize={16}
            h="16px"
            w="16px"
          />
        </HStack>
      )}
    </HStack>
  );
}
CardNotif.propTypes = {
  notif: PropTypes.instanceOf(Object).isRequired,
  color: PropTypes.string,
  onClose: PropTypes.func,
};
