/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconMain({ color = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_521_417)">
        <path
          d="M25.0677 15.6885L21.0648 17.7261C20.4003 16.7047 19.2524 16.0914 18.0217 16.0991L14.1355 16.1275C13.5442 16.1309 12.9597 16.0038 12.425 15.7547L12.0226 15.5666C10.002 14.6148 7.64434 14.6552 5.65858 15.6748L5.65422 15.0538C5.65161 14.6741 5.33719 14.3691 4.95223 14.3717L0.69155 14.4035C0.306591 14.4069 -0.00259641 14.717 1.64389e-05 15.0958L0.0705633 24.3179C0.0731762 24.6968 0.386718 25.0026 0.771678 25C0.771678 25 0.771678 25 0.772549 25L5.03323 24.9682C5.41819 24.9656 5.72738 24.6555 5.72477 24.2759L5.72215 23.9125L6.72723 23.3499C7.11741 23.1248 7.58076 23.0587 8.01972 23.1652L14.6886 24.7646C14.7034 24.7681 14.7191 24.7715 14.7347 24.7741C16.2763 25.0343 17.8623 24.8016 19.2602 24.1092C19.2898 24.0946 19.3177 24.0774 19.3447 24.0594L28.7022 17.6196C29.0044 17.4117 29.0897 17.0071 28.8972 16.697C28.0977 15.4068 26.4124 14.9627 25.0677 15.6885ZM1.45886 23.6213L1.39876 15.7736L4.26593 15.7521L4.32602 23.5998L1.45799 23.6213H1.45886ZM18.5878 22.9101C17.4782 23.4504 16.2258 23.6342 15.0047 23.4383L14.9925 23.4366L8.34807 21.8363C7.56247 21.6448 6.73246 21.7616 6.03221 22.1619L5.70909 22.3423L5.67077 17.2725C7.36564 16.1095 9.56217 15.9334 11.426 16.8095L11.8284 16.9976C12.553 17.3352 13.3456 17.5079 14.1468 17.5036L18.033 17.4744C18.9754 17.4684 19.8159 18.0559 20.1233 18.9338L14.5457 18.975C14.1608 18.9776 13.8507 19.2869 13.8533 19.6665C13.8551 20.0007 14.1007 20.285 14.4351 20.34C14.4752 20.3468 14.5153 20.3494 14.5562 20.3494L20.9437 20.3022C21.3287 20.2996 21.6379 19.9895 21.6353 19.6098C21.6335 19.4088 21.6144 19.2087 21.5787 19.0111L25.7148 16.9057C25.72 16.9032 25.7252 16.9006 25.7305 16.8971C26.2069 16.6395 26.7878 16.6592 27.2451 16.9495L18.5878 22.9092V22.9101Z"
          fill={color}
        />
        <path
          d="M21.0351 10.9598V7.22495L24.3143 5.35751C24.5877 5.20203 24.6809 4.85672 24.5233 4.587C24.3656 4.31727 24.0155 4.22536 23.742 4.38084L20.462 6.24828L17.1821 4.38084C16.9086 4.22536 16.5593 4.31727 16.4008 4.587C16.2432 4.85672 16.3364 5.20117 16.6098 5.35751L19.8898 7.22495V10.9598C19.8898 11.2708 20.1459 11.5233 20.462 11.5233C20.7782 11.5233 21.0334 11.2708 21.0334 10.9598H21.0351Z"
          fill={color}
        />
        <path
          d="M23.6654 7.70941C23.4277 7.6467 23.1794 7.67849 22.9661 7.80046C22.7527 7.92158 22.6003 8.11743 22.5367 8.35193C22.4731 8.58644 22.5053 8.83125 22.629 9.0417C22.7997 9.3329 23.1098 9.49611 23.4285 9.49611C23.5844 9.49611 23.743 9.45659 23.8875 9.37413C24.1009 9.25301 24.2533 9.05716 24.3169 8.82266C24.3805 8.58815 24.3483 8.34334 24.2246 8.13289C24.1018 7.92244 23.9032 7.77212 23.6654 7.70941Z"
          fill={color}
        />
        <path
          d="M20.2252 4.40231C20.3044 4.42292 20.3854 4.43323 20.4647 4.43323C20.6241 4.43323 20.7817 4.392 20.9237 4.31125C21.137 4.19014 21.2895 3.99429 21.353 3.75978C21.4166 3.52528 21.3844 3.28047 21.2607 3.07001C21.0064 2.63623 20.442 2.48676 20.0022 2.73759C19.7888 2.8587 19.6364 3.05455 19.5728 3.28906C19.5092 3.52356 19.5415 3.76837 19.6651 3.97882C19.7879 4.18928 19.9865 4.3396 20.2243 4.40231H20.2252Z"
          fill={color}
        />
        <path
          d="M17.7386 7.70941C17.5009 7.6467 17.2526 7.67849 17.0392 7.80046C16.8259 7.92158 16.6734 8.11743 16.6099 8.35193C16.5463 8.58644 16.5785 8.83125 16.7022 9.0417C16.8729 9.3329 17.183 9.49611 17.5017 9.49611C17.6576 9.49611 17.8161 9.45659 17.9607 9.37413C18.4005 9.1233 18.5521 8.56668 18.2978 8.13289C18.175 7.92244 17.9764 7.77212 17.7386 7.70941Z"
          fill={color}
        />
        <path
          d="M20.4586 0C19.2366 0 18.0295 0.320403 16.9661 0.925131C16.1491 1.3907 15.448 2.00058 14.8828 2.7376C14.3376 3.4497 13.9421 4.24856 13.707 5.11356C13.4718 5.97856 13.4091 6.86589 13.5206 7.75151C13.6364 8.66891 13.9334 9.5425 14.4055 10.3491C15.0308 11.4177 15.934 12.3007 17.0183 12.9046C18.0687 13.4896 19.2619 13.7996 20.469 13.7996C21.6762 13.7996 22.8981 13.4801 23.9607 12.8745C24.7776 12.4089 25.4787 11.7991 26.044 11.0621C26.5892 10.35 26.9846 9.55109 27.2198 8.68609C27.4549 7.82109 27.5176 6.93375 27.4062 6.04814C27.2903 5.13074 26.9933 4.25715 26.5213 3.45056C25.8959 2.38283 24.9928 1.49893 23.9093 0.895066C22.8589 0.310095 21.6657 0 20.4586 0ZM15.4985 9.72718C13.9151 7.02223 14.8549 3.56394 17.5975 2.0023C18.5007 1.48777 19.4866 1.24382 20.4586 1.24382C22.44 1.24382 24.3674 2.25828 25.4291 4.07246C27.0125 6.77742 26.0727 10.2357 23.3301 11.7973C22.4269 12.3119 21.441 12.5558 20.469 12.5558C18.4876 12.5558 16.5602 11.5414 15.4985 9.72718Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_521_417">
          <rect width="29" height="25" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
IconMain.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconMain;
