import React from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { Header } from "components/Layout/Header";
import { Footer } from "components/Layout/Footer";
import { FormProfil } from "./_partials/FormProfil";

export function PageProfil() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <SlideIn>
      <Header />
      <Box p={4} w={isMobile ? "100%" : "70%"} mx="auto">
        <FormProfil />
      </Box>
      <Footer />
    </SlideIn>
  );
}
