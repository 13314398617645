/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconEnergieProposerDon({ color = "#EA730C", dropShadowColor = "#E71D73", ...rest }) {
  return (
    <chakra.svg
      width="58"
      height="59"
      viewBox="0 0 58 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g filter="url(#filter0_d_639_2621)">
        <path
          d="M30.6365 41.5205V30.6941L40.0107 25.2808C40.7925 24.8302 41.0589 23.8292 40.6082 23.0473C40.1576 22.2655 39.1567 21.9991 38.3749 22.4497L28.9982 27.863L19.6215 22.4497C18.8396 21.9991 17.8412 22.2655 17.3881 23.0473C16.9374 23.8292 17.2038 24.8277 17.9856 25.2808L27.3623 30.6941V41.5205C27.3623 42.4219 28.0943 43.154 28.9982 43.154C29.902 43.154 30.6315 42.4219 30.6315 41.5205H30.6365Z"
          fill={color}
        />
        <path
          d="M38.1532 32.0985C37.4735 31.9167 36.7639 32.0089 36.1539 32.3624C35.5439 32.7135 35.1081 33.2812 34.9264 33.961C34.7446 34.6408 34.8367 35.3504 35.1903 35.9605C35.6783 36.8046 36.5647 37.2777 37.476 37.2777C37.9216 37.2777 38.3748 37.1631 38.7881 36.9241C39.3981 36.573 39.8338 36.0053 40.0156 35.3255C40.1973 34.6458 40.1052 33.9361 39.7517 33.3261C39.4006 32.716 38.8329 32.2803 38.1532 32.0985Z"
          fill={color}
        />
        <path
          d="M28.3181 22.5121C28.5447 22.5719 28.7762 22.6018 29.0028 22.6018C29.4585 22.6018 29.9091 22.4823 30.315 22.2482C30.925 21.8971 31.3607 21.3294 31.5424 20.6496C31.7242 19.9699 31.6321 19.2602 31.2785 18.6502C30.5515 17.3927 28.9381 16.9595 27.6807 17.6865C27.0707 18.0376 26.635 18.6054 26.4532 19.2851C26.2715 19.9649 26.3636 20.6745 26.7172 21.2846C27.0682 21.8946 27.6359 22.3304 28.3156 22.5121H28.3181Z"
          fill={color}
        />
        <path
          d="M21.2123 32.0985C20.5326 31.9167 19.823 32.0089 19.2129 32.3624C18.6029 32.7135 18.1672 33.2812 17.9855 33.961C17.8037 34.6408 17.8958 35.3504 18.2494 35.9605C18.7374 36.8046 19.6238 37.2777 20.535 37.2777C20.9807 37.2777 21.4339 37.1631 21.8472 36.9241C23.1045 36.197 23.5378 34.5835 22.8107 33.3261C22.4597 32.716 21.892 32.2803 21.2123 32.0985Z"
          fill={color}
        />
        <path
          d="M28.9855 9.75098C25.4923 9.75098 22.0414 10.6797 19.0013 12.4327C16.6658 13.7823 14.6615 15.5501 13.0456 17.6866C11.487 19.7508 10.3566 22.0664 9.68434 24.5739C9.01457 27.0838 8.8353 29.6559 9.15151 32.2206C9.48266 34.8799 10.3317 37.4122 11.6812 39.7503C13.4689 42.8478 16.0508 45.4076 19.1507 47.158C22.1534 48.8537 25.5645 49.7526 29.0154 49.7526C32.4663 49.7526 35.9595 48.8263 38.9971 47.0709C41.3326 45.7213 43.3369 43.9534 44.9528 41.817C46.5114 39.7528 47.6418 37.4371 48.314 34.9297C48.9863 32.4223 49.1656 29.8501 48.8469 27.283C48.5157 24.6237 47.6667 22.0913 46.3172 19.7532C44.5295 16.6582 41.9475 14.096 38.8502 12.3455C35.845 10.6474 32.4339 9.75098 28.9855 9.75098ZM14.8059 37.9476C10.2794 30.1066 12.9659 20.0819 20.8039 15.5551C23.3859 14.0636 26.2044 13.3565 28.983 13.3565C34.6474 13.3565 40.1574 16.2971 43.1925 21.556C47.719 29.397 45.0324 39.4216 37.192 43.9484C34.61 45.4399 31.7915 46.1471 29.0129 46.1471C23.3485 46.1471 17.8385 43.2064 14.8034 37.9476H14.8059Z"
          fill={color}
        />
      </g>
      <defs>
        {color === "#E71D73" ? (
          <filter
            id="filter0_d_639_2621"
            x="0"
            y="0.750977"
            width="58"
            height="58.0016"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.905882 0 0 0 0 0.113725 0 0 0 0 0.45098 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_212_2415" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_212_2415"
              result="shape"
            />
          </filter>
        ) : null}
        {color === "#EA740C" ? (
          <filter
            id="filter0_d_639_2621"
            x="0"
            y="0.750977"
            width="58"
            height="58.0016"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.917647 0 0 0 0 0.450886 0 0 0 0 0.0470588 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_639_2621" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_639_2621"
              result="shape"
            />
          </filter>
        ) : null}
      </defs>
    </chakra.svg>
  );
}
IconEnergieProposerDon.propTypes = {
  color: PropTypes.string,
  dropShadowColor: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconEnergieProposerDon;
