// Les propriétés des selects
export const ReactSelect = {
  baseStyle: {
    input: (styles) => ({
      ...styles,
      color: '#B0B0B0',

    }),
    singleValue: (styles, {isDisabled}) => ({
      ...styles,
      color: isDisabled ?'#FFF' :"#B0B0B0",
    }),
    control: (styles, {isDisabled}) => ({
      ...styles,
      backgroundColor: isDisabled ? "#000000" : "#191919",
      opacity: isDisabled ? 0.6 : 1,
      border: isDisabled ? 'solid #323232 2px' : 'solid #B0B0B0 1px',
      borderRadius: 14,
      ":hover": {
        borderColor: "#B0B0B0",
      },
      ":autofill": {
        borderColor: "#B0B0B0",
      },
      ":active": {
        borderColor: "#B0B0B0",
      },
    }),
    placeholder: (styles, {isDisabled}) => ({
      ...styles,
      color: isDisabled ?'#FFF' :"#B0B0B0",
    }),
    option: (styles) => ({
      ...styles,
      color:'white',
      backgroundColor:"#191919",
      borderBottom: "1px solid #EA5B0C66",
      ":autofill": {
        backgroundColor:"#191919",
      },
      ":hover": {
        color: "#191919",
        backgroundColor:"#fff",
      },
      ":active": {
        backgroundColor:"#191919",
      },
    }),
    // Les tags des valeurs sélectionnées
    multiValue: (styles) => ({
      ...styles,
      color:'white',
    }),
    // Le label des tags des valeurs sélectionnées
    multiValueLabel: (styles) => ({
      ...styles,
      color:'white',
    }),
    // La div pour remove un des tags des valeurs sélectionnées
    multiValueRemove: (styles) => ({
      ...styles,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
      backgroundColor:"#191919",
      color:'white',
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
  },
  defaultProps: {
    variant: "default",
  },
};
