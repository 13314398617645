import React from "react";
import { fieldDefaultProps, fieldPropTypes, useField } from "@formiz/core";

import PropTypes from "prop-types";
import { FormGroup } from "components/form-group";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./css/phone.css";

const propTypes = {
  country: PropTypes.string,
  defaultCountry: PropTypes.string,
  ...fieldPropTypes,
};

const defaultProps = {
  country: "FR",
  defaultCountry: "FR",
  ...fieldDefaultProps,
};

export function FieldPhone(props) {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } =
    useField(props);
  const {
    label,
    required,
    isDisabled,
    country,
    defaultCountry,
    ...otherProps
  } = props;
  const showError = !isValid && isSubmitted;

  const formGroupProps = {
    errorMessage,
    id,
    isRequired: !!required,
    label,
    showError,
    isDisabled,
    ...otherProps,
  };

  return (
    <FormGroup {...formGroupProps}>
      <PhoneInput
        buttonStyle={{
          borderRadius: "30px",
          backgroundColor: "#191919",
        }}
        inputStyle={{
          borderRadius: "30px",
          color: "#8A8A8A",
          backgroundColor: "#191919",
          minHeight: "2.5rem",
          width: "100%",
        }}
        value={value ?? ""}
        onChange={(e) => {
          setValue(e);
        }}
        enableAreaCodes
        country={country?.toLowerCase()}
        defaultCountry={defaultCountry}
      />
    </FormGroup>
  );
}

FieldPhone.propTypes = propTypes;
FieldPhone.defaultProps = defaultProps;
