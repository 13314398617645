/* eslint-disable max-len */
import React from "react";

import { chakra } from "@chakra-ui/react";
import PropTypes from "prop-types";

export function Logo({ text, ...rest }) {
  return (
    <chakra.svg width="257" height="173" viewBox="0 0 257 173" {...rest}>
      <text x="50%" dominantBaseline="middle" textAnchor="middle" y="165" fill="#B0B0B0">
        {text}
      </text>
      {/* <path
        d="M60 163.58H63.75C64.96 163.58 65.92 163.91 66.62 164.55C67.32 165.2 67.66 166.02 67.66 167.01C67.66 168 67.31 168.82 66.62 169.47C65.93 170.12 64.96 170.44 63.74 170.44H61.13V172.73H60V163.58ZM63.85 169.44C64.63 169.44 65.26 169.21 65.75 168.74C66.24 168.27 66.48 167.7 66.48 167.01C66.48 166.32 66.24 165.75 65.75 165.28C65.26 164.81 64.64 164.58 63.87 164.58H61.13V169.43H63.85V169.44Z"
        fill="#B0B0B0"
      />
      <path
        d="M69.95 166.76C70.66 166.06 71.51 165.71 72.5 165.71C73.49 165.71 74.35 166.06 75.05 166.76C75.76 167.46 76.11 168.3 76.11 169.29C76.11 170.28 75.76 171.11 75.05 171.81C74.34 172.51 73.49 172.86 72.5 172.86C71.51 172.86 70.65 172.51 69.95 171.81C69.24 171.11 68.89 170.27 68.89 169.29C68.89 168.31 69.24 167.46 69.95 166.76ZM70.73 171.08C71.22 171.57 71.81 171.82 72.51 171.82C73.21 171.82 73.8 171.57 74.29 171.08C74.78 170.59 75.02 169.99 75.02 169.29C75.02 168.59 74.78 168 74.29 167.5C73.8 167 73.21 166.75 72.51 166.75C71.81 166.75 71.22 167 70.73 167.5C70.24 168 70 168.6 70 169.29C70 169.98 70.24 170.58 70.73 171.08Z"
        fill="#B0B0B0"
      />
      <path
        d="M77.49 165.85H78.56V169.87C78.56 171.17 79.15 171.82 80.32 171.82C80.89 171.82 81.35 171.63 81.72 171.24C82.09 170.85 82.27 170.33 82.27 169.67V165.84H83.34V172.71H82.27V171.65C81.78 172.45 81.04 172.85 80.05 172.85C79.21 172.85 78.57 172.59 78.14 172.08C77.71 171.57 77.48 170.9 77.48 170.08V165.83L77.49 165.85Z"
        fill="#B0B0B0"
      />
      <path
        d="M85.21 165.85H86.28V167.3C86.49 166.83 86.82 166.45 87.27 166.15C87.72 165.85 88.28 165.71 88.94 165.71V166.9C88.17 166.9 87.53 167.12 87.03 167.57C86.53 168.02 86.28 168.73 86.28 169.69V172.72H85.21V165.85Z"
        fill="#B0B0B0"
      /> */}
      {/* <path
        d="M93.88 166.77H92.85V165.85H93.88V164.33L94.95 164.16V165.85H97.31V166.77H94.95V170.47C94.95 171.43 95.28 171.91 95.92 171.91C96.11 171.91 96.3 171.88 96.48 171.81C96.67 171.74 96.81 171.68 96.9 171.63L97.04 171.53L97.39 172.36C96.89 172.7 96.36 172.87 95.81 172.87C95.21 172.87 94.73 172.69 94.39 172.33C94.04 171.97 93.87 171.41 93.87 170.67V166.77H93.88Z"
        fill="#B0B0B0"
      />
      <path
        d="M99.19 166.76C99.9 166.06 100.75 165.71 101.74 165.71C102.73 165.71 103.59 166.06 104.29 166.76C105 167.46 105.35 168.3 105.35 169.29C105.35 170.28 105 171.11 104.29 171.81C103.58 172.51 102.73 172.86 101.74 172.86C100.75 172.86 99.89 172.51 99.19 171.81C98.48 171.11 98.13 170.27 98.13 169.29C98.13 168.31 98.48 167.46 99.19 166.76ZM99.97 171.08C100.46 171.57 101.05 171.82 101.75 171.82C102.45 171.82 103.04 171.57 103.53 171.08C104.02 170.59 104.26 169.99 104.26 169.29C104.26 168.59 104.02 168 103.53 167.5C103.04 167 102.45 166.75 101.75 166.75C101.05 166.75 100.46 167 99.97 167.5C99.48 168 99.24 168.6 99.24 169.29C99.24 169.98 99.48 170.58 99.97 171.08Z"
        fill="#B0B0B0"
      />
      <path
        d="M106.72 165.85H107.79V169.87C107.79 171.17 108.38 171.82 109.55 171.82C110.12 171.82 110.58 171.63 110.95 171.24C111.32 170.85 111.5 170.33 111.5 169.67V165.84H112.57V172.71H111.5V171.65C111.01 172.45 110.27 172.85 109.28 172.85C108.44 172.85 107.81 172.59 107.37 172.08C106.93 171.57 106.71 170.9 106.71 170.08V165.83L106.72 165.85Z"
        fill="#B0B0B0"
      />
      <path
        d="M114.62 170.93C115.19 171.52 115.91 171.82 116.78 171.82C117.27 171.82 117.68 171.72 118.01 171.53C118.34 171.34 118.5 171.08 118.5 170.77C118.5 170.61 118.46 170.47 118.37 170.35C118.28 170.23 118.14 170.12 117.96 170.04C117.78 169.96 117.61 169.89 117.45 169.84C117.29 169.79 117.09 169.74 116.82 169.67C116.43 169.58 116.13 169.49 115.9 169.41C115.67 169.33 115.42 169.21 115.16 169.06C114.9 168.91 114.7 168.72 114.58 168.49C114.46 168.26 114.4 167.98 114.4 167.66C114.4 167.08 114.65 166.6 115.16 166.24C115.67 165.88 116.24 165.7 116.87 165.7C117.71 165.7 118.5 165.95 119.24 166.46L118.72 167.29C118.18 166.92 117.57 166.74 116.89 166.74C116.52 166.74 116.2 166.83 115.92 167C115.64 167.17 115.5 167.39 115.5 167.64C115.5 167.79 115.54 167.92 115.61 168.04C115.68 168.16 115.82 168.25 116 168.33C116.18 168.41 116.34 168.47 116.46 168.5C116.59 168.53 116.79 168.58 117.06 168.65C117.44 168.73 117.75 168.82 118 168.91C118.25 169 118.51 169.12 118.78 169.28C119.05 169.44 119.26 169.63 119.39 169.88C119.53 170.12 119.59 170.41 119.59 170.74C119.59 171.43 119.31 171.95 118.74 172.31C118.17 172.67 117.52 172.85 116.78 172.85C115.66 172.85 114.76 172.49 114.08 171.78L114.6 170.92L114.62 170.93Z"
        fill="#B0B0B0"
      /> */}
      {/* <path
        d="M124.55 165.85H125.62V166.95C125.85 166.55 126.15 166.25 126.54 166.03C126.93 165.81 127.34 165.71 127.8 165.71C128.91 165.71 129.65 166.17 130.02 167.08C130.26 166.65 130.59 166.31 131.03 166.07C131.47 165.83 131.92 165.71 132.39 165.71C133.23 165.71 133.86 165.95 134.28 166.44C134.7 166.92 134.92 167.62 134.92 168.52V172.73H133.85V168.71C133.85 168.09 133.73 167.61 133.48 167.27C133.23 166.93 132.8 166.76 132.17 166.76C131.62 166.76 131.17 166.94 130.82 167.3C130.47 167.66 130.28 168.13 130.28 168.72V172.73H129.21V168.71C129.21 168.09 129.09 167.61 128.84 167.27C128.59 166.93 128.16 166.76 127.53 166.76C126.99 166.76 126.54 166.96 126.18 167.35C125.82 167.75 125.64 168.23 125.64 168.82V172.73H124.57V165.86L124.55 165.85Z"
        fill="#B0B0B0"
      />
      <path
        d="M137.24 166.75C137.88 166.05 138.66 165.71 139.6 165.71C140.7 165.71 141.54 166.15 142.11 167.04V165.85H143.18V172.72H142.11V171.55C141.55 172.43 140.71 172.86 139.6 172.86C138.66 172.86 137.87 172.51 137.24 171.82C136.61 171.12 136.29 170.28 136.29 169.29C136.29 168.3 136.61 167.45 137.24 166.75ZM138.07 171.09C138.53 171.57 139.09 171.82 139.77 171.82C140.45 171.82 140.98 171.58 141.44 171.1C141.89 170.62 142.12 170.02 142.12 169.29C142.12 168.56 141.9 167.97 141.45 167.48C141 166.99 140.45 166.75 139.78 166.75C139.11 166.75 138.54 167 138.08 167.48C137.62 167.96 137.4 168.57 137.4 169.29C137.4 170.01 137.63 170.6 138.08 171.09H138.07Z"
        fill="#B0B0B0"
      />
      <path
        d="M145.1 163.49C145.24 163.35 145.4 163.28 145.6 163.28C145.8 163.28 145.96 163.35 146.1 163.49C146.24 163.63 146.3 163.8 146.3 164C146.3 164.2 146.23 164.35 146.1 164.49C145.96 164.63 145.8 164.7 145.6 164.7C145.4 164.7 145.23 164.63 145.1 164.49C144.96 164.35 144.9 164.18 144.9 164C144.9 163.82 144.97 163.63 145.1 163.49ZM145.05 165.85H146.12V172.72H145.05V165.85Z"
        fill="#B0B0B0"
      />
      <path
        d="M147.99 165.85H149.06V166.95C149.58 166.12 150.36 165.71 151.4 165.71C152.24 165.71 152.87 165.96 153.29 166.45C153.71 166.94 153.93 167.64 153.93 168.53V172.72H152.86V168.7C152.86 168.06 152.73 167.58 152.46 167.25C152.19 166.92 151.75 166.76 151.13 166.76C150.56 166.76 150.08 166.96 149.68 167.36C149.27 167.76 149.07 168.27 149.07 168.88V172.73H148V165.86L147.99 165.85Z"
        fill="#B0B0B0"
      />
      <path
        d="M155.94 166.77H154.91V165.85H155.94V164.33L157.01 164.16V165.85H159.37V166.77H157.01V170.47C157.01 171.43 157.34 171.91 157.98 171.91C158.17 171.91 158.36 171.88 158.54 171.81C158.73 171.74 158.87 171.68 158.96 171.63L159.1 171.53L159.45 172.36C158.95 172.7 158.42 172.87 157.87 172.87C157.27 172.87 156.79 172.69 156.45 172.33C156.1 171.97 155.93 171.41 155.93 170.67V166.77H155.94Z"
        fill="#B0B0B0"
      />
      <path
        d="M161.19 166.76C161.86 166.06 162.67 165.71 163.63 165.71C164.53 165.71 165.31 166.06 165.96 166.75C166.61 167.44 166.93 168.27 166.93 169.23L166.9 169.68H161.31C161.39 170.34 161.67 170.87 162.14 171.27C162.61 171.67 163.17 171.88 163.81 171.88C164.64 171.88 165.34 171.62 165.93 171.09L166.44 171.87C165.66 172.53 164.78 172.86 163.82 172.86C162.86 172.86 161.96 172.53 161.25 171.86C160.54 171.19 160.19 170.34 160.19 169.29C160.19 168.3 160.52 167.46 161.19 166.76ZM165.85 168.84C165.78 168.21 165.54 167.69 165.11 167.28C164.68 166.87 164.18 166.67 163.59 166.67C163 166.67 162.51 166.88 162.08 167.29C161.65 167.7 161.39 168.22 161.3 168.84H165.84H165.85Z"
        fill="#B0B0B0"
      />
      <path
        d="M168.45 165.85H169.52V166.95C170.04 166.12 170.82 165.71 171.86 165.71C172.7 165.71 173.33 165.96 173.75 166.45C174.17 166.94 174.39 167.64 174.39 168.53V172.72H173.32V168.7C173.32 168.06 173.19 167.58 172.92 167.25C172.65 166.92 172.21 166.76 171.59 166.76C171.03 166.76 170.54 166.96 170.14 167.36C169.74 167.76 169.53 168.27 169.53 168.88V172.73H168.46V165.86L168.45 165.85Z"
        fill="#B0B0B0"
      /> */}
      {/* <path
        d="M176.71 166.75C177.35 166.05 178.13 165.71 179.07 165.71C180.17 165.71 181.01 166.15 181.58 167.04V165.85H182.65V172.72H181.58V171.55C181.02 172.43 180.18 172.86 179.07 172.86C178.13 172.86 177.34 172.51 176.71 171.82C176.08 171.12 175.76 170.28 175.76 169.29C175.76 168.3 176.08 167.45 176.71 166.75ZM177.54 171.09C178 171.57 178.56 171.82 179.24 171.82C179.92 171.82 180.45 171.58 180.91 171.1C181.36 170.62 181.59 170.02 181.59 169.29C181.59 168.56 181.37 167.97 180.92 167.48C180.47 166.99 179.92 166.75 179.25 166.75C178.58 166.75 178.01 167 177.55 167.48C177.09 167.96 176.87 168.57 176.87 169.29C176.87 170.01 177.1 170.6 177.55 171.09H177.54Z"
        fill="#B0B0B0"
      />
      <path
        d="M184.53 165.85H185.6V166.95C186.12 166.12 186.9 165.71 187.94 165.71C188.78 165.71 189.41 165.96 189.83 166.45C190.25 166.94 190.47 167.64 190.47 168.53V172.72H189.4V168.7C189.4 168.06 189.27 167.58 189 167.25C188.73 166.92 188.29 166.76 187.67 166.76C187.11 166.76 186.62 166.96 186.22 167.36C185.82 167.76 185.61 168.27 185.61 168.88V172.73H184.54V165.86L184.53 165.85Z"
        fill="#B0B0B0"
      />
      <path
        d="M192.48 166.77H191.45V165.85H192.48V164.33L193.55 164.16V165.85H195.91V166.77H193.55V170.47C193.55 171.43 193.88 171.91 194.52 171.91C194.71 171.91 194.9 171.88 195.08 171.81C195.27 171.74 195.41 171.68 195.5 171.63L195.64 171.53L195.99 172.36C195.49 172.7 194.96 172.87 194.41 172.87C193.81 172.87 193.33 172.69 192.98 172.33C192.63 171.97 192.46 171.41 192.46 170.67V166.77H192.48Z"
        fill="#B0B0B0"
      /> */}
      <path
        d="M17.64 130C15.02 127.38 11.82 126.05 8.14 126.05H3.07C2.79 126.05 2.52 126.11 2.27 126.22C2.02 126.33 1.81 126.47 1.62 126.65C1.43 126.83 1.28 127.05 1.17 127.3C1.06 127.55 1 127.82 1 128.1C1 128.73 1.19 129.23 1.57 129.59C2.22 130.2 3.16 130.15 3.18 130.15H8.12C10.67 130.15 12.9 131.08 14.72 132.9C16.55 134.73 17.47 136.95 17.47 139.5C17.47 140.81 17.24 142.02 16.79 143.1C16.34 144.18 15.64 145.2 14.72 146.12C12.9 147.94 10.68 148.86 8.12 148.86H3.07C2.36 148.86 1.01 149.3 1.01 150.91C1.01 151.19 1.07 151.46 1.18 151.71C1.29 151.96 1.44 152.18 1.63 152.36C1.81 152.54 2.03 152.68 2.28 152.79C2.53 152.9 2.8 152.96 3.08 152.96H8.14C11.83 152.96 15.03 151.64 17.64 149.02C20.25 146.4 21.59 143.2 21.59 139.5C21.59 137.63 21.26 135.89 20.6 134.33C19.95 132.77 18.95 131.32 17.63 130H17.64Z"
        fill="#8CA0A0"
      />
      <path
        d="M29.74 126.05C29.18 126.05 28.69 126.25 28.29 126.65C27.89 127.05 27.69 127.53 27.69 128.1V150.91C27.69 151.47 27.89 151.96 28.29 152.36C28.69 152.76 29.17 152.96 29.74 152.96C30.31 152.96 30.79 152.76 31.19 152.36C31.59 151.96 31.79 151.48 31.79 150.91V128.1C31.79 127.54 31.59 127.05 31.19 126.65C30.79 126.25 30.31 126.05 29.74 126.05Z"
        fill="#8CA0A0"
      />
      <path
        d="M59.29 126.64C59.1 126.45 58.88 126.31 58.63 126.2C58.38 126.1 58.11 126.05 57.83 126.05C56.9 126.05 56.24 126.48 55.93 127.28L48.95 145.23L41.96 127.26C41.61 126.46 40.95 126.04 40.07 126.04C39.78 126.04 39.51 126.09 39.26 126.2C39.02 126.3 38.8 126.45 38.61 126.64C38.42 126.83 38.28 127.05 38.17 127.3C38.07 127.55 38.02 127.82 38.02 128.1C38.02 128.38 38.09 128.73 38.24 129.1L47.07 151.67C47.41 152.52 48.06 152.96 48.95 152.96C49.84 152.96 50.49 152.51 50.83 151.66L59.65 129.1C59.8 128.76 59.87 128.42 59.87 128.09C59.87 127.81 59.82 127.54 59.72 127.29C59.62 127.04 59.47 126.82 59.28 126.63L59.29 126.64Z"
        fill="#8CA0A0"
      />
      <path
        d="M67.45 126.05C66.89 126.05 66.4 126.25 66 126.65C65.6 127.05 65.4 127.54 65.4 128.1V150.91C65.4 151.47 65.6 151.96 66 152.36C66.4 152.76 66.88 152.96 67.45 152.96C68.02 152.96 68.5 152.76 68.9 152.36C69.3 151.96 69.5 151.48 69.5 150.91V128.1C69.5 127.54 69.3 127.05 68.9 126.65C68.5 126.25 68.02 126.05 67.45 126.05Z"
        fill="#8CA0A0"
      />
      <path
        d="M94.93 126.05C94.37 126.05 93.88 126.25 93.48 126.65C93.08 127.05 92.88 127.54 92.88 128.1V144.96L78.77 126.81V126.79C78.31 126.3 77.78 126.05 77.18 126.05C76.89 126.05 76.62 126.1 76.37 126.21C76.13 126.31 75.91 126.46 75.72 126.65C75.53 126.84 75.39 127.06 75.28 127.31C75.18 127.56 75.13 127.83 75.13 128.11V150.92C75.13 151.48 75.33 151.97 75.73 152.37C76.13 152.77 76.61 152.97 77.18 152.97C77.75 152.97 78.23 152.77 78.63 152.37C79.03 151.97 79.23 151.49 79.23 150.92V134.09L93.36 152.24L93.38 152.26C93.81 152.73 94.33 152.97 94.94 152.97C95.22 152.97 95.49 152.92 95.74 152.82C95.99 152.72 96.21 152.57 96.4 152.38C96.59 152.19 96.73 151.98 96.84 151.73C96.94 151.48 97 151.21 97 150.92V128.11C97 127.55 96.8 127.06 96.4 126.66C96 126.26 95.51 126.06 94.95 126.06L94.93 126.05Z"
        fill="#8CA0A0"
      />
      <path
        d="M111.62 137.46C111.06 137.46 110.57 137.66 110.17 138.06C109.77 138.46 109.57 138.94 109.57 139.51C109.57 140.08 109.77 140.56 110.17 140.96C110.57 141.36 111.05 141.56 111.62 141.56H116.65C117.21 141.56 117.7 141.36 118.1 140.96C118.5 140.56 118.7 140.08 118.7 139.51C118.7 138.94 118.5 138.46 118.1 138.06C117.7 137.66 117.21 137.46 116.65 137.46H111.62Z"
        fill="#8CA0A0"
      />
      <path
        d="M121.89 129.55C122.29 129.15 122.49 128.67 122.49 128.1C122.49 127.53 122.29 127.05 121.89 126.65C121.49 126.25 121 126.05 120.44 126.05H105.24C104.95 126.05 104.68 126.1 104.43 126.21C104.19 126.31 103.97 126.46 103.78 126.65C103.59 126.84 103.45 127.06 103.34 127.31C103.24 127.56 103.19 127.83 103.19 128.11V150.92C103.19 151.21 103.24 151.49 103.35 151.73C103.45 151.97 103.6 152.19 103.79 152.38C103.98 152.57 104.19 152.71 104.44 152.82C104.68 152.92 104.96 152.98 105.25 152.98H120.45C121.01 152.98 121.5 152.78 121.9 152.38C122.3 151.98 122.5 151.5 122.5 150.93C122.5 150.36 122.3 149.88 121.9 149.48C121.5 149.08 121.01 148.88 120.45 148.88H107.29V130.16H120.45C121.01 130.16 121.5 129.96 121.9 129.56L121.89 129.55Z"
        fill="#8CA0A0"
      />
      <path
        d="M164.51 137.46C163.95 137.46 163.46 137.66 163.06 138.06C162.66 138.46 162.46 138.94 162.46 139.51C162.46 140.08 162.66 140.56 163.06 140.96C163.46 141.36 163.94 141.56 164.51 141.56H169.54C170.1 141.56 170.59 141.36 170.99 140.96C171.39 140.56 171.59 140.08 171.59 139.51C171.59 138.94 171.39 138.46 170.99 138.06C170.59 137.66 170.11 137.46 169.54 137.46H164.51Z"
        fill="#8CA0A0"
      />
      <path
        d="M174.78 129.55C175.18 129.15 175.38 128.67 175.38 128.1C175.38 127.53 175.18 127.05 174.78 126.65C174.38 126.25 173.89 126.05 173.33 126.05H158.13C157.84 126.05 157.57 126.1 157.32 126.21C157.08 126.31 156.86 126.46 156.67 126.65C156.48 126.84 156.34 127.06 156.23 127.31C156.13 127.56 156.08 127.83 156.08 128.11V150.92C156.08 151.21 156.13 151.49 156.24 151.73C156.34 151.97 156.49 152.19 156.68 152.38C156.87 152.57 157.08 152.71 157.33 152.82C157.57 152.92 157.85 152.98 158.14 152.98H173.34C173.9 152.98 174.39 152.78 174.79 152.38C175.19 151.98 175.39 151.5 175.39 150.93C175.39 150.36 175.19 149.88 174.79 149.48C174.39 149.08 173.9 148.88 173.34 148.88H160.18V130.16H173.34C173.9 130.16 174.39 129.96 174.79 129.56L174.78 129.55Z"
        fill="#8CA0A0"
      />
      <path
        d="M148.03 126.05C147.47 126.05 146.98 126.25 146.58 126.65C146.18 127.05 145.98 127.54 145.98 128.1V144.96L131.87 126.81V126.79C131.41 126.3 130.88 126.05 130.28 126.05C129.99 126.05 129.72 126.1 129.47 126.21C129.23 126.31 129.01 126.46 128.82 126.65C128.63 126.84 128.49 127.06 128.38 127.31C128.28 127.56 128.23 127.83 128.23 128.11V150.92C128.23 151.48 128.43 151.97 128.83 152.37C129.23 152.77 129.71 152.97 130.28 152.97C130.85 152.97 131.33 152.77 131.73 152.37C132.13 151.97 132.33 151.49 132.33 150.92V134.09L146.46 152.24L146.48 152.26C146.91 152.73 147.43 152.97 148.04 152.97C148.32 152.97 148.59 152.92 148.84 152.82C149.09 152.72 149.31 152.57 149.5 152.38C149.69 152.19 149.83 151.98 149.94 151.73C150.04 151.48 150.1 151.21 150.1 150.92V128.11C150.1 127.55 149.9 127.06 149.5 126.66C149.1 126.26 148.61 126.06 148.05 126.06L148.03 126.05Z"
        fill="#8CA0A0"
      />
      <path
        d="M215.87 130.15C216.96 130.15 218 130.32 218.97 130.66C219.93 131 220.83 131.49 221.62 132.12C222.03 132.5 222.5 132.69 223.02 132.69C223.3 132.69 223.57 132.64 223.82 132.54C224.07 132.44 224.29 132.29 224.48 132.1C224.67 131.91 224.81 131.7 224.92 131.45C225.02 131.2 225.08 130.93 225.08 130.64C225.08 130.04 224.89 129.56 224.51 129.2C221.96 127.11 219.05 126.06 215.89 126.06C212.2 126.06 208.99 127.39 206.37 130.01C203.76 132.62 202.43 135.82 202.43 139.51C202.43 143.2 203.75 146.42 206.37 149.03C208.99 151.64 212.18 152.97 215.89 152.97C219.01 152.97 221.86 151.96 224.35 149.98L224.38 149.95C224.8 149.52 225.01 149.03 225.01 148.49V140.78C225.01 140.51 224.95 140.24 224.84 139.99C224.73 139.74 224.58 139.52 224.4 139.34C224.22 139.16 224 139.01 223.76 138.9C223.51 138.79 223.25 138.73 222.98 138.73C221.35 138.73 220.92 140.08 220.92 140.8V147.45C219.44 148.39 217.76 148.87 215.9 148.87C213.31 148.87 211.08 147.95 209.26 146.13C207.45 144.32 206.54 142.1 206.54 139.51C206.54 136.92 207.46 134.71 209.26 132.91C211.06 131.11 213.32 130.16 215.9 130.16L215.87 130.15Z"
        fill="#8CA0A0"
      />
      <path
        d="M255.29 130.95C255.02 130.48 254.57 130.14 254.04 129.99C253.51 129.85 252.96 129.92 252.48 130.2L242.61 135.9L232.74 130.2C232.26 129.93 231.71 129.85 231.18 129.99C230.65 130.13 230.21 130.47 229.93 130.95C229.66 131.42 229.58 131.98 229.72 132.51C229.86 133.04 230.2 133.48 230.68 133.76L240.55 139.46V150.86C240.55 151.99 241.47 152.91 242.6 152.91C243.15 152.91 243.66 152.7 244.05 152.31C244.44 151.92 244.65 151.41 244.65 150.86V139.46L254.52 133.76C254.99 133.49 255.33 133.04 255.48 132.51C255.62 131.98 255.55 131.43 255.27 130.95H255.29Z"
        fill="#8CA0A0"
      />
      <path
        d="M241.8 130.72C242.07 130.79 242.34 130.83 242.62 130.83C243.17 130.83 243.71 130.68 244.18 130.41C244.91 129.99 245.42 129.32 245.64 128.51C245.86 127.7 245.75 126.86 245.33 126.13C244.91 125.41 244.24 124.89 243.43 124.67C242.62 124.45 241.78 124.56 241.05 124.98C240.32 125.4 239.81 126.07 239.59 126.88C239.37 127.69 239.48 128.53 239.9 129.26C240.32 129.99 240.99 130.5 241.8 130.72Z"
        fill="#E71D73"
      />
      <path
        d="M198.6 149.79L193.52 141.65C194.13 141.28 194.69 140.86 195.16 140.39C195.71 139.86 196.17 139.27 196.54 138.64C196.91 138.01 197.19 137.34 197.37 136.63C197.56 135.92 197.65 135.19 197.65 134.45C197.65 133.31 197.43 132.22 196.99 131.21C196.55 130.2 195.95 129.31 195.2 128.55C194.45 127.79 193.56 127.19 192.55 126.75C191.54 126.31 190.45 126.09 189.3 126.09H182.96C182.67 126.09 182.4 126.14 182.16 126.24C181.92 126.34 181.7 126.49 181.52 126.67C181.33 126.86 181.19 127.08 181.09 127.32C180.99 127.57 180.94 127.83 180.94 128.11C180.94 128.71 181.13 129.2 181.5 129.55C182.17 130.18 183.18 130.14 183.21 130.14H189.31C189.89 130.14 190.45 130.25 190.97 130.48C191.49 130.71 191.95 131.02 192.34 131.4C192.73 131.79 193.04 132.25 193.26 132.77C193.48 133.29 193.6 133.85 193.6 134.44C193.6 135.03 193.49 135.57 193.26 136.09C193.03 136.61 192.73 137.07 192.34 137.46C191.96 137.85 191.5 138.17 190.98 138.39C190.46 138.61 189.9 138.73 189.31 138.73H182.97C182.68 138.73 182.41 138.78 182.17 138.88C181.93 138.98 181.71 139.13 181.53 139.31C181.34 139.5 181.2 139.72 181.1 139.96C181 140.21 180.95 140.47 180.95 140.75C180.95 141.35 181.14 141.84 181.51 142.19C182.18 142.82 183.19 142.78 183.22 142.78H189.46L195.17 151.92C195.61 152.57 196.19 152.9 196.9 152.9C197.18 152.9 197.45 152.85 197.69 152.75C197.94 152.65 198.16 152.5 198.34 152.32C198.52 152.14 198.67 151.92 198.77 151.68C198.87 151.44 198.92 151.17 198.92 150.88C198.92 150.5 198.81 150.13 198.6 149.78V149.79Z"
        fill="#8CA0A0"
      />
      <path
        d="M132.728 84.77V55.88L157.738 41.44C159.828 40.24 160.538 37.57 159.338 35.48C158.138 33.39 155.468 32.68 153.378 33.88L128.358 48.32L103.338 33.88C101.248 32.68 98.5881 33.39 97.3781 35.48C96.1781 37.57 96.8881 40.23 98.9781 41.44L123.998 55.88V84.77C123.998 87.18 125.958 89.13 128.358 89.13C130.758 89.13 132.718 87.18 132.718 84.77H132.728Z"
        fill="#8CA0A0"
      />
      <path
        d="M152.788 59.62C150.978 59.13 149.078 59.38 147.458 60.32C145.828 61.26 144.668 62.77 144.188 64.59C143.698 66.4 143.948 68.3 144.888 69.92C146.188 72.17 148.558 73.43 150.988 73.43C152.178 73.43 153.388 73.13 154.488 72.49C156.118 71.55 157.278 70.04 157.768 68.22C158.258 66.4 158.008 64.51 157.068 62.89C156.128 61.26 154.618 60.1 152.798 59.62H152.788Z"
        fill="#36A9E1"
      />
      <path
        d="M126.548 34.05C127.158 34.21 127.768 34.29 128.378 34.29C129.598 34.29 130.798 33.97 131.878 33.35C133.508 32.41 134.668 30.9 135.148 29.08C135.638 27.27 135.388 25.37 134.448 23.75C132.508 20.39 128.198 19.24 124.848 21.18C123.218 22.12 122.058 23.63 121.568 25.45C121.078 27.26 121.328 29.16 122.268 30.78C123.208 32.41 124.718 33.57 126.538 34.05H126.548Z"
        fill="#E71D73"
      />
      <path
        d="M107.588 59.62C105.778 59.13 103.878 59.38 102.258 60.32C100.628 61.26 99.4681 62.77 98.9781 64.59C98.4881 66.41 98.7381 68.3 99.6781 69.92C100.978 72.17 103.348 73.43 105.778 73.43C106.968 73.43 108.178 73.13 109.278 72.49C112.638 70.55 113.788 66.25 111.848 62.89C110.908 61.26 109.398 60.1 107.578 59.62H107.588Z"
        fill="#EA5B0C"
      />
      <path
        d="M128.328 0C119.008 0 109.798 2.47 101.688 7.16C95.4581 10.76 90.1081 15.48 85.7981 21.18C81.6381 26.68 78.6181 32.87 76.8281 39.56C75.0381 46.25 74.5581 53.11 75.4081 59.96C76.2881 67.05 78.5581 73.81 82.1581 80.05C86.9281 88.31 93.8181 95.15 102.088 99.81C110.098 104.34 119.208 106.73 128.408 106.73C137.608 106.73 146.938 104.26 155.048 99.57C161.278 95.97 166.628 91.25 170.938 85.55C175.098 80.05 178.118 73.86 179.908 67.17C181.698 60.48 182.178 53.62 181.328 46.77C180.448 39.68 178.178 32.92 174.578 26.68C169.808 18.42 162.918 11.59 154.648 6.92C146.638 2.39 137.538 0 128.328 0ZM90.4881 75.23C78.4081 54.31 85.5781 27.56 106.498 15.49C113.388 11.51 120.908 9.62 128.328 9.62C143.448 9.62 158.148 17.46 166.248 31.49C178.328 52.41 171.158 79.16 150.238 91.23C143.348 95.21 135.828 97.1 128.408 97.1C113.288 97.1 98.5881 89.26 90.4881 75.23Z"
        fill="#8CA0A0"
      />

      <defs>
        <clipPath id="clip0_1_45">
          <rect width="256.01" height="172.87" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
Logo.propTypes = {
  text: PropTypes.string,
};
