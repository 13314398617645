/* eslint-disable max-len */
// Autogenerated file, DO NOT MODIFY or it will be overwritten;
import * as React from "react";
import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";

function IconGoBack({ color = "#FFFFFF", ...rest }) {
  return (
    <chakra.svg
      width="10"
      height="19"
      viewBox="0 0 10 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9 18L0.999999 9.5L9 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  );
}
IconGoBack.propTypes = {
  color: PropTypes.string,
};
// eslint-disable-next-line import/no-default-export
export default IconGoBack;
