import axios from 'axios';
import { APPENV } from "./config";

function isUnauthorizedError(error) {
    const {
        response: { status, statusText },
    } = error;
    const originalRequest = error.config;
    return status === 401  && !originalRequest._retry;
}
export async function renewToken() {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken)
        throw new Error('refresh token does not exist');

    const refreshPayload = {
        refreshToken: refreshToken
    };

    const response = await axios.post(`${APPENV.HOST_RB_API}/refresh_endpoint`, refreshPayload);
    const token = response.data.accessToken;

    const newRefreshToken = response.data.refreshToken;

    return [token, newRefreshToken];
}
let refreshingFunc = undefined;

axios.interceptors.request.use(
   (config) => {
      const token = localStorage.getItem("token");
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
    return config;
  },
);

axios.interceptors.response.use(
    (response) =>  response?.data,
    async (error) => {
        const originalConfig = error.config;
        const token = localStorage.getItem("token");
        
        // if we don't have token in local storage or error is not 401 just return error and break req.
        if (!token || !isUnauthorizedError(error)) {
            return Promise.reject(error);
        }
        originalConfig._retry = true;

        try {
            // the trick here, that `refreshingFunc` is global, e.g. 2 expired requests will get the same function pointer and await same function.
            if (!refreshingFunc)
                refreshingFunc = renewToken();
                
            const [newToken, newRefreshToken] = await refreshingFunc;

            localStorage.setItem("token", newToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            // retry original request
            try {
              axios.defaults.headers['Authorization'] = 'Bearer ' + newToken;
              return await axios(originalConfig);

            } catch(innerError) {
                // if original req failed with 401 again - it means server returned not valid token for refresh request
                if (isUnauthorizedError(innerError)) {
                    throw innerError;
                }                  
            }

        } catch (err) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");

            window.location = `${window.location.origin}/login`;

        } finally {
            refreshingFunc = undefined;
        }
    },
)
