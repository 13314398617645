import React from "react";
import { Select, Stack, useBreakpointValue } from "@chakra-ui/react";
import { useLanguageContext } from "context/lang-context";
import PropTypes from "prop-types";
import { useApp } from "context/App/useApp";

export function LanguagePicker({
  color = "#B0B0B0",
  defaultPos = {
    position: "absolute",
    left: 0,
    top: 0,
  },
}) {
  const { pageColor } = useApp();
  const { lang, setLang } = useLanguageContext();
  const changeLanguage = (e) => {
    setLang(e?.target?.value);
  };
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Stack
      {...defaultPos}
      alignSelf={isMobile ? "end" : "center"}
      alignItems={isMobile ? "end" : "center"}
    >
      <Select
        _hover={{ cursor: "pointer" }}
        fontSize={isMobile ? "14px" : "16px"}
        background="transparent"
        border="none"
        color={color}
        onChange={changeLanguage}
        defaultValue={lang}
      >
        <option value="fr" style={{ backgroundColor: pageColor }}>
          FR
        </option>
        <option value="en-US" style={{ backgroundColor: pageColor }}>
          EN
        </option>
        <option value="es" style={{ backgroundColor: pageColor }}>
          ES
        </option>
      </Select>
    </Stack>
  );
}
LanguagePicker.propTypes = {
  defaultPos: PropTypes.instanceOf(Object),
  color: PropTypes.string,
};
