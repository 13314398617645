import Axios from "axios";
import { APPENV } from "config/config";
import {  useQuery } from "react-query";

export const useGetUser = ({isEnabled= false}) => {
  const {data, isLoading, isFetching } = useQuery('user',
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/user`
      ), {
        enabled: isEnabled,
      }
  );

  const user = data?.data ?? null;
  return {user, isLoading, isFetching};
}
