import Axios from "axios";
import { APPENV } from "config/config";
import { useQuery } from "react-query";

export const useSearchDons = ({params = new URLSearchParams(), isFavorite = false}) => {
  const {data, isLoading, isFetching, } = useQuery(['searchDons', isFavorite, params.toString()],
    () =>
      Axios.get(
        `${APPENV.HOST_RB_API}/search/dons${isFavorite ? '/favoris' : ''}`, {
          params
        }
      ),
  );

  const dons = data?.data?.dons ?? data?.data  ?? [];
  const {error} = data?.data ?? {};
  return {dons, isLoading, isFetching,error};
}

