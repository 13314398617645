import { useToast } from "@chakra-ui/react";
import Axios from "axios";
import { MyToast } from "components/Toast/MyToast";
import { APPENV } from "config/config";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateUserStatut = () => {
  const toast = useToast()
  const { t } = useTranslation();
  const queryClient = useQueryClient()


  return useMutation(
    (data) =>
      Axios.post(
        `${APPENV.HOST_RB_API}/user/${data.id}/update`,
        data
      ),
    {
      onError: (error) => {
        let descriptionError = "account:profil.update.error"
        if (error?.response?.request.response) {
          const errorObj = JSON.parse(error?.response?.request.response);
          if (
            errorObj.statusCode === 501 &&
            errorObj?.error?.type === "AppException"
          ) {
            descriptionError = errorObj?.error?.description;
          }
        } 
        toast({
          duration: 9000,
          position: "top",
          render: () => (
            <MyToast status="error" backgroundColor="#EA5B0C" color="white" title={t('app:errors.profilUpdate')} text={t(descriptionError)} />
          ),
        });

      },
      onSuccess: async () => {
        await queryClient.invalidateQueries( {queryKey:'users', });
        await queryClient.invalidateQueries( {queryKey:'admin-collectifs', });
        await queryClient.invalidateQueries( {queryKey:['collectifs_pays']});
      },
    }
  );

};
