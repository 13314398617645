import React, { useEffect, useState } from "react";
import { Center, Spinner, Stack, StackDivider, Text, useBreakpointValue } from "@chakra-ui/react";
import { SlideIn } from "components/SlideIn";
import { Header } from "components/Layout/Header";
import { useTranslation } from "react-i18next";
import { Footer } from "components/Layout/Footer";
import { useApp } from "context/App/useApp";
import { useAuthentificationContext } from "context/auth-context";
import { useSearchParams } from "react-router-dom";
import { CardDon } from "./_partials/CardDon";
import { GetDonsFilters } from "./funtions/GetDonsFilters";
import { useSearchDons } from "./services/useSearchDons";
import { SearchCardMobile } from "./_partials/SearchCardMobile";
import { SearchCardDesktop } from "./_partials/SearchCardDesktop";
import { CardDonMobile } from "./_partials/CardDonMobile";

export function PageRechercheDonsFavoris() {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, isLoading } = useAuthentificationContext();

  // remplacer par params pour filter coter backend
  const {
    dons,
    isLoading: isLoadingDons,
    isFetching,
  } = useSearchDons({
    params: searchParams,
    isFavorite: true,
  });

  const { t } = useTranslation();
  const { pageColor, setPageColor, setBackgroundColorNotif, setColorNotif, setColorNotifHover } =
    useApp();

  useEffect(() => {
    setPageColor("#36A9E1");
    setBackgroundColorNotif("#36A9E1");
    setColorNotif("#FFFFFF");
    setColorNotifHover("#000000");
  }, [setPageColor, setBackgroundColorNotif, setColorNotifHover, setColorNotif]);

  const { type, localisation, frequence } = GetDonsFilters();
  const [selectedValue, setSelectedValue] = useState({
    type: [],
    frequence: [],
    localisation: [],
  });

  const handleSearchDon = (values) => {
    setSelectedValue((old) => ({ ...old, search: values.search }));
  };

  const handleClickFilters = (typeFiltre, filtre) => {
    if (typeFiltre === "type") {
      setSelectedValue((old) => ({
        ...old,
        type: old.type === filtre.value ? "" : filtre.value,
      }));
    } else if (typeFiltre === "localisation") {
      setSelectedValue((old) => ({
        ...old,
        localisation: old.localisation === filtre.value ? "" : filtre.value,
      }));
    } else if (typeFiltre === "frequence") {
      setSelectedValue((old) => ({
        ...old,
        frequence: old.frequence === filtre.value ? "" : filtre.value,
      }));
    }
  };

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue.type) {
        searchParams.set("type", selectedValue.type);
      } else {
        searchParams.delete("type");
      }
      if (selectedValue.localisation !== "") {
        searchParams.set("localisation", selectedValue.localisation);
      } else {
        searchParams.delete("localisation");
      }
      if (selectedValue.frequence) {
        searchParams.set("frequence", selectedValue.frequence);
      } else {
        searchParams.delete("frequence");
      }
      if (selectedValue.search) {
        searchParams.set("search", selectedValue.search);
      }

      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <SlideIn>
      <Header />
      <Stack mx="auto" minW={isMobile ? "100%" : "500px"}>
        <Stack
          mb={8}
          spacing={2}
          alignItems="center"
          p={4}
          divider={isMobile ? <StackDivider h="1px" borderColor={pageColor} /> : null}
        >
          <Stack alignItems="center">
            <Text textTransform="uppercase" fontSize="24px" color={pageColor}>
              {t("dons:titleFavoris")}
            </Text>
          </Stack>
          {isMobile ? (
            <SearchCardMobile
              handleClickFilters={handleClickFilters}
              dons={dons}
              handleSearchDon={handleSearchDon}
              isLoadingDons={isLoadingDons}
              isFetching={isFetching}
              selectedValue={selectedValue}
              pageColor={pageColor}
              type={type}
              frequence={frequence}
              localisation={localisation}
            />
          ) : (
            <SearchCardDesktop
              handleClickFilters={handleClickFilters}
              dons={dons}
              handleSearchDon={handleSearchDon}
              isLoadingDons={isLoadingDons}
              isFetching={isFetching}
              selectedValue={selectedValue}
              pageColor={pageColor}
              type={type}
              frequence={frequence}
              localisation={localisation}
            />
          )}

          {isLoadingDons || isLoading ? (
            <Center>
              <Spinner w="30px" h="30px" />
            </Center>
          ) : (
            <Stack pt={12} spacing={8} w="100%">
              {dons?.length === 0 ? (
                <Stack
                  justifyContent="center"
                  width="100%"
                  border={`${pageColor} inset 1px`}
                  minHeight="180px"
                  textAlign="center"
                  borderRadius="10px"
                  alignContent="center"
                >
                  <Text color="#FFF" fontSize="16px">
                    {t("dons:notfound")}
                  </Text>
                </Stack>
              ) : (
                dons?.map((don) =>
                  isMobile ? (
                    <CardDonMobile user={user} key={don?.dons?.id} don={don} />
                  ) : (
                    <CardDon user={user} key={don?.dons?.id} don={don} />
                  )
                )
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Footer />
    </SlideIn>
  );
}
