import React from "react";
import { Card, HStack, Image, Link, Stack, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import IconPlanet from "components/Icons/icons-generated/IconPlanet";

export function CardPartenaire(props) {
  const { src, collectif, href, ...rest } = props;
  const { t } = useTranslation();
  return (
    <Stack border="#36A9E1 solid 1px" borderRadius="10px" {...rest}>
      <Text color="#36A9E1" textTransform="uppercase" p={2} fontSize="12px">
        {collectif}
      </Text>
      <Card objectFit="cover">
        <Image src={src} objectFit="cover" maxW="337px" maxH="90px" />
      </Card>

      <HStack justifyContent="end" p={2}>
        <Link
          fontSize="12px"
          color="#36A9E1"
          textTransform="uppercase"
          href={href}
          isExternal
        >
          {t("partenaires:acceder")}
        </Link>
        <IconPlanet />
      </HStack>
    </Stack>
  );
}
CardPartenaire.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  collectif: PropTypes.string.isRequired,
};
