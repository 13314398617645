import React, {useEffect, useRef} from "react";

import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure,} from "@chakra-ui/react";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthentificationContext} from "context/auth-context";
import {FormLogin} from "App/login/_partials/FormLogin";
import {useQueryClient} from "react-query";

export function LoginModalInterceptor() {
    const {t} = useTranslation(["auth"]);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isAuthenticated, updateToken} = useAuthentificationContext();
    const queryCache = useQueryClient();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const pathnameRef = useRef(pathname);
    pathnameRef.current = pathname;

    useEffect(() => {
        const interceptor = Axios.interceptors.response.use(
            (r) => r,
            (error) => {
                if (
                    error?.response?.status === 401 &&
                    pathnameRef.current !== "/login"
                ) {
                    queryCache.cancelQueries();
                    onOpen();
                }
                throw error;
            }
        );

        return () => Axios.interceptors.response.eject(interceptor);
    }, [onOpen, updateToken, queryCache]);

    // On Route Change
    useEffect(() => {
        if (isOpen && pathname !== pathnameRef.current) {
            updateToken(null);
            onClose();
        }
    }, [isOpen, updateToken, onClose, pathname]);

    const handleLogin = () => {
        navigate("/dashboard");
        queryCache.refetchQueries();
        onClose();
    };

    const handleClose = () => {
        updateToken(null);
        onClose();
        navigate("/login");
    };

    return (
        <Modal
            isOpen={isOpen && isAuthenticated}
            onClose={handleClose}
            closeOnOverlayClick={false}
            trapFocus={false}

        >
            <ModalOverlay style={{backdropFilter: "blur(6px)"}}/>
            <ModalContent bgColor="energieCyan">
                <ModalCloseButton/>
                <ModalBody p="6">
                    <FormLogin onSuccess={handleLogin}/>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
