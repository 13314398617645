/* eslint-disable max-len */
import React from "react";
import { chakra } from "@chakra-ui/react";
import PropTypes from "prop-types";

export function LogoChangeDot({
  topDot = "#EA5B0C",
  leftDot = "#36A9E1",
  rightDot = "#E71D73",
  ...rest
}) {
  return (
    <chakra.svg width="112" height="77" viewBox="0 0 112 77" {...rest}>
      <g clipPath="url(#clip0_559_3843)">
        <path
          d="M7.71721 57.9048C6.57101 56.7378 5.17106 56.1454 3.56113 56.1454H1.34309C1.22059 56.1454 1.10247 56.1721 0.993103 56.2211C0.883733 56.2701 0.791861 56.3325 0.708739 56.4126C0.625618 56.4928 0.559995 56.5908 0.511872 56.7022C0.463749 56.8135 0.4375 56.9338 0.4375 57.0585C0.4375 57.3391 0.520622 57.5618 0.686865 57.7222C0.971229 57.9939 1.38246 57.9716 1.39121 57.9716H3.55238C4.66796 57.9716 5.64355 58.3859 6.43976 59.1965C7.24036 60.0116 7.64284 61.0005 7.64284 62.1363C7.64284 62.7198 7.54222 63.2588 7.34535 63.7398C7.14849 64.2209 6.84225 64.6752 6.43976 65.085C5.64355 65.8957 4.67233 66.3054 3.55238 66.3054H1.34309C1.03248 66.3054 0.441875 66.5014 0.441875 67.2186C0.441875 67.3433 0.468124 67.4635 0.516247 67.5749C0.56437 67.6863 0.629992 67.7842 0.713114 67.8644C0.791861 67.9446 0.888107 68.007 0.997478 68.056C1.10685 68.1049 1.22497 68.1317 1.34746 68.1317H3.56113C5.17544 68.1317 6.57538 67.5437 7.71721 66.3767C8.85905 65.2097 9.44527 63.7844 9.44527 62.1363C9.44527 61.3034 9.3009 60.5283 9.01216 59.8335C8.7278 59.1386 8.29032 58.4928 7.71284 57.9048H7.71721Z"
          fill="#8CA0A0"
        />
        <path
          d="M13.0106 56.1454C12.7656 56.1454 12.5513 56.2345 12.3763 56.4126C12.2013 56.5908 12.1138 56.8046 12.1138 57.0585V67.2186C12.1138 67.468 12.2013 67.6863 12.3763 67.8644C12.5513 68.0426 12.7612 68.1317 13.0106 68.1317C13.26 68.1317 13.47 68.0426 13.645 67.8644C13.82 67.6863 13.9074 67.4725 13.9074 67.2186V57.0585C13.9074 56.8091 13.82 56.5908 13.645 56.4126C13.47 56.2345 13.26 56.1454 13.0106 56.1454Z"
          fill="#8CA0A0"
        />
        <path
          d="M25.9386 56.4082C25.8554 56.3236 25.7592 56.2612 25.6498 56.2122C25.5405 56.1677 25.4223 56.1454 25.2998 56.1454C24.893 56.1454 24.6042 56.3369 24.4686 56.6933L21.415 64.6886L18.357 56.6843C18.2039 56.328 17.9151 56.1409 17.5301 56.1409C17.4033 56.1409 17.2852 56.1632 17.1758 56.2122C17.0708 56.2567 16.9745 56.3236 16.8914 56.4082C16.8083 56.4928 16.747 56.5908 16.6989 56.7022C16.6552 56.8135 16.6333 56.9338 16.6333 57.0585C16.6333 57.1832 16.6639 57.3391 16.7295 57.5039L20.5925 67.5571C20.7413 67.9357 21.0256 68.1317 21.415 68.1317C21.8043 68.1317 22.0887 67.9312 22.2375 67.5526L26.0961 57.5039C26.1617 57.3525 26.1923 57.201 26.1923 57.054C26.1923 56.9293 26.1704 56.8091 26.1267 56.6977C26.0829 56.5864 26.0173 56.4884 25.9342 56.4037L25.9386 56.4082Z"
          fill="#8CA0A0"
        />
        <path
          d="M29.5082 56.1454C29.2632 56.1454 29.0488 56.2345 28.8738 56.4126C28.6988 56.5908 28.6113 56.8091 28.6113 57.0585V67.2186C28.6113 67.468 28.6988 67.6863 28.8738 67.8644C29.0488 68.0426 29.2588 68.1317 29.5082 68.1317C29.7575 68.1317 29.9675 68.0426 30.1425 67.8644C30.3175 67.6863 30.405 67.4725 30.405 67.2186V57.0585C30.405 56.8091 30.3175 56.5908 30.1425 56.4126C29.9675 56.2345 29.7575 56.1454 29.5082 56.1454Z"
          fill="#8CA0A0"
        />
        <path
          d="M41.5303 56.1454C41.2853 56.1454 41.071 56.2345 40.896 56.4126C40.721 56.5908 40.6335 56.8091 40.6335 57.0585V64.5683L34.4606 56.4839V56.475C34.2594 56.2567 34.0275 56.1454 33.765 56.1454C33.6381 56.1454 33.52 56.1677 33.4106 56.2167C33.3056 56.2612 33.2094 56.328 33.1263 56.4126C33.0432 56.4973 32.9819 56.5953 32.9338 56.7066C32.89 56.818 32.8682 56.9382 32.8682 57.063V67.223C32.8682 67.4725 32.9557 67.6907 33.1307 67.8689C33.3056 68.047 33.5156 68.1361 33.765 68.1361C34.0144 68.1361 34.2244 68.047 34.3994 67.8689C34.5743 67.6907 34.6618 67.4769 34.6618 67.223V59.7266L40.8435 67.811L40.8522 67.8199C41.0403 68.0292 41.2678 68.1361 41.5347 68.1361C41.6572 68.1361 41.7753 68.1139 41.8847 68.0693C41.9941 68.0248 42.0903 67.958 42.1734 67.8733C42.2565 67.7887 42.3178 67.6952 42.3659 67.5838C42.4097 67.4724 42.4359 67.3522 42.4359 67.223V57.063C42.4359 56.8135 42.3484 56.5953 42.1734 56.4171C41.9984 56.2389 41.7841 56.1498 41.5391 56.1498L41.5303 56.1454Z"
          fill="#8CA0A0"
        />
        <path
          d="M48.8319 61.2276C48.5869 61.2276 48.3725 61.3167 48.1976 61.4949C48.0226 61.673 47.9351 61.8868 47.9351 62.1407C47.9351 62.3946 48.0226 62.6084 48.1976 62.7866C48.3725 62.9648 48.5825 63.0539 48.8319 63.0539H51.0324C51.2774 63.0539 51.4918 62.9648 51.6668 62.7866C51.8418 62.6084 51.9293 62.3946 51.9293 62.1407C51.9293 61.8868 51.8418 61.673 51.6668 61.4949C51.4918 61.3167 51.2774 61.2276 51.0324 61.2276H48.8319Z"
          fill="#8CA0A0"
        />
        <path
          d="M53.325 57.7044C53.5 57.5262 53.5875 57.3124 53.5875 57.0585C53.5875 56.8046 53.5 56.5908 53.325 56.4126C53.15 56.2345 52.9356 56.1454 52.6906 56.1454H46.0409C45.914 56.1454 45.7959 56.1677 45.6865 56.2167C45.5815 56.2612 45.4853 56.328 45.4022 56.4126C45.319 56.4973 45.2578 56.5953 45.2097 56.7066C45.1659 56.818 45.144 56.9382 45.144 57.063V67.223C45.144 67.3522 45.1659 67.4769 45.214 67.5838C45.2578 67.6907 45.3234 67.7887 45.4065 67.8733C45.4897 67.958 45.5815 68.0203 45.6909 68.0693C45.7959 68.1139 45.9184 68.1406 46.0453 68.1406H52.695C52.94 68.1406 53.1544 68.0515 53.3293 67.8733C53.5043 67.6952 53.5918 67.4814 53.5918 67.2275C53.5918 66.9736 53.5043 66.7598 53.3293 66.5816C53.1544 66.4034 52.94 66.3144 52.695 66.3144H46.9377V57.9761H52.695C52.94 57.9761 53.1544 57.887 53.3293 57.7088L53.325 57.7044Z"
          fill="#8CA0A0"
        />
        <path
          d="M71.9701 61.2276C71.7251 61.2276 71.5107 61.3167 71.3357 61.4949C71.1607 61.673 71.0732 61.8868 71.0732 62.1407C71.0732 62.3946 71.1607 62.6084 71.3357 62.7866C71.5107 62.9648 71.7207 63.0539 71.9701 63.0539H74.1706C74.4156 63.0539 74.63 62.9648 74.805 62.7866C74.98 62.6084 75.0675 62.3946 75.0675 62.1407C75.0675 61.8868 74.98 61.673 74.805 61.4949C74.63 61.3167 74.42 61.2276 74.1706 61.2276H71.9701Z"
          fill="#8CA0A0"
        />
        <path
          d="M76.4632 57.7044C76.6381 57.5262 76.7256 57.3124 76.7256 57.0585C76.7256 56.8046 76.6381 56.5908 76.4632 56.4126C76.2882 56.2345 76.0738 56.1454 75.8288 56.1454H69.1791C69.0522 56.1454 68.9341 56.1677 68.8247 56.2167C68.7197 56.2612 68.6235 56.328 68.5403 56.4126C68.4572 56.4973 68.396 56.5953 68.3478 56.7066C68.3041 56.818 68.2822 56.9382 68.2822 57.063V67.223C68.2822 67.3522 68.3041 67.4769 68.3522 67.5838C68.396 67.6907 68.4616 67.7887 68.5447 67.8733C68.6278 67.958 68.7197 68.0203 68.8291 68.0693C68.9341 68.1139 69.0566 68.1406 69.1834 68.1406H75.8332C76.0782 68.1406 76.2925 68.0515 76.4675 67.8733C76.6425 67.6952 76.73 67.4814 76.73 67.2275C76.73 66.9736 76.6425 66.7598 76.4675 66.5816C76.2925 66.4034 76.0782 66.3144 75.8332 66.3144H70.0759V57.9761H75.8332C76.0782 57.9761 76.2925 57.887 76.4675 57.7088L76.4632 57.7044Z"
          fill="#8CA0A0"
        />
        <path
          d="M64.7608 56.1454C64.5158 56.1454 64.3014 56.2345 64.1264 56.4126C63.9515 56.5908 63.864 56.8091 63.864 57.0585V64.5683L57.6911 56.4839V56.475C57.4898 56.2567 57.258 56.1454 56.9955 56.1454C56.8686 56.1454 56.7505 56.1677 56.6411 56.2167C56.5361 56.2612 56.4399 56.328 56.3567 56.4126C56.2736 56.4973 56.2124 56.5953 56.1643 56.7066C56.1205 56.818 56.0986 56.9382 56.0986 57.063V67.223C56.0986 67.4725 56.1861 67.6907 56.3611 67.8689C56.5361 68.047 56.7461 68.1361 56.9955 68.1361C57.2448 68.1361 57.4548 68.047 57.6298 67.8689C57.8048 67.6907 57.8923 67.4769 57.8923 67.223V59.7266L64.0739 67.811L64.0827 67.8199C64.2708 68.0292 64.4983 68.1361 64.7652 68.1361C64.8877 68.1361 65.0058 68.1139 65.1152 68.0693C65.2245 68.0248 65.3208 67.958 65.4039 67.8733C65.487 67.7887 65.5483 67.6952 65.5964 67.5838C65.6401 67.4724 65.6664 67.3522 65.6664 67.223V57.063C65.6664 56.8135 65.5789 56.5953 65.4039 56.4171C65.2289 56.2389 65.0145 56.1498 64.7695 56.1498L64.7608 56.1454Z"
          fill="#8CA0A0"
        />
        <path
          d="M94.4393 57.9716C94.9162 57.9716 95.3712 58.0473 95.7955 58.1988C96.2155 58.3502 96.6093 58.5684 96.9549 58.8491C97.1342 59.0183 97.3398 59.103 97.5673 59.103C97.6898 59.103 97.808 59.0807 97.9173 59.0361C98.0267 58.9916 98.1229 58.9248 98.2061 58.8402C98.2892 58.7555 98.3504 58.662 98.3986 58.5506C98.4423 58.4393 98.4686 58.319 98.4686 58.1898C98.4686 57.9226 98.3854 57.7088 98.2192 57.5484C97.1036 56.6175 95.8305 56.1498 94.4481 56.1498C92.8338 56.1498 91.4295 56.7422 90.2833 57.9092C89.1414 59.0718 88.5596 60.4971 88.5596 62.1407C88.5596 63.7843 89.137 65.2186 90.2833 66.3811C91.4295 67.5437 92.825 68.1361 94.4481 68.1361C95.813 68.1361 97.0599 67.6862 98.1492 66.8043L98.1623 66.7909C98.3461 66.5994 98.4379 66.3811 98.4379 66.1406V62.7064C98.4379 62.5862 98.4117 62.4659 98.3636 62.3545C98.3154 62.2432 98.2498 62.1452 98.1711 62.065C98.0923 61.9848 97.9961 61.918 97.8911 61.869C97.7817 61.82 97.668 61.7933 97.5498 61.7933C96.8367 61.7933 96.6486 62.3946 96.6486 62.7153V65.6774C96.0012 66.0961 95.2662 66.3099 94.4525 66.3099C93.3194 66.3099 92.3438 65.9001 91.5476 65.0894C90.7557 64.2832 90.3576 63.2944 90.3576 62.1407C90.3576 60.9871 90.7601 60.0027 91.5476 59.201C92.335 58.3992 93.3238 57.976 94.4525 57.976L94.4393 57.9716Z"
          fill="#8CA0A0"
        />
        <path
          d="M111.685 58.3279C111.567 58.1186 111.37 57.9671 111.138 57.9003C110.906 57.838 110.666 57.8691 110.456 57.9939L106.138 60.5328L101.82 57.9939C101.61 57.8736 101.369 57.838 101.137 57.9003C100.905 57.9627 100.713 58.1141 100.59 58.3279C100.472 58.5373 100.437 58.7867 100.498 59.0228C100.56 59.2589 100.708 59.4548 100.918 59.5796L105.236 62.1185V67.1963C105.236 67.6996 105.639 68.1094 106.133 68.1094C106.374 68.1094 106.597 68.0158 106.768 67.8421C106.938 67.6684 107.03 67.4412 107.03 67.1963V62.1185L111.348 59.5796C111.554 59.4593 111.702 59.2589 111.768 59.0228C111.829 58.7867 111.799 58.5417 111.676 58.3279H111.685Z"
          fill="#8CA0A0"
        />
        <path
          d="M105.783 58.2255C105.901 58.2567 106.02 58.2745 106.142 58.2745C106.383 58.2745 106.619 58.2077 106.825 58.0874C107.144 57.9003 107.367 57.6019 107.463 57.2411C107.56 56.8803 107.511 56.5062 107.328 56.181C107.144 55.8603 106.851 55.6287 106.496 55.5307C106.142 55.4327 105.775 55.4817 105.455 55.6688C105.136 55.8558 104.913 56.1543 104.817 56.5151C104.72 56.8759 104.768 57.25 104.952 57.5752C105.136 57.9003 105.429 58.1275 105.783 58.2255Z"
          fill={topDot}
        />
        <path
          d="M86.8842 66.7197L84.6617 63.0939C84.9286 62.9291 85.1736 62.7421 85.3792 62.5327C85.6198 62.2966 85.8211 62.0338 85.9829 61.7532C86.1448 61.4726 86.2673 61.1742 86.3461 60.8579C86.4292 60.5417 86.4685 60.2165 86.4685 59.8869C86.4685 59.3791 86.3723 58.8936 86.1798 58.4437C85.9873 57.9939 85.7248 57.5974 85.3967 57.2589C85.0686 56.9204 84.6792 56.6531 84.2374 56.4572C83.7955 56.2612 83.3187 56.1632 82.8156 56.1632H80.0419C79.9151 56.1632 79.7969 56.1854 79.6919 56.23C79.5869 56.2745 79.4907 56.3413 79.4119 56.4215C79.3288 56.5062 79.2676 56.6041 79.2238 56.711C79.1801 56.8224 79.1582 56.9382 79.1582 57.0629C79.1582 57.3302 79.2413 57.5484 79.4032 57.7043C79.6963 57.985 80.1382 57.9671 80.1513 57.9671H82.8199C83.0737 57.9671 83.3187 58.0161 83.5462 58.1186C83.7737 58.221 83.9749 58.3591 84.1455 58.5284C84.3161 58.7021 84.4517 58.907 84.548 59.1386C84.6442 59.3702 84.6967 59.6197 84.6967 59.8824C84.6967 60.1452 84.6486 60.3858 84.548 60.6174C84.4474 60.849 84.3161 61.0539 84.1455 61.2276C83.9793 61.4013 83.778 61.5439 83.5505 61.6419C83.323 61.7399 83.0781 61.7933 82.8199 61.7933H80.0463C79.9194 61.7933 79.8013 61.8156 79.6963 61.8601C79.5913 61.9047 79.4951 61.9715 79.4163 62.0516C79.3332 62.1363 79.272 62.2343 79.2282 62.3412C79.1845 62.4525 79.1626 62.5683 79.1626 62.6931C79.1626 62.9603 79.2457 63.1786 79.4076 63.3345C79.7007 63.6151 80.1425 63.5973 80.1557 63.5973H82.8856L85.3836 67.6684C85.5761 67.9579 85.8298 68.1049 86.1404 68.1049C86.2629 68.1049 86.381 68.0827 86.486 68.0381C86.5954 67.9936 86.6917 67.9268 86.7704 67.8466C86.8492 67.7664 86.9148 67.6684 86.9585 67.5615C87.0023 67.4546 87.0241 67.3343 87.0241 67.2052C87.0241 67.0359 86.976 66.8711 86.8842 66.7152V66.7197Z"
          fill="#8CA0A0"
        />
        <path
          d="M43.0916 18.6661L54.2373 24.8956L54.2837 37.5591C54.285 38.6158 55.1582 39.4627 56.2296 39.4551C57.301 39.4476 58.1647 38.5812 58.1634 37.5244L58.1193 24.8571L69.2169 18.4173C70.1416 17.878 70.4552 16.7092 69.9162 15.7965C69.375 14.8876 68.1919 14.5852 67.2634 15.1223L56.1657 21.5622L45.02 15.3326C44.0903 14.8129 42.905 15.1409 42.3749 16.0573C41.8447 16.9737 42.1641 18.1426 43.0939 18.6622L43.0916 18.6661Z"
          fill="#8CA0A0"
        />
        <path
          d="M48.3631 31.7812C48.9519 31.1958 49.275 30.4166 49.2702 29.5955C49.2676 28.7706 48.9414 28.0022 48.3454 27.4266C47.7555 26.8494 46.9674 26.5352 46.1349 26.5448C44.98 26.556 43.9706 27.1892 43.4339 28.1168C43.1711 28.5711 43.0196 29.0977 43.0235 29.6556C43.0261 30.4805 43.3523 31.2489 43.9461 31.8283C44.5399 32.4077 45.3241 32.7198 46.1566 32.7101C46.9929 32.7027 47.7738 32.3763 48.3609 31.785L48.3631 31.7812Z"
          fill={leftDot}
        />
        <path
          d="M63.9733 27.2296C63.7769 27.428 63.6113 27.6436 63.4766 27.8765C63.2072 28.3422 63.0656 28.8693 63.0662 29.4152C63.0688 30.2402 63.395 31.0085 63.991 31.5841C64.581 32.1614 65.369 32.4756 66.2015 32.466C67.9259 32.4497 69.3214 31.0523 69.3129 29.3551C69.3103 28.5302 68.9841 27.7619 68.3903 27.1824C67.8004 26.6052 67.0123 26.291 66.1798 26.3006C65.3435 26.3081 64.5626 26.6344 63.9755 27.2258L63.9733 27.2296Z"
          fill={rightDot}
        />
        <path
          d="M58.3634 14.4607C58.9521 13.8754 59.2753 13.0962 59.2705 12.2751C59.2679 11.4502 58.9416 10.6818 58.3478 10.1024C57.7541 9.52297 56.9698 9.21095 56.1374 9.22056C54.9824 9.23178 53.973 9.86491 53.4364 10.7926C53.1736 11.2469 53.022 11.7735 53.026 12.3314C53.0322 14.0323 54.4368 15.3983 56.1613 15.3821C56.9976 15.3746 57.7785 15.0482 58.3656 14.4569L58.3634 14.4607Z"
          fill={topDot}
        />
        <path
          d="M76.7514 35.2592C78.8097 31.7012 79.891 27.6528 79.8731 23.5457C79.8604 20.3912 79.2214 17.3313 77.9746 14.4571C76.7719 11.6833 75.0513 9.19593 72.8662 7.07034C70.6811 4.94475 68.1411 3.28262 65.3112 2.13037C62.3822 0.937833 59.2734 0.347079 56.0715 0.376164C51.832 0.416425 47.6721 1.57214 44.0483 3.72464C40.532 5.80591 37.5982 8.76846 35.5664 12.2806C33.5346 15.7928 32.4268 19.887 32.4447 23.9941C32.4574 27.1486 33.0965 30.2085 34.3432 33.0827C35.5459 35.8565 37.2665 38.3439 39.4516 40.4695C41.6367 42.595 44.1767 44.2572 47.0066 45.4094C49.9357 46.602 53.0444 47.1927 56.2463 47.1636C60.4858 47.1234 64.6419 45.9655 68.2696 43.8152C71.7858 41.7339 74.7174 38.7752 76.7514 35.2592ZM56.091 4.59529C66.828 4.49367 75.5624 12.9977 75.5978 23.5861C75.6113 27.0744 74.6795 30.3527 73.0408 33.1853C69.7016 38.9574 63.4312 42.8791 56.2307 42.9467C45.4937 43.0483 36.7593 34.5443 36.7239 23.9559C36.7104 20.4675 37.6421 17.1893 39.2808 14.3567C42.6201 8.58453 48.8905 4.66289 56.091 4.59529Z"
          fill="#8CA0A0"
        />
      </g>
      <defs>
        <clipPath id="clip0_559_3843">
          <rect width="112" height="77" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  );
}
LogoChangeDot.propTypes = {
  topDot: PropTypes.string,
  leftDot: PropTypes.string,
  rightDot: PropTypes.string,
};
